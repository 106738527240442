<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import { useFormContentNavigationStore } from '@/stores/formContentNavigation'

const { t } = useI18n()

const formContentNavigationStore = useFormContentNavigationStore()

// increment メソッドを呼び出す
const onClick = () => {
  formContentNavigationStore.executeBackAction()
}
</script>

<template>
  <div>
    <v-btn prepend-icon="arrow_back" @click="onClick">{{
      t('features.hospitalizationDocument.FormContentSubmission.stepBack')
    }}</v-btn>
  </div>
</template>

<style scoped lang="scss"></style>
