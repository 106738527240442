import { Api } from '@/api/generate/apiSchemaGateway'

// APIクライアントの基本設定
const baseApiClient = new Api({
  // セッションタイムアウト時間
  timeout: import.meta.env.VITE_API_REQUEST_TIMEOUT,
  // ヘッダー情報
  headers: {
    'Content-Type': 'application/json'
  },
  // cookieをHTTPヘッダに付与
  withCredentials: true
})

// 入院オーダーAPI用クライアント
export const medicalInstitutionsApiClient = () => {
  baseApiClient.instance.defaults.baseURL =
    import.meta.env.VITE_API_MEDICAL_INSTITUTION_API_BASE_URL
  return baseApiClient
}

// 書類管理API用クライアント
export const documentManagementApiClient = () => {
  baseApiClient.instance.defaults.baseURL =
    import.meta.env.VITE_API_DOCUMENT_MANAGEMENT_API_BASE_URL
  return baseApiClient
}

// ユーザーAPI用クライアント
export const usersApiClient = () => {
  baseApiClient.instance.defaults.baseURL = import.meta.env.VITE_API_USER_API_BASE_URL
  return baseApiClient
}
