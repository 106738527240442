import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useDisplayNameStore = defineStore('displayName', () => {
  // 画面情報
  const displayName = ref<string>()
  const updateDisplayName = (name: string | undefined) => {
    displayName.value = name
  }
  return {
    displayName,
    updateDisplayName
  }
})
