<script setup lang="ts">
import QrCode from 'qrcode.vue'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import Label from '@/components/Base/Label/index.vue'
const { t } = useI18n()

type Props = {
  bindingToken: string
}

const props = defineProps<Props>()
const bindingToken = ref(props.bindingToken)
const bindingTokenPiece = props.bindingToken.match(/.{1,4}/g)
if (bindingTokenPiece == null) {
  bindingToken.value = ''
} else {
  bindingToken.value = bindingTokenPiece.slice(0, 4).join('-')
  if (bindingTokenPiece.length > 4) {
    bindingToken.value += bindingTokenPiece.slice(4).join('')
  }
}
</script>
<template>
  <div class="qr-code-card">
    <div class="qr-code-card-content">
      <Label
        forHtml="content_title"
        :labelText="`${t('features.medicalInstitutionList.qrCodeCard.bindingToken')} ${bindingToken}`"
        :isRequired="false"
      ></Label>
      <hr />
      <p>{{ t('features.medicalInstitutionList.qrCodeCard.description') }}</p>
      <div class="qr-code">
        <QrCode :value="props.bindingToken" :size="150" :margin="1" />
      </div>
    </div>
  </div>
</template>

<style>
.qr-code-card {
  border-radius: 10px;
  background-color: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.1);
}

.qr-code-card-content {
  padding: 16px;
}

.qr-code {
  display: flex;
  justify-content: center;
  padding: 16px;
}
</style>
