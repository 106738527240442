<script setup lang="ts">
import { getTypography } from '@optim-design-system/src'

import type { ChipItem } from './types'

import BaseChip from '@/components/Base/Chip/index.vue'

type Props = {
  /**
   * タイトル
   */
  title: string
  /**
   * チップの内容
   */
  chipValue?: ChipItem
  /**
   * ワーニングのテキスト
   */
  warning: undefined | string
  disabled?: boolean
}

withDefaults(defineProps<Props>(), {
  title: 'タイトル',
  warnText: undefined
})

type Emits = {
  (e: 'click', value: string[]): void
}

const emit = defineEmits<Emits>()

const handlerClick = (event: string[]) => {
  emit('click', event)
}

const titleStyle = getTypography('TITLE_M')
const bodyStyle = getTypography('BODY_M')
</script>

<template>
  <v-sheet class="status-card" @click="handlerClick">
    <div class="status-card-content">
      <div class="status-card-title" v-if="chipValue">
        <div class="chip">
          <BaseChip :label="chipValue.label" :color="chipValue.color" />
        </div>
        <div :style="bodyStyle" class="warning-text" v-if="typeof warning == 'string'">
          {{ warning }}
        </div>
      </div>
      <div :style="titleStyle">{{ title }}</div>
    </div>
    <div class="icon">
      <span class="material-symbols-outlined icon--btn-primary">arrow_circle_right</span>
    </div>
  </v-sheet>
</template>

<style lang="scss" scoped>
.status-card {
  display: flex;
  padding: 16px;
  border-radius: 8px;
  justify-content: space-between;
  border: 1px solid #a3a9ad;
}

.status-card-title {
  display: flex;
  height: 24px;
  justify-content: space-between;
  width: 40%;
  min-width: 150px;
  gap: 8px;
}

.status-card-content {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  min-height: 56px;
}

.icon {
  align-self: center;

  &--btn-primary {
    color: rgb(var(--v-theme-primary)) !important;
  }
}

.warning-text {
  color: rgb(var(--v-theme-primitive-red-40-value));
}
</style>
