import { useI18n } from 'vue-i18n'

export default function () {
  const { t } = useI18n()

  // 氏名
  const getFormattedName = (familyName: string | unknown, givenName: string | unknown): string => {
    let formattedName = typeof familyName === 'string' ? familyName : ''
    formattedName += typeof givenName === 'string' ? ' ' + givenName : ''
    return formattedName
  }

  // 氏名（カナ）
  const getFormattedPhoneticName = (
    phoneticFamilyName: string | unknown,
    phoneticGivenName: string | unknown
  ): string => {
    let formattedPhoneticName = typeof phoneticFamilyName === 'string' ? phoneticFamilyName : ''
    formattedPhoneticName += typeof phoneticGivenName === 'string' ? ' ' + phoneticGivenName : ''
    return formattedPhoneticName === ''
      ? ''
      : t('attributes.formatTemplate.phoneticName', { resource: formattedPhoneticName })
  }

  // 氏名 + 氏名（カナ）
  const getFormattedNameAndPhoneticName = (
    familyName: string | unknown,
    givenName: string | unknown,
    phoneticFamilyName: string | unknown,
    phoneticGivenName: string | unknown
  ) => {
    return (
      getFormattedName(familyName, givenName) +
      getFormattedPhoneticName(phoneticFamilyName, phoneticGivenName)
    )
  }

  // 氏名（続柄）
  const getFormattedNameAndRelationship = (
    familyName: string | unknown,
    givenName: string | unknown,
    relationshipCode: string | unknown
  ) => {
    const parenthesesRelationship =
      typeof relationshipCode === 'string'
        ? '（' + t(`attributes.relationshipType.${relationshipCode}`) + '）'
        : ''
    return getFormattedName(familyName, givenName) + parenthesesRelationship
  }
  return {
    getFormattedName,
    getFormattedPhoneticName,
    getFormattedNameAndPhoneticName,
    getFormattedNameAndRelationship
  }
}
