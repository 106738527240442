<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import type { Patient, Representative } from '@/features/MedicalInstitutionPatientConfirmation/type'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import useFormattedName from '@/composables/useFormattedName'
import MedicalInstitutionPatientConfirmation from '@/features/MedicalInstitutionPatientConfirmation/index.vue'
import { useDisplayNameStore } from '@/stores/displayName'
import { useHospitalizationOrderStore } from '@/stores/hospitalizationOrder'

const hospitalizationOrderStore = useHospitalizationOrderStore()
const route = useRoute()
const medicalInstitutionId = route.params.medicalInstitutionId as string

const { getFormattedName } = useFormattedName()

const patient = ref<Patient>()

onMounted(async () => {
  const displayNameStore = useDisplayNameStore()
  displayNameStore.updateDisplayName('pages.navigationTitles.patientConfirmation')

  // 患者・代理人情報の取得
  await hospitalizationOrderStore.fetchHospitalizationOrder(medicalInstitutionId, {
    allowNotFound: true
  })

  const hospitalizationOrder = hospitalizationOrderStore.getHospitalizationOrder()

  if (hospitalizationOrder) {
    patient.value = {
      id: hospitalizationOrder.patient.id,
      name: getFormattedName(
        hospitalizationOrder.patient.familyName,
        hospitalizationOrder.patient.givenName
      ),
      gender: hospitalizationOrder.patient.gender,
      birthDate: hospitalizationOrder.patient.birthDate
    }
  } else {
    // 代理人ではない場合かつ、入院オーダーがない場合の患者情報取得
    const response = await medicalInstitutionsApiClient().api.patientByUser(medicalInstitutionId, {
      userId: ''
    })

    patient.value = {
      id: response.data.id,
      name: getFormattedName(response.data.familyName, response.data.givenName),
      gender: response.data.gender,
      birthDate: response.data.birthDate
    }
  }
})
const representative = computed<Representative | undefined>(() => {
  const rawRepresentative = hospitalizationOrderStore.getRepresentative()
  if (rawRepresentative == undefined) return undefined

  return {
    id: rawRepresentative.id,
    name: getFormattedName(rawRepresentative.familyName, rawRepresentative.givenName),
    relationshipType: rawRepresentative.relationshipType
  }
})
</script>

<template>
  <v-container class="container">
    <MedicalInstitutionPatientConfirmation
      v-if="patient != undefined"
      :medicalInstitutionId="medicalInstitutionId"
      :hospitalizationOrderId="hospitalizationOrderStore.getHospitalizationOrder()?.id"
      :patient="patient"
      :representative="representative"
    />
  </v-container>
</template>

<style scoped lang="scss">
.container {
  height: 100%;
  padding: 16px;
}
</style>
