/* eslint-disable import/order */
import { createI18n } from 'vue-i18n'

import { features } from './ja/features'
import { attributes } from './ja/attributes'
import { errors } from './ja/errors'
import { pages } from './ja/pages'
import { validations } from './ja/validations'

const messages = {
  ja: {
    features,
    attributes,
    errors,
    pages,
    validations
  }
} as const

export default createI18n({
  legacy: false,
  locale: 'ja',
  fallbackLocale: 'ja',
  allowComposition: true,
  messages
})
