<script setup lang="ts">
import { computed, reactive } from 'vue'
import { useI18n } from 'vue-i18n'

import type { ChoiceOption } from './type'

import BaseBadge from '@/components/Base/Badge/index.vue'
import BaseLabel from '@/components/Base/Label/index.vue'

const { t } = useI18n()

// props初期化
const props = withDefaults(
  defineProps<{
    sectionIndex: number
    content: ChoiceOption
  }>(),
  {}
)

// 初期化
const content = reactive<ChoiceOption>(props.content)

// ラベルID発行
const labelId = computed(() => {
  return `${props.sectionIndex}_${props.content.id}`
})

// emit定義
const emit = defineEmits<{
  (e: 'change'): void
}>()

const onChange = () => {
  emit('change')
}

const isChecked = (choiceIndex: number) => {
  return props.content.selected === choiceIndex
}
</script>

<template>
  <div class="choice-container">
    <div>
      <BaseLabel :label-text="content.name" token-type="LABEL_L" class="label-wrapper"></BaseLabel>
      <div v-show="content.isRequired" class="badge-wrapper">
        <BaseBadge
          :label="t('attributes.required')"
          background-color="primitive-red-accent-40-value"
        ></BaseBadge>
      </div>
    </div>
    <div>
      <label :for="labelId">{{ content.description }}</label>
      <v-radio-group
        v-for="(choice, choiceIndex) in content.choices"
        :key="choiceIndex"
        v-model="content.selected"
        class="custom-checkbox"
        :class="{ 'custom-checkbox-on': isChecked(choiceIndex) }"
        hide-details
        @change="onChange"
      >
        <v-radio :label="choice.name" :value="choiceIndex"></v-radio>
      </v-radio-group>
      <div class="v-input__details hint-message-wrapper">
        <div class="v-messages v-messages__message">
          {{ t('features.hospitalizationDocument.FormContentSubmission.pleaseChoseOne') }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
// 選択肢フィールドコンテナ
.choice-container {
  padding: 16px 0 16px 0;
}
// ラベルラッパー要素
.label-wrapper {
  display: inline-block;
}
// バッジラッパー要素
.badge-wrapper {
  display: inline-block;
  height: 1.2lh;
  vertical-align: middle;
  :deep(.badge) {
    display: inline-block;
    height: 1.2lh;
    vertical-align: middle;
  }
}
// カスタムチェックボックスUI
.custom-checkbox {
  margin: 8px 0 0 0;
  // チェックボックスを装飾
  :deep(.v-selection-control) {
    border: 1px solid gray;
    border-radius: 4px;
    display: flex;
    justify-content: start;
    align-items: center;
    // 当たり判定を広げる
    label {
      width: 100%;
      padding: 8px;
    }
    // チェックボックスの図形を非表示
    .v-selection-control__wrapper {
      display: none;
    }
  }
}
// ON時のアイコン色
.custom-checkbox-on :deep(.v-selection-control) {
  border-color: #0017c1;
  background-color: #f0f1fb;
}
// ヒントメッセージの余白
.hint-message-wrapper {
  padding: 0 0 0 16px;
}
</style>
