import { default as typography } from '../../tokens/typography.json';

import type { NucleusTypographyTokenType } from './typography-types';

/**
 * タイポグラフィトークンを取得する関数
 *
 * @param {NucleusTypographyTokenType} tokenType - タイポグラフィトークンタイプ
 */
export const getTypography = (
  tokenType: NucleusTypographyTokenType,
): { fontSize: string; fontWeight: number; lineHeight: number } => {
  switch (tokenType) {
    case 'HEADLINE_L':
      return {
        fontSize: typography.fontSizes.x2l,
        fontWeight: typography.fontWeights.bold,
        lineHeight: typography.lineHeights.tight,
      };
    case 'HEADLINE_M':
      return {
        fontSize: typography.fontSizes.xl,
        fontWeight: typography.fontWeights.bold,
        lineHeight: typography.lineHeights.tight,
      };
    case 'HEADLINE_S':
      return {
        fontSize: typography.fontSizes.lg,
        fontWeight: typography.fontWeights.bold,
        lineHeight: typography.lineHeights.tight,
      };
    case 'TITLE_L':
      return {
        fontSize: typography.fontSizes.md,
        fontWeight: typography.fontWeights.bold,
        lineHeight: typography.lineHeights.normal,
      };
    case 'TITLE_L_UNDERLINE':
      return {
        fontSize: typography.fontSizes.md,
        fontWeight: typography.fontWeights.bold,
        lineHeight: typography.lineHeights.normal,
      };
    case 'TITLE_M':
      return {
        fontSize: typography.fontSizes.sm,
        fontWeight: typography.fontWeights.bold,
        lineHeight: typography.lineHeights.normal,
      };
    case 'TITLE_S':
      return {
        fontSize: typography.fontSizes.xs,
        fontWeight: typography.fontWeights.bold,
        lineHeight: typography.lineHeights.normal,
      };
    case 'LABEL_L':
      return {
        fontSize: typography.fontSizes.sm,
        fontWeight: typography.fontWeights.bold,
        lineHeight: typography.lineHeights.normal,
      };
    case 'LABEL_L_TIGHT':
      return {
        fontSize: typography.fontSizes.sm,
        fontWeight: typography.fontWeights.bold,
        lineHeight: typography.lineHeights.tight,
      };
    case 'LABEL_L_UNDERLINE':
      return {
        fontSize: typography.fontSizes.sm,
        fontWeight: typography.fontWeights.bold,
        lineHeight: typography.lineHeights.normal,
      };
    case 'LABEL_M':
      return {
        fontSize: typography.fontSizes.xs,
        fontWeight: typography.fontWeights.bold,
        lineHeight: typography.lineHeights.normal,
      };
    case 'LABEL_M_TIGHT':
      return {
        fontSize: typography.fontSizes.xs,
        fontWeight: typography.fontWeights.bold,
        lineHeight: typography.lineHeights.tight,
      };
    case 'LABEL_S':
      return {
        fontSize: typography.fontSizes.x2s,
        fontWeight: typography.fontWeights.bold,
        lineHeight: typography.lineHeights.normal,
      };
    case 'LABEL_S_TIGHT':
      return {
        fontSize: typography.fontSizes.x2s,
        fontWeight: typography.fontWeights.bold,
        lineHeight: typography.lineHeights.tight,
      };
    case 'BODY_L':
      return {
        fontSize: typography.fontSizes.sm,
        fontWeight: typography.fontWeights.regular,
        lineHeight: typography.lineHeights.normal,
      };
    case 'BODY_L_TIGHT':
      return {
        fontSize: typography.fontSizes.sm,
        fontWeight: typography.fontWeights.regular,
        lineHeight: typography.lineHeights.tight,
      };
    case 'BODY_L_UNDERLINE':
      return {
        fontSize: typography.fontSizes.sm,
        fontWeight: typography.fontWeights.regular,
        lineHeight: typography.lineHeights.normal,
      };
    case 'BODY_M':
      return {
        fontSize: typography.fontSizes.xs,
        fontWeight: typography.fontWeights.regular,
        lineHeight: typography.lineHeights.normal,
      };
    case 'BODY_M_TIGHT':
      return {
        fontSize: typography.fontSizes.xs,
        fontWeight: typography.fontWeights.regular,
        lineHeight: typography.lineHeights.tight,
      };
    case 'BODY_M_UNDERLINE':
      return {
        fontSize: typography.fontSizes.xs,
        fontWeight: typography.fontWeights.regular,
        lineHeight: typography.lineHeights.normal,
      };
    case 'BODY_S':
      return {
        fontSize: typography.fontSizes.x2s,
        fontWeight: typography.fontWeights.regular,
        lineHeight: typography.lineHeights.normal,
      };
    case 'BODY_S_TIGHT':
      return {
        fontSize: typography.fontSizes.x2s,
        fontWeight: typography.fontWeights.regular,
        lineHeight: typography.lineHeights.tight,
      };
    case 'BODY_S_UNDERLINE':
      return {
        fontSize: typography.fontSizes.x2s,
        fontWeight: typography.fontWeights.regular,
        lineHeight: typography.lineHeights.normal,
      };
    case 'CAPTION':
      return {
        fontSize: typography.fontSizes.x3s,
        fontWeight: typography.fontWeights.regular,
        lineHeight: typography.lineHeights.normal,
      };
    case 'CAPTION_TIGHT':
      return {
        fontSize: typography.fontSizes.x3s,
        fontWeight: typography.fontWeights.regular,
        lineHeight: typography.lineHeights.tight,
      };
    default:
      // デフォルトはBODY_M
      return {
        fontSize: typography.fontSizes.xs,
        fontWeight: typography.fontWeights.regular,
        lineHeight: typography.lineHeights.normal,
      };
  }
};
