<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import type { ParHospitalizationOrderSubmissionFormContentResponse } from '@/api/generate/apiSchema'

import { usePathParameters } from '@/composables/usePathParameters'
import HospitalizationFormContentConfirm from '@/features/FormContentSubmission/confirm/index.vue'
import router from '@/router'
import { useDisplayNameStore } from '@/stores/displayName'
import { useHospitalizationOrderStore } from '@/stores/hospitalizationOrder'
import { useHospitalizationOrderFormContentStore } from '@/stores/hospitalizationOrderFormContent'

const pathParameters = usePathParameters(useRoute())
const hospitalizationOrderStore = useHospitalizationOrderStore()
const medicalInstitutionId = pathParameters.medicalInstitutionId
const hospitalizationOrderDocumentSetId = pathParameters.hospitalizationOrderDocumentSetId
const hospitalizationOrderDocumentSetContentId =
  pathParameters.hospitalizationOrderDocumentSetContentId
// フォームコンテンツ
const contentJson = ref<ParHospitalizationOrderSubmissionFormContentResponse['contentJson']>()
// ローディング管理
const isLoading = ref<boolean>(false)

// 入院オーダーID
const hospitalizationOrderFormContentStore = useHospitalizationOrderFormContentStore()
const hospitalizationOrderId = useHospitalizationOrderStore().getHospitalizationOrder()?.id ?? ''

// フォームコンテンツの取得
const fetchContentJson = async () => {
  isLoading.value = true

  try {
    const response = await hospitalizationOrderFormContentStore.fetchContentJson(
      medicalInstitutionId,
      hospitalizationOrderId,
      hospitalizationOrderDocumentSetId,
      hospitalizationOrderDocumentSetContentId,
      hospitalizationOrderStore.getRepresentativeId()
    )
    if (response.success) {
      if (hospitalizationOrderFormContentStore.isSubmitted()) {
        // 既に回答済みの場合、コンテンツ詳細画面へ戻す
        router.replace({
          name: 'DGHP-006-06',
          params: {
            medicalInstitutionId,
            hospitalizationOrderDocumentSetId,
            hospitalizationOrderDocumentSetContentId
          }
        })
        return
      }

      isLoading.value = false
      contentJson.value = response.content
      return
    }

    // リクエスト失敗時
    isLoading.value = false
    throw new Error('Request failed.')
  } catch (error) {
    isLoading.value = false
    throw error
  }
}

onMounted(async () => {
  const displayNameStore = useDisplayNameStore()
  displayNameStore.updateDisplayName('pages.navigationTitles.answersList')
  await fetchContentJson()
})
</script>

<template>
  <v-container class="form-content-confirm-container">
    <div v-if="!isLoading && contentJson != undefined && hospitalizationOrderId != undefined">
      <HospitalizationFormContentConfirm
        :contentJson="contentJson"
        :medicalInstitutionId="medicalInstitutionId"
        :hospitalizationOrderId="hospitalizationOrderId"
        :hospitalizationOrderDocumentSetContentId="hospitalizationOrderDocumentSetContentId"
        :hospitalizationOrderDocumentSetId="hospitalizationOrderDocumentSetId"
      ></HospitalizationFormContentConfirm>
    </div>
  </v-container>
</template>

<style scoped lang="scss">
.form-content-confirm-container {
  margin: 0 auto;
  padding: 0 16px 16px 16px;
}
</style>
