import { defineStore } from 'pinia'
import { ref } from 'vue'

import { usersApiClient } from '@/api/apiClient'

export const useDigihosTermsStore = defineStore('digihosTerms', () => {
  /**
   * デジホスの利用規約同意済み状態
   * @param userId
   * @returns ユーザー情報取得
   */
  const termsAgreed = ref<boolean>()
  const fetchDigihosTerms = async (): Promise<boolean> => {
    const response = await usersApiClient().api.userByUser({ userId: '' })
    termsAgreed.value = response.data.termsAgreed
    return response.data.termsAgreed
  }

  const isAgreedForDigihosTerms = async (): Promise<boolean> => {
    if (termsAgreed.value == undefined) {
      return await fetchDigihosTerms()
    } else {
      return termsAgreed.value
    }
  }

  return {
    isAgreedForDigihosTerms,
    fetchDigihosTerms
  }
})
