//  APIのレスポンスのような、画面に表示する要素を指定するファイル

export const attributes = {
  role: {
    admin: '管理者',
    general: '一般',
    read: '閲覧'
  },
  logout: 'ログアウト',
  register: '登録',
  cancel: 'キャンセル',
  decision: '決定',
  close: '閉じる',
  restart: '再読み込み',
  successRegistration: '{resource}の登録に成功しました',
  required: '必須',
  comma: '、',
  relationshipType: {
    wife: '妻',
    husband: '夫',
    biological_mother: '実母',
    biological_father: '実父',
    mother_in_law: '義母',
    father_in_law: '義父',
    eldest_son: '長男',
    eldest_daughter: '長女',
    second_son: '次男',
    second_daughter: '次女',
    third_son: '三男',
    third_daughter: '三女',
    fourth_son: '四男',
    fourth_daughter: '四女',
    fifth_son: '五男',
    fifth_daughter: '五女',
    older_brother: '兄',
    older_sister: '姉',
    younger_brother: '弟',
    younger_sister: '妹',
    grand_father: '祖父',
    grand_mother: '祖母',
    grand_son: '孫息子',
    grand_daughter: '孫娘',
    uncle: '叔父',
    aunt: '叔母',
    nephew: '甥',
    niece: '姪',
    other: 'その他'
  },
  gender: {
    1: '男性',
    2: '女性'
  },
  documentSetStatus: {
    notSubmitted: '未提出',
    partialSubmitted: '一部提出',
    submitted: '提出済',
    confirmed: '確定済'
  },
  documentSetContentStatus: {
    notSubmitted: '未提出',
    submitted: '提出済',
    confirmed: '確定済'
  },
  formatTemplate: {
    fullAge: ' (満{resource}歳)'
  }
}
