import { createRouter, createWebHistory } from 'vue-router'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import { getUserId } from '@/api/userDataCookie'
import InterruptFormHeaderButton from '@/features/FormContentSubmission/edit/InterruptFormHeaderButton.vue'
import StepBackHeaderButton from '@/features/FormContentSubmission/edit/StepBackHeaderButton.vue'
import BackToMedicalInstitutionListButton from '@/features/MedicalInstitutionDetail/BackToMedicalInstitutionListButton.vue'
import AppVersion from '@/pages/AppVersion.vue'
import DetailDocumentSetContent from '@/pages/DetailDocumentSetContent.vue'
import DetailMedicalInstitution from '@/pages/DetailMedicalInstitution.vue'
import DigihosAgreement from '@/pages/DigihosAgreement.vue'
import DocumentSetContentList from '@/pages/DocumentSetContentList.vue'
import DocumentSetList from '@/pages/DocumentSetList.vue'
import EditHospitalizationFormContent from '@/pages/EditHospitalizationFormContent.vue'
import Forbidden from '@/pages/error/Forbidden.vue'
import NotFound from '@/pages/error/NotFound.vue'
import ServerError from '@/pages/error/ServerError.vue'
import HospitalizationFormContentComplete from '@/pages/HospitalizationFormContentComplete.vue'
import HospitalizationFormContentConfirm from '@/pages/HospitalizationFormContentConfirm.vue'
import MedicalInstitutionAgreement from '@/pages/MedicalInstitutionAgreement.vue'
import MedicalInstitutionList from '@/pages/MedicalInstitutionList.vue'
import MedicalInstitutionPatientConfirmation from '@/pages/MedicalInstitutionPatientConfirmation.vue'
import { useDigihosTermsStore } from '@/stores/digihosTermsStore'
import { useHospitalizationOrderStore } from '@/stores/hospitalizationOrder'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      // デジホスの利用規約とプライバシーポリシーの同意画面
      path: '/agreement',
      name: 'DGHP-008-01',
      component: DigihosAgreement,
      meta: {
        // ボトムメニューの表示・非表示
        showBottomMenu: false,
        // ナビゲーションバーの戻るボタン表示・非表示
        showBackIcon: false,
        // ナビゲーションバーのページリロードボタン表示・非表示
        showReloadPageIcon: false,
        notAuth: true,
        // ヘッダーの存在の有無
        nonHeader: true
      }
    },
    {
      // 医療機関一覧画面 「/」と 「/medical-institutions」に一致する
      path: '/:params(medical-institutions)?',
      name: 'DGHP-002-01',
      component: MedicalInstitutionList,
      meta: {
        // ボトムメニューの表示・非表示
        showBottomMenu: false,
        // ナビゲーションバーの戻るボタン表示・非表示
        showBackIcon: false,
        // ナビゲーションバーのページリロードボタン表示・非表示
        showReloadPageIcon: true,
        customThemeName: 'exDigihosTheme'
      }
    },
    {
      // 医療機関の利用規約とプライバシーポリシーの同意画面
      path: '/medical-institutions/:medicalInstitutionId/agreement',
      name: 'DGHP-004-02',
      component: MedicalInstitutionAgreement,
      meta: {
        // ボトムメニューの表示・非表示
        showBottomMenu: false,
        // ナビゲーションバーの戻るボタン表示
        showBackIcon: true,
        // ナビゲーションバーのページリロードボタン表
        showReloadPageIcon: false,
        //ヘッダーの存在有無
        nonHeader: true,
        navTitle: 'pages.navigationTitles.terms',
        customThemeName: 'digihosTheme'
      }
    },
    {
      // 患者情報確認画面
      path: '/medical-institutions/:medicalInstitutionId/patients/:patientId',
      name: 'DGHP-004-01',
      component: MedicalInstitutionPatientConfirmation,
      meta: {
        // ボトムメニューの表示
        showBottomMenu: false,
        // ナビゲーションバーの戻るボタン表示・非表示
        showBackIcon: true,
        // ナビゲーションバーのページリロードボタン表示
        showReloadPageIcon: true,
        medicalInstitutionTermsAuth: true, // 医療機関の利用規約に同意しているか
        customThemeName: 'digihosTheme'
      }
    },
    {
      // 入院手続きホーム画面
      path: '/medical-institutions/:medicalInstitutionId',
      name: 'DGHP-005-01',
      component: DetailMedicalInstitution,
      meta: {
        showBottomMenu: false,
        showBackIcon: true,
        useHomeBar: true,
        leftHeaderComponent: BackToMedicalInstitutionListButton,
        showReloadPageIcon: false,
        medicalInstitutionAuth: true
      }
    },
    {
      // DGHP-005-02 書類セット一覧画面
      path: '/medical-institutions/:medicalInstitutionId/hospitalization-order-document-sets',
      name: 'DGHP-005-02',
      component: DocumentSetList,
      meta: {
        showBackIcon: true,
        navTitle: 'pages.navigationTitles.hospitalizationDocumentsList',
        medicalInstitutionAuth: true
      }
    },
    {
      // DGHP-005-03 コンテンツ一覧画面
      path: '/medical-institutions/:medicalInstitutionId/hospitalization-order-document-sets/:hospitalizationOrderDocumentSetId/hospitalization-order-document-set-contents',
      name: 'DGHP-005-03',
      component: DocumentSetContentList,
      meta: {
        showBackIcon: true,
        medicalInstitutionAuth: true
      }
    },
    {
      // DGHP-006-06 コンテンツ詳細画面
      path: '/medical-institutions/:medicalInstitutionId/hospitalization-order-document-sets/:hospitalizationOrderDocumentSetId/hospitalization-order-document-set-contents/:hospitalizationOrderDocumentSetContentId',
      name: 'DGHP-006-06',
      component: DetailDocumentSetContent,
      meta: {
        showBackIcon: true,
        medicalInstitutionAuth: true
      }
    },
    {
      // DGHP-006-01 フォーム回答画面
      path: '/medical-institutions/:medicalInstitutionId/hospitalization-order-document-sets/:hospitalizationOrderDocumentSetId/hospitalization-order-document-set-contents/:hospitalizationOrderDocumentSetContentId/edit',
      name: 'DGHP-006-01',
      component: EditHospitalizationFormContent,
      meta: {
        showBottomMenu: false,
        leftHeaderComponent: StepBackHeaderButton,
        rightHeaderComponent: InterruptFormHeaderButton,
        customThemeName: 'exDigihosTheme',
        medicalInstitutionAuth: true
      }
    },
    {
      // DGHP-006-02 フォーム回答確認画面
      path: '/medical-institutions/:medicalInstitutionId/hospitalization-order-document-sets/:hospitalizationOrderDocumentSetId/hospitalization-order-document-set-contents/:hospitalizationOrderDocumentSetContentId/confirm',
      name: 'DGHP-006-02',
      component: HospitalizationFormContentConfirm,
      meta: {
        customThemeName: 'exDigihosTheme',
        medicalInstitutionAuth: true
      }
    },
    {
      // DGHP-006-03 フォーム回答完了画面
      path: '/medical-institutions/:medicalInstitutionId/hospitalization-order-document-sets/:hospitalizationOrderDocumentSetId/hospitalization-order-document-set-contents/:hospitalizationOrderDocumentSetContentId/complete',
      name: 'DGHP-006-03',
      component: HospitalizationFormContentComplete,
      meta: {
        // ボトムメニューの表示・非表示
        showBottomMenu: false,
        // ナビゲーションバーの戻るボタン表示・非表示
        showBackIcon: false,
        // ナビゲーションバーのページリロードボタン表示・非表示
        showReloadPageIcon: false,
        medicalInstitutionAuth: true,
        customThemeName: 'exDigihosTheme'
      }
    },
    {
      path: '/server_error',
      name: 'server_error',
      component: ServerError,
      meta: {
        // ボトムメニューの表示・非表示
        showBottomMenu: false,
        // ナビゲーションバーの戻るボタン表示・非表示
        showBackIcon: false,
        // ナビゲーションバーのページリロードボタン表示・非表示
        showReloadPageIcon: false,
        notAuth: true
      }
    },
    {
      path: '/forbidden',
      name: 'forbidden',
      component: Forbidden,
      meta: {
        // ボトムメニューの表示・非表示
        showBottomMenu: false,
        // ナビゲーションバーの戻るボタン表示・非表示
        showBackIcon: false,
        // ナビゲーションバーのページリロードボタン表示・非表示
        showReloadPageIcon: false,
        notAuth: true
      }
    },
    {
      path: '/not_found',
      name: 'not_found',
      component: NotFound,
      meta: {
        // ボトムメニューの表示・非表示
        showBottomMenu: false,
        // ナビゲーションバーの戻るボタン表示・非表示
        showBackIcon: false,
        // ナビゲーションバーのページリロードボタン表示・非表示
        showReloadPageIcon: false,
        notAuth: true
      }
    },
    {
      // アプリのバージョン確認画面
      path: '/version',
      name: 'version',
      component: AppVersion,
      meta: {
        notAuth: true
      }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  const notAuth = to.matched.some((record) => record.meta.notAuth)
  if (notAuth) {
    next()
    return
  }

  const userId = getUserId()
  const digihosTermsStore = useDigihosTermsStore()
  // ユーザー情報の有無確認 + 利用規約の同意確認
  if (userId == undefined || userId == '' || !(await digihosTermsStore.isAgreedForDigihosTerms())) {
    next('/agreement')
    return
  }

  const hospitalizationOrderStore = useHospitalizationOrderStore()
  // 利用規約の同意済みチェック
  const medicalInstitutionTermsAuth = to.matched.some(
    (record) => record.meta.medicalInstitutionTermsAuth
  )
  // 利用規約 + 患者情報の確認済みチェック
  const medicalInstitutionAuth = to.matched.some((record) => record.meta.medicalInstitutionAuth)
  // どちらかでも対象の場合、医療機関情報を取得
  if (medicalInstitutionTermsAuth || medicalInstitutionAuth) {
    const medicalInstitutionId = to.params.medicalInstitutionId as string
    // 医療機関取得 見つからない場合共通エラーハンドリングで404へ遷移
    const response = await medicalInstitutionsApiClient().api.medicalInstitutionByUser(
      medicalInstitutionId,
      { userId: '' }
    )
    // 利用規約同意済ではない場合、医療機関利用規約同意ページへ
    if (!response.data.termsAgreed) {
      next(`/medical-institutions/${medicalInstitutionId}/agreement`)
    }

    // 患者情報の確認済みチェックが必要な場合
    if (medicalInstitutionAuth) {
      // 入院オーダー・代理人情報の取得 404は許容する
      const hospitalizationOrder = await hospitalizationOrderStore.fetchHospitalizationOrder(
        medicalInstitutionId,
        { allowNotFound: true }
      )
      const representative = hospitalizationOrderStore.getRepresentative()

      if (hospitalizationOrder && representative) {
        // 代理人の場合
        if (!representative.patientConfirmed) {
          // 患者未確認の場合
          next(
            `/medical-institutions/${medicalInstitutionId}/patients/${hospitalizationOrder.patient.id}`
          )
        }
      } else {
        // 患者の場合 見つからない場合共通エラーによる404
        const response = await medicalInstitutionsApiClient().api.patientByUser(
          medicalInstitutionId,
          { userId: '' }
        )
        if (!response.data.patientConfirmed) {
          next(`/medical-institutions/${medicalInstitutionId}/patients/${response.data.id}`)
        }
      }
    }
  }

  next()
})

export default router
