<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue'

import MedicalInstitutionList from '../features/MedicalInstitutionList/index.vue'

import type { MedicalInstitution } from '@/features/MedicalInstitutionList/type'

import { hospitalizationOrderApiClient, usersApiClient } from '@/api/apiClient'
import { sendBindingToken } from '@/api/sendUserEvent'
import { getBindingTokenId, setBindingToken, setBindingTokenId } from '@/api/userDataCookie'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'
import router from '@/router'
import { useDisplayNameStore } from '@/stores/displayName'

const axiosErrorGuard = useAxiosErrorGuard()

const medicalInstitutionList = ref<MedicalInstitution[] | undefined>([])
const bindingToken = ref<string | undefined>()
let stopPolling: (() => void) | undefined = undefined

onMounted(async () => {
  const displayNameStore = useDisplayNameStore()
  displayNameStore.updateDisplayName('pages.medicalInstitution.title')
  // ネイティブからuserIdと認証用トークンを受け取る（Cookieに含まれている情報から取得）
  // 紐付け用トークン作成APIを実行
  try {
    const response = await usersApiClient().api.createMedicalInstitutionBindingTokenByUser({
      userId: ``
    })
    bindingToken.value = response.data.bindingToken
    sendBindingToken(response.data.id, response.data.bindingToken)

    // デバッグ用 開発環境では、ブラウザでも動作確認可能になるように処理する
    if (import.meta.env.DEV && import.meta.env.VITE_PREVIEW_BROWSER) {
      setBindingTokenId(response.data.id)
      setBindingToken(response.data.bindingToken)
    }
  } catch (error: unknown) {
    throw Error('Unexpected error')
  }

  try {
    // 医療機関一覧の取得APIを実行してmedicalInstitutionsにセットする
    const responseByUser = await usersApiClient().api.medicalInstitutionsByUser({
      userId: ``,
      bindingTokenId: ``
    })
    const responseByRepresentative =
      await hospitalizationOrderApiClient().api.medicalInstitutionsByRepresentative({
        userId: ''
      })
    usersApiClient().api.medicalInstitutionsByRepresentative
    const medicalInstitutionIdsByUser = responseByUser.data.list.map(
      (medicalInstitution) => medicalInstitution.id
    )
    // 医療機関情報を、患者として連携しているものと、代理人として連携しているものを結合する。名前の昇順でソートする
    medicalInstitutionList.value = [
      ...responseByUser.data.list,
      ...responseByRepresentative.data.list.filter((medicalInstitutionByRepresentative) => {
        return !medicalInstitutionIdsByUser.includes(medicalInstitutionByRepresentative.id)
      })
    ].sort((a, b) => (a.name > b.name ? 1 : -1))
  } catch (error: unknown) {
    throw Error('Unexpected error')
  }

  stopPolling = pollingStart()
})

onBeforeUnmount(() => {
  if (stopPolling) {
    stopPolling()
  }
})

const pollingStart = () => {
  const bindingTokenId = getBindingTokenId()

  let isStop = false

  const stopPolling = () => {
    isStop = true
  }

  const polling = async () => {
    for (;;) {
      try {
        const responseByUser = await usersApiClient().api.medicalInstitutionsByUser({
          userId: '',
          bindingTokenId: bindingTokenId
        })

        if (isStop) {
          return
        }

        if (!(responseByUser.data.list.length == 0)) {
          const medicalInstitutionId = responseByUser.data.list[0].id
          router.push({ path: `/medical-institutions/${medicalInstitutionId}` })
          return
        } else {
          const responseByRepresentative = await hospitalizationOrderApiClient({
            allowNotFound: true
          })
            .api.medicalInstitutionsByRepresentative({
              userId: '',
              bindingTokenId: bindingTokenId
            })
            .catch((error) => {
              if (axiosErrorGuard.isNotFound(error)) {
                return undefined
              }
              throw error
            })

          if (isStop) {
            return
          }

          if (responseByRepresentative != undefined) {
            const medicalInstitutionId = responseByRepresentative.data.list[0].id
            router.push({ path: `/medical-institutions/${medicalInstitutionId}` })
            return
          }
        }
      } catch (error: unknown) {
        throw Error('Unexpected error')
      }
      await new Promise((resolve) => setTimeout(resolve, 5000))
    }
  }
  polling()

  return stopPolling
}
</script>

<template>
  <v-container class="container">
    <MedicalInstitutionList
      v-if="medicalInstitutionList != undefined && bindingToken != undefined"
      :medicalInstitutionList="medicalInstitutionList"
      :bindingToken="bindingToken"
    />
  </v-container>
</template>

<style lang="scss" scoped>
.container {
  padding: 16px;
}
</style>
