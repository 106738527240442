<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import type { MedicalInstitutionCardType } from '@/features/MedicalInstitutionDetail/MedicalInstitutionCard/types'

import { usersApiClient } from '@/api/apiClient'
import MedicalInstitutionDetail from '@/features/MedicalInstitutionDetail/index.vue'
import { useDisplayNameStore } from '@/stores/displayName'

const route = useRoute()
const medicalInstitutionId =
  typeof route.params.medicalInstitutionId == 'string' ? route.params.medicalInstitutionId : ''

const hospitalizationOrder = ref<MedicalInstitutionCardType>()
const medicalInstitutionName = ref<string>('')

onMounted(async () => {
  const displayNameStore = useDisplayNameStore()
  displayNameStore.updateDisplayName('pages.navigationTitles.home')
  // 入院詳細を取得する
  try {
    const response = await usersApiClient().api.latestHospitalizationOrderByPar(
      medicalInstitutionId,
      { userId: '' }
    )
    hospitalizationOrder.value = {
      id: response.data.id,
      hospitalizationOrderStatus: response.data.hospitalizationOrderStatus,
      hospitalDepartment: response.data.hospitalDepartment,
      ward: response.data.ward,
      hospitalizationDate: response.data.hospitalizationDate,
      patient: response.data.patient,
      dischargeDate: response.data.dischargeDate
    }
  } catch (error: unknown) {
    throw Error('Unexpected error')
  }

  try {
    const response = await usersApiClient().api.medicalInstitutionByUser(medicalInstitutionId, {
      userId: ''
    })
    medicalInstitutionName.value = response.data.name
  } catch (error: unknown) {
    throw Error('Unexpected error')
  }
})
</script>
<template>
  <v-container>
    <MedicalInstitutionDetail
      v-if="hospitalizationOrder != undefined"
      :medicalInstitutionId="medicalInstitutionId"
      :hospitalizationOrder="hospitalizationOrder"
      :medicalInstitutionName="medicalInstitutionName"
    />
  </v-container>
</template>

<style></style>
