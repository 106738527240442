import type { ConstantsType } from '@/composables/type'

/**
 * 共通定数
 * @returns CONSTANTS
 */
export const useConstants = () => {
  const CONSTANTS: ConstantsType = {
    /**
     * 性別
     */
    GENDER: {
      /**
       * 男性
       */
      MALE: 1,
      /**
       * 女性
       */
      FEMALE: 2
    },

    /**
     * コンテンツタイプ
     */
    CONTENT_TYPE: {
      /**
       * 回答フォーム
       */
      FORM: 'form',
      /**
       * URL
       */
      URL: 'url'
    },
    /**
     * 書類セットのステータス
     */
    DOCUMENT_SET_STATUS: {
      /**
       * 確認済
       */
      CONFIRMED: 'confirmed',
      /**
       * 提出済
       */
      SUBMITTED: 'submitted',
      /**
       * 一部提出
       */
      PARTIAL_SUBMITTED: 'partial_submitted',
      /**
       * 未提出
       */
      NOT_SUBMITTED: 'not_submitted'
    },
    /**
     * 書類セットのステータス
     */
    CONTENT_STATUS: {
      /**
       * 確認済
       */
      CONFIRMED: 'confirmed',
      /**
       * 提出済
       */
      SUBMITTED: 'submitted',
      /**
       * 未提出
       */
      NOT_SUBMITTED: 'not_submitted'
    }
  }
  return {
    CONSTANTS
  }
}
