<script setup lang="ts">
import { getTypography, muiThemeColors } from '@optim-design-system/src'
import { v4 as uuidV4 } from 'uuid'
import { reactive } from 'vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import CheckBoxForm from './CheckBoxForm.vue'
import DateForm from './DateForm.vue'
import QuantitativeForm from './QuantitativeForm.vue'
import TextForm from './TextForm.vue'
import {
  FormOptionType,
  type InputSetAllowedType,
  type InputSetGroupType,
  type InputSetOption,
  type InputSetTemplate,
  type TemplateList
} from './type'

import BaseLabel from '@/components/Base/Label/index.vue'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    /**
     * セクションインデックス番号
     */
    sectionIndex: number
    /**
     * 入力セットデータ本体
     */
    content: InputSetOption
    /**
     * 表示させるフォームコンテンツのテンプレートリスト
     */
    templateList: TemplateList
  }>(),
  {}
)
const content = reactive<InputSetOption>(props.content)
const templateList = reactive<TemplateList>(props.templateList)

// 書式
const bodyStyle = computed(() => {
  return getTypography('LABEL_L')
})

// バッジの配色
const colorStyle = computed(() => {
  return {
    color: muiThemeColors['theme-DEFAULT-surface-brand-primary-value'],
    borderColor: muiThemeColors['theme-DEFAULT-surface-brand-primary-value']
  }
})

// 追加の上限数を管理
const maxLength = 5

// 入力セットテンプレートの取得
const inputSetTemplate = computed<InputSetTemplate[]>(() => {
  // ガード
  if (typeof templateList === 'undefined') {
    throw new Error('InputSet list is empty.')
  }
  return templateList[content.id].template
})

// 入力セットグループの追加
const addInputSet = () => {
  if (content.values.length < maxLength) {
    // テンプレートの取得
    const template = inputSetTemplate.value
    // テンプレートから新しい入力セットグループを作成
    const group = Object.entries(template[0]).map(([key, value]) => ({ [key]: value }))
    // content.values にグループを追加
    content.values.push(JSON.parse(JSON.stringify(group)))
  }
}

// 入力セットグループの削除
const deleteInputSetGroup = (inputSetKey: number) => {
  content.values.splice(inputSetKey, 1)
}

// 入力セットグループタイトル
const withGroupNumber = (title: string, index: number) => {
  return `${title} ${index + 1}`
}

// 追加ボタンタイトル
const buttonTitle = computed(() => {
  return `${content.name} ${t('features.hospitalizationDocument.FormContentSubmission.addInputSetButtonCaption')}`
})

// 型整形
const reshape = <T extends InputSetGroupType>(
  inputSetKey: number,
  innerKey: number,
  contentKey: string,
  type: T
): InputSetAllowedType<T> => {
  const value = content.values[inputSetKey][innerKey][contentKey]
  switch (type) {
    case FormOptionType.Text:
    case FormOptionType.CheckBox:
    case FormOptionType.Quantitative:
    case FormOptionType.Date:
      return { id: contentKey, ...value } as InputSetAllowedType<T>
    default:
      throw new Error(
        `Unknown argument. Must be content type kind of Text, CheckBox, Quantitative, Date.`
      )
  }
}

//  テキストフォームの値更新
const onChangeText = (
  newValue: string,
  inputSetIndex: number,
  innerIndex: number,
  contentKey: string
) => {
  const v = content.values[inputSetIndex][innerIndex][contentKey]
  if (v.type === FormOptionType.Text) {
    v.value = newValue
  }
}

//  テキストフォームの値更新
const onChangeCheckBox = (
  newValue: number[],
  inputSetIndex: number,
  innerIndex: number,
  contentKey: string
) => {
  const v = content.values[inputSetIndex][innerIndex][contentKey]
  if (v.type === FormOptionType.CheckBox) {
    v.selected.splice(0, v.selected.length, ...newValue)
  }
}

//  テキストフォームの値更新
const onChangeQuantitative = (
  newValue: number | null,
  inputSetIndex: number,
  innerIndex: number,
  contentKey: string
) => {
  const v = content.values[inputSetIndex][innerIndex][contentKey]
  if (v.type === FormOptionType.Quantitative) {
    v.value = newValue
  }
}

//  日付フォームの値更新
const onChangeDate = (
  newValue: string | null,
  inputSetIndex: number,
  innerIndex: number,
  contentKey: string
) => {
  const v = content.values[inputSetIndex][innerIndex][contentKey]
  if (v.type === FormOptionType.Date) {
    v.value = newValue
  }
}

// ランダムなIDを取得
// 入力セット中で増やしたコンポーネントのIDに使用
const getAlternativeId = () => {
  return uuidV4()
}
</script>

<template>
  <div>
    <div>
      <div
        v-for="(inputSetValue, inputSetIndex) in content.values"
        :key="inputSetIndex"
        class="input-set-container"
      >
        <div>
          <BaseLabel
            :label-text="withGroupNumber(content.name, inputSetIndex)"
            class="content-title"
            token-type="LABEL_L"
          ></BaseLabel>
          <div class="delete-button-container">
            <v-btn
              class="delete-button"
              label="option"
              :v-show="true"
              valiant="plain"
              rounded="lg"
              tabindex="-1"
              flat
              @click="deleteInputSetGroup(inputSetIndex)"
            >
              <v-icon class="section-container-header-col--icon" icon="delete_outline" />
            </v-btn>
          </div>
        </div>
        <div
          v-for="(innerValue, innerIndex) in inputSetValue"
          :key="innerIndex"
          class="input-set-group-container"
        >
          <div
            v-for="(contentValue, contentKey) in innerValue"
            :key="contentKey"
            class="input-set-group-container"
          >
            <TextForm
              v-if="FormOptionType.Text === contentValue.type"
              :section-index="sectionIndex"
              :alternative-id="getAlternativeId()"
              @onChange="
                (newValue: string) => onChangeText(newValue, inputSetIndex, innerIndex, contentKey)
              "
              :content="reshape(inputSetIndex, innerIndex, contentKey, FormOptionType.Text)"
            ></TextForm>
            <CheckBoxForm
              v-if="FormOptionType.CheckBox === contentValue.type"
              :section-index="sectionIndex"
              :content="reshape(inputSetIndex, innerIndex, contentKey, FormOptionType.CheckBox)"
              :alternative-id="getAlternativeId()"
              @updateValue="
                (newValue: number[]) =>
                  onChangeCheckBox(newValue, inputSetIndex, innerIndex, contentKey)
              "
            ></CheckBoxForm>
            <QuantitativeForm
              v-if="FormOptionType.Quantitative === contentValue.type"
              :section-index="sectionIndex"
              :alternative-id="getAlternativeId()"
              @onChange="
                (newValue: number | null) =>
                  onChangeQuantitative(newValue, inputSetIndex, innerIndex, contentKey)
              "
              :content="reshape(inputSetIndex, innerIndex, contentKey, FormOptionType.Quantitative)"
            ></QuantitativeForm>
            <DateForm
              v-if="FormOptionType.Date === contentValue.type"
              :section-index="sectionIndex"
              :alternative-id="getAlternativeId()"
              @onChange="
                (newValue: string | null) =>
                  onChangeDate(newValue, inputSetIndex, innerIndex, contentKey)
              "
              :content="reshape(inputSetIndex, innerIndex, contentKey, FormOptionType.Date)"
            ></DateForm>
          </div>
        </div>
      </div>
    </div>
    <v-btn
      class="action-button"
      :style="[bodyStyle, colorStyle]"
      flat
      stacked
      @click="addInputSet"
      >{{ buttonTitle }}</v-btn
    >
  </div>
</template>

<style scoped lang="scss">
// 入力セット動的フォームコンテナ
.input-set-container {
  display: flex;
  flex-direction: column;
  gap: -4px;
  margin: 16px 0 20px 0px;
}
// 入力セットグループコンテナ
.input-set-group-container {
  margin: 4px 0;
}
.action-button {
  width: 100%;
  height: fit-content;
  min-height: 36px;
  padding: 4px;
  align-items: center;
  border: 2px solid;
  border-radius: 16px;
  :deep(.v-btn__content) {
    font-weight: 600;
    word-break: break-all;
  }
}
// コンテントの説明文要素
.content-title {
  display: inline;
}
// 入力セット動的フォームコンテナ削除ボタンコンテナ
.delete-button-container {
  float: right;
}
// 入力セット動的フォームコンテナ削除ボタン
.delete-button {
  min-width: auto;
}
</style>
