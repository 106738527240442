<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue'

import CheckBoxForm from './CheckBoxForm.vue'
import ChoicesForm from './ChoicesForm.vue'
import DateForm from './DateForm.vue'
import { setCleanValue, setInitValue } from './formContentFunction'
import InputSetForm from './InputSetForm.vue'
import QuantitativeForm from './QuantitativeForm.vue'
import SectionContainer from './SectionContainer.vue'
import TextForm from './TextForm.vue'
import {
  type FormOption,
  type SectionContainer as SectionContainerType,
  FormOptionType,
  BranchName,
  type TextOption,
  type ChoiceOption,
  type CheckBoxOption,
  type QuantitativeOption,
  type DateOption,
  type InputSetOption,
  type FormOptionForInputSet,
  type TemplateList,
  type InputSetTemplate
} from './type'

const props = withDefaults(
  defineProps<{
    /**
     * フェッチしたフォームコンテンツの配列
     * */
    fetchedContentJson: SectionContainerType[]
    /**
     * 参照中のセクション番号
     */
    currentSectionNumber: number
  }>(),
  {}
)
const fetchedContentJson = reactive<SectionContainerType[]>(props.fetchedContentJson)

// emit定義
const emit = defineEmits<{
  (e: 'onChange', sectionContainers: SectionContainerType[]): void
  (e: 'goNext'): void
  (e: 'goConfirm'): void
}>()

// answerJsonの初回描画フラグ
const initVisible = ref<boolean>(false)

// 回答提出用JSONの初期化（デフォルト値を設定）
const initAnswerJson = (): SectionContainerType[] => {
  const copied = JSON.parse(JSON.stringify(fetchedContentJson))
  copied.forEach((s: SectionContainerType) => {
    s.options.forEach((fo: FormOption) => {
      setInitValue(fo)
    })
  })
  return copied
}

// 回答提出用JSON初期化
const answerJson = reactive<SectionContainerType[]>(initAnswerJson())
// 表示用JSON初期化
const visibleJson = reactive<SectionContainerType[]>(initAnswerJson())

// 選択肢の状態を表示用JSONに反映
const refreshVisibleJson = (): void => {
  // 全セクションを探索しフォームオプションの表示範囲を計算
  answerJson.forEach((s: SectionContainerType, sIndex: number) => {
    // 初期値null、stringはフォームコンテンツのid（uuid）
    let jumpId: string | BranchName.next | BranchName.last | null = null
    // 表示内容の計算結果初期化
    const resultFormOption: FormOption[] = []

    // フォームオプションの表示範囲を計算
    for (const fo of s.options) {
      // jumpIdがnullでない場合
      if (jumpId !== null) {
        // nextの場合は以降のフォームコンテンツを表示する
        if (jumpId === BranchName.next) {
          jumpId = null
        }
        // セクションの末尾まで表示しない
        if (jumpId === BranchName.last) {
          break
        }
        // 該当idのフォームコンテンツまで表示をスキップ
        if (jumpId === fo.id) {
          jumpId = null
        }
      }

      // jumpIdがnull以外の場合、選択肢以外なら上から順々に表示する
      if (jumpId === null) {
        resultFormOption.push(fo)
        // 選択肢の場合は以降の入力フォームを表示させるかどうか判断する
        if (fo.type === FormOptionType.Choice) {
          // 選択済みの場合は続きの表示位置をjumpIdに設定する
          if (fo.selected !== null) {
            const branch = fo.choices[fo.selected]
            // next | last の場合は定数をそのままjumpIdに設定
            if (branch.branch.type === BranchName.next || branch.branch.type === BranchName.last) {
              jumpId = branch.branch.type
            }
            // option の場合はフォームコンテンツのidを設定
            if (branch.branch.type === BranchName.option) {
              jumpId = branch.branch.id
            }
          }
          // 未選択の場合は以降の入力フォームを表示しないためループを終了させる
          if (fo.selected === null) {
            break
          }
        }
      }
    }
    // セクションごとに表示用JSONを設定
    visibleJson[sIndex].options.splice(0, visibleJson[sIndex].options.length, ...resultFormOption)
    initVisible.value = true
  })
}

// 選択肢変更時に画面更新
const onChangeChoice = () => {
  refreshVisibleJson()
}

/**
 * @description 表示用JSONに対応する回答提出用JSONのフォームコンテンツ取得
 *
 * @param id in visibleJson 表示用JSONのコンテンツid
 * @return formContent in answerJson 回答提出用JSONのフォームコンテンツ
 **/
const getAnswerContent = (contentId: string): FormOption => {
  let result: null | FormOption = null
  answerJson[props.currentSectionNumber].options.forEach((fo: FormOption) => {
    if (fo.id === contentId) {
      result = fo
      return
    }
  })
  if (!result) {
    throw new Error(`Missing FormContent. formContentId :${contentId}`)
  }
  return result
}

// 入力セット用のフォームコンテンツテンプレートの作成
const inputSetGroupTemplate = computed<TemplateList>(() => {
  const templateList: TemplateList = {}

  fetchedContentJson.forEach((sc: SectionContainerType) => {
    sc.options.forEach((fo: FormOption) => {
      // テンプレートにしたいフォームコンテンツIDの収集と同時に初期化も行う
      if (fo.type === FormOptionType.InputSet) {
        // id: InputSetのID
        // list: テンプレートにしたいID
        // template: テンプレート化したフォームコンテンツ
        templateList[fo.id] = {
          id: fo.id,
          list: [...fo.optionIds],
          template: []
        }
      }
    })

    // listのIDにマッチするフォームコンテンツを取得し、初期化してtemplateに挿入
    for (const key in templateList) {
      const pushItem: InputSetTemplate = {}
      sc.options.forEach((fo: FormOption) => {
        if (templateList[key].list.includes(fo.id)) {
          // 取得したフォームコンテンツの入力値だけを初期化
          const copied = JSON.parse(JSON.stringify(fo))
          const clean = setCleanValue(copied)
          // 入力セットのテンプレートの型がFormOption型と異なるため整形（idをそぎ落とす）
          type FormOptionForTrans = FormOptionForInputSet & { id?: string | undefined }
          const trans: FormOptionForTrans = { ...clean } as FormOptionForTrans
          delete trans.id
          // 入力セットの形に整形して保存
          pushItem[fo.id] = { ...trans }
        }
      })
      if (Object.keys(pushItem).length > 0) {
        templateList[key].template.push(pushItem)
      }
    }
  })

  return templateList
})

// 現在のセクションを取得
const currentSection = computed((): SectionContainerType => {
  return visibleJson[props.currentSectionNumber]
})

onMounted(async () => {
  // フォーム表示状態の初期化（選択肢の選択状態を反映）
  refreshVisibleJson()
})

// 変更をemit
watch([answerJson], () => {
  emit('onChange', answerJson)
})
</script>

<template>
  <div v-if="fetchedContentJson.length">
    <SectionContainer
      :id="currentSection.id"
      :section-index="currentSectionNumber"
      :title="currentSection.name"
    >
      <div v-if="initVisible">
        <div v-for="content in visibleJson[currentSectionNumber].options" :key="content.id">
          <TextForm
            v-if="FormOptionType.Text === content.type"
            :section-index="currentSectionNumber"
            :content="getAnswerContent(content.id) as TextOption"
          ></TextForm>
          <ChoicesForm
            v-if="FormOptionType.Choice === content.type"
            :section-index="currentSectionNumber"
            :content="getAnswerContent(content.id) as ChoiceOption"
            @change="onChangeChoice"
          ></ChoicesForm>
          <CheckBoxForm
            v-if="FormOptionType.CheckBox === content.type"
            :section-index="currentSectionNumber"
            :content="getAnswerContent(content.id) as CheckBoxOption"
          ></CheckBoxForm>
          <QuantitativeForm
            v-if="FormOptionType.Quantitative === content.type"
            :section-index="currentSectionNumber"
            :content="getAnswerContent(content.id) as QuantitativeOption"
          ></QuantitativeForm>
          <DateForm
            v-if="FormOptionType.Date === content.type"
            :section-index="currentSectionNumber"
            :content="getAnswerContent(content.id) as DateOption"
          ></DateForm>
          <InputSetForm
            v-if="FormOptionType.InputSet === content.type"
            :section-index="currentSectionNumber"
            :templateList="inputSetGroupTemplate"
            :content="getAnswerContent(content.id) as InputSetOption"
          >
          </InputSetForm>
        </div>
      </div>
    </SectionContainer>

    <div class="bottom-button-container d-flex justify-center">
      <slot name="next-button"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
// 次へボタンコンテナ
.bottom-button-container {
  margin: 32px 0;
  padding: 0 32px 16px 32px;
}
</style>
