<script setup lang="ts">
import { computed, onMounted, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { hospitalizationOrderApiClient } from '@/api/apiClient'
import BaseButton from '@/components/Base/Button/index.vue'
import FormContentSubmissionForm from '@/features/FormContentSubmission/CommonForm/formContentSubmissionForm.vue'
import { type SectionContainer as SectionContainerType } from '@/features/FormContentSubmission/CommonForm/type'
import { useFormContentNavigationStore } from '@/stores/formContentNavigation'
import { useHospitalizationOrderStore } from '@/stores/hospitalizationOrder'
import { useHospitalizationOrderFormContentStore } from '@/stores/hospitalizationOrderFormContent'

const router = useRouter()
const formContentNavigationStore = useFormContentNavigationStore()
const hospitalizationOrderFormContentStore = useHospitalizationOrderFormContentStore()
const hospitalizationOrderStore = useHospitalizationOrderStore()

const { t } = useI18n()

// propsを取得
type Props = {
  contentJson: string
  medicalInstitutionId: string
  hospitalizationOrderId: string
  hospitalizationOrderDocumentSetId: string
  hospitalizationOrderDocumentSetContentId: string
}
const props = defineProps<Props>()
const fetchedJson = reactive<SectionContainerType[]>(JSON.parse(props.contentJson))

// 最終セクション番号
const lastSectionNumber = computed(() => {
  const sc = JSON.parse(JSON.stringify(fetchedJson)) as SectionContainerType[]
  return sc.length - 1
})

// 次のセクションが存在するか
const hasNextSection = computed((): boolean => currentSectionNumber.value < lastSectionNumber.value)

// 前のセクションが存在するか
const hasPrevSection = computed((): boolean => currentSectionNumber.value > 0)

/**
 * フォームコンテンツの変更をセット
 *
 * @param sectionContainers 回答提出用JSON
 */
const onEditFormContent = (sectionContainers: SectionContainerType[]): void => {
  hospitalizationOrderFormContentStore.setEditedFormContent(JSON.stringify(sectionContainers))
}

// 前の質問へボタンハンドラー。ヘッダーコンポーネントに設定するコールバック関数を定義
const goBack = async (): Promise<void> => {
  await saveFormContentDraftProgress()

  if (hasPrevSection.value) {
    formContentNavigationStore.previousSection()
  } else {
    router.back()
  }
}

// 次の質問へ | 確認画面へ
const goNext = async () => {
  await saveFormContentDraftProgress()

  if (hasNextSection.value) {
    // 次のセクションが残っている場合は次のセクション画面を表示
    formContentNavigationStore.nextSection()
  } else {
    // 最終セクションの場合は回答フォーム確認画面へ
    router.replace({
      name: 'DGHP-006-02',
      params: {
        medicalInstitutionId: props.medicalInstitutionId,
        hospitalizationOrderDocumentSetId: props.hospitalizationOrderDocumentSetId,
        hospitalizationOrderDocumentSetContentId: props.hospitalizationOrderDocumentSetContentId
      }
    })
  }
}

// 回答を中断する
const interruptEdit = async () => {
  await saveFormContentDraftProgress()

  // 中断する場合、コンテンツ詳細画面に戻す
  router.back()
}

// 現在のセクション番号を取得
const currentSectionNumber = computed((): number => {
  return formContentNavigationStore.section
})

// 途中保存API呼び出し
const saveFormContentDraftProgress = async () => {
  await hospitalizationOrderApiClient().api.updateHospitalizationOrderSubmissionFormContentByPar(
    props.medicalInstitutionId,
    props.hospitalizationOrderId,
    props.hospitalizationOrderDocumentSetContentId,
    { userId: '', representativeId: hospitalizationOrderStore.getRepresentativeId() },
    {
      submitted: false,
      contentJson: hospitalizationOrderFormContentStore.editedFormContent
    }
  )
}

// コンポーネント初期化
onMounted(async () => {
  // 戻るボタンの初期化
  if (fetchedJson.length > 0) {
    formContentNavigationStore.clearFormContentNavigation()

    formContentNavigationStore.setBackButtonAction(goBack)
    formContentNavigationStore.setInterruptButtonAction(interruptEdit)
  } else {
    throw new Error('データが空か不正です')
  }
  hospitalizationOrderFormContentStore.setEditedFormContent(props.contentJson)
})
</script>

<template>
  <div v-if="fetchedJson.length">
    <div>
      <FormContentSubmissionForm
        :fetched-content-json="fetchedJson"
        :current-section-number="currentSectionNumber"
        @on-change="onEditFormContent"
      >
        <template v-if="hasNextSection" #next-button>
          <!-- 次へボタン -->
          <BaseButton
            class="transition-next-button"
            color="primary"
            :content="t('features.hospitalizationDocument.FormContentSubmission.goToNext')"
            type="button"
            variant="flat"
            @click="goNext"
          ></BaseButton>
        </template>
        <!-- 確認ボタン -->
        <template v-else #next-button>
          <BaseButton
            class="transition-next-button"
            color="primary"
            block
            large
            :content="t('features.hospitalizationDocument.FormContentSubmission.goToConfirm')"
            type="button"
            variant="flat"
            @click="goNext"
          ></BaseButton>
        </template>
      </FormContentSubmissionForm>
    </div>
  </div>
</template>

<style scoped lang="scss">
// 次へボタン
.transition-next-button {
  background-color: #0017c1 !important;
  min-width: 92px;
  font-size: 1.125rem;
  height: 52px;
  font-weight: 600;
  border-radius: 24px;
  padding: 14px 87px;
}
</style>
