import axios, { AxiosError } from 'axios'
import { defineStore } from 'pinia'
import { ref } from 'vue'

import { type FetchApiResponse } from './types'

import { hospitalizationOrderApiClient, type ErrorOption } from '@/api/apiClient'
import { type ParHospitalizationOrderContentItem } from '@/api/generate/apiSchema'
import { useConstants } from '@/composables/useConstants'

export const useHospitalizationOrderFormContentStore = defineStore(
  'hospitalizationOrderFormContent',
  () => {
    // init
    const initHospitalizationOrderContentItem = (): ParHospitalizationOrderContentItem => {
      return {
        id: '',
        contentId: '',
        title: '',
        description: '',
        contentType: useConstants().CONSTANTS.CONTENT_TYPE.FORM,
        contentRevisionId: '',
        contentStatus: ''
      }
    }

    const hospitalizationOrderContentItem = ref<ParHospitalizationOrderContentItem>(
      initHospitalizationOrderContentItem()
    )
    const emptyFormContent = ref<string>('')
    const submittedFormContent = ref<string>('')
    const editedFormContent = ref<string>('')
    const submitted = ref<boolean>()

    const getInitialFormContent = () =>
      submittedFormContent.value != '' ? submittedFormContent.value : emptyFormContent.value

    // setter
    const setHospitalizationOrderContentItem = (item: ParHospitalizationOrderContentItem) => {
      hospitalizationOrderContentItem.value = item
    }

    const setEditedFormContent = (json: string) => {
      editedFormContent.value = json
    }

    // fetch
    const fetchContentJson = async (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      hospitalizationOrderDocumentSetId: string,
      hospitalizationOrderDocumentSetContentId: string,
      representativeId?: string
    ): Promise<FetchApiResponse<string>> => {
      try {
        const contentRevisionId = (
          await hospitalizationOrderApiClient().api.hospitalizationOrderDocumentSetContentByPar(
            medicalInstitutionId,
            hospitalizationOrderId,
            hospitalizationOrderDocumentSetId,
            hospitalizationOrderDocumentSetContentId,
            {
              userId: '',
              representativeId
            }
          )
        ).data.contentRevisionId
        const response = await fetchFormContentRevision(
          medicalInstitutionId,
          hospitalizationOrderId,
          contentRevisionId,
          representativeId
        )

        if (!response.success) {
          return {
            success: false,
            status: response.status,
            cancel: true
          }
        }

        await fetchSubmissionFormContent(
          medicalInstitutionId,
          hospitalizationOrderId,
          hospitalizationOrderDocumentSetContentId,
          representativeId,
          { allowNotFound: true }
        )

        return {
          success: true,
          content: getInitialFormContent()
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError
          return {
            success: false,
            status: axiosError.response?.status
          }
        }

        throw error
      }
    }

    const fetchFormContentRevision = async (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      contentRevisionId: string,
      representativeId?: string
    ): Promise<FetchApiResponse<string>> => {
      try {
        const response =
          await hospitalizationOrderApiClient().api.hospitalizationOrderDocumentSetFormContentRevisionByPar(
            medicalInstitutionId,
            hospitalizationOrderId,
            contentRevisionId,
            { userId: '', representativeId }
          )
        emptyFormContent.value = response.data.contentJson
        return {
          success: true,
          content: emptyFormContent.value
        }
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError
          return {
            success: false,
            status: axiosError.response?.status
          }
        }

        throw error
      }
    }

    const fetchSubmissionFormContent = async (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      hospitalizationOrderDocumentSetContentId: string,
      representativeId?: string,
      errorOption: ErrorOption | undefined = undefined
    ): Promise<FetchApiResponse<string>> => {
      try {
        const response = await hospitalizationOrderApiClient(
          errorOption
        ).api.hospitalizationOrderSubmissionFormContentByPar(
          medicalInstitutionId,
          hospitalizationOrderId,
          hospitalizationOrderDocumentSetContentId,
          { userId: '', representativeId }
        )
        submittedFormContent.value = response.data.contentJson
        submitted.value = response.data.submitted
        return {
          success: true,
          content: submittedFormContent.value
        }
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError
          return {
            success: false,
            status: axiosError.response?.status
          }
        }

        throw error
      }
    }

    // clear
    const clearHospitalizationOrderContent = () => {
      hospitalizationOrderContentItem.value = initHospitalizationOrderContentItem()
      emptyFormContent.value = ''
      submittedFormContent.value = ''
      editedFormContent.value = ''
    }

    const isSubmitted = (): boolean | undefined => {
      return submitted.value
    }

    return {
      getInitialFormContent,
      hospitalizationOrderContentItem,
      editedFormContent,
      setHospitalizationOrderContentItem,
      setEditedFormContent,
      fetchContentJson,
      clearHospitalizationOrderContent,
      isSubmitted
    }
  }
)
