<script setup lang="ts">
import { HttpStatusCode } from 'axios'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { TermsResponse } from '@/api/generate/apiSchemaGateway'

import { usersApiClient } from '@/api/apiClient'
import { usersApiClient as usersGatewayApiClient } from '@/api/apiGatewayClient'
import { authenticateUser } from '@/api/sendUserEvent'
import { getUserId, setEncodedToken } from '@/api/userDataCookie'
import { OnlyTextContentDialog } from '@/components/Dialog'
import DigihosAgreement from '@/features/DigihosAgreement/index.vue'
import router from '@/router'
import { useDigihosTermsStore } from '@/stores/digihosTermsStore'
import { useDisplayNameStore } from '@/stores/displayName'

const { t } = useI18n()

// 同意書類
const terms = ref<TermsResponse>()

// 利用規約の取得
const fetchTerms = async () => {
  try {
    const response = await usersGatewayApiClient().gateway.getTerms()
    terms.value = response.data
  } catch (e) {
    router.push('/server_error')
  }
}

// モーダル開閉管理
const isOpenErrorDialog = ref<boolean>(false)

// サーバーエラーダイアログを開く
const openDialog = () => {
  isOpenErrorDialog.value = true
}

const closeDialog = async () => {
  // 利用規約IDが異なる場合にエラーとなるため、更新をかける
  await fetchTerms()
  isOpenErrorDialog.value = false
  isAlreadySubmit.value = false
}

// 二重送信フラグ
const isAlreadySubmit = ref<boolean>(false)

// 同意送信
const onSubmit = async (): Promise<void> => {
  if (isAlreadySubmit.value) {
    return
  }

  // ユーザーIDを取得)
  const userId = getUserId()
  // 同意書類ID
  const termsId = terms.value?.termsId
  if (termsId == undefined) {
    return
  }

  try {
    if (!userId) {
      // 新規登録
      const response = await usersGatewayApiClient().gateway.createUser({ termsId: termsId })
      if (response.status !== HttpStatusCode.Created) {
        throw Error('failed submit terms.')
      }
      authenticateUser(response.data.id, response.data.appToken)
      // デバッグ用 開発環境では、ブラウザでも動作確認可能になるように処理する
      if (import.meta.env.DEV && import.meta.env.VITE_PREVIEW_BROWSER) {
        setEncodedToken(response.data.id, response.data.appToken)
      }
      window.onCookiesSet = () => {
        router.replace('/')
        isAlreadySubmit.value = false
      }
    } else {
      // ユーザーIDはあるが未同意の場合（利用規約の更新などが主例）
      await usersApiClient().api.agreeTermsByUser(
        {
          userId: ''
        },
        {
          termsId: termsId
        }
      )
      // 利用規約の同意状態を際取得する
      await useDigihosTermsStore().fetchDigihosTerms()
      // 遷移と初期化処理
      router.replace('/')
      isAlreadySubmit.value = false
    }
  } catch (error) {
    openDialog()
    throw error
  }
}

onMounted(async () => {
  const displayNameStore = useDisplayNameStore()
  displayNameStore.updateDisplayName(undefined)

  await fetchTerms()
})
</script>

<template>
  <div class="main-container">
    <div v-if="terms">
      <DigihosAgreement @submit="onSubmit"></DigihosAgreement>
    </div>

    <OnlyTextContentDialog
      :value="isOpenErrorDialog"
      :title="t('pages.serverError.title')"
      :content="
        t('errors.FailedRegistration', { resource: t('features.terms.agreementToTermsOfUse') })
      "
      cancelBtnDefault="center"
      :cancelBtnText="t('attributes.close')"
      :size="'medium'"
      :confirmBtnVisible="false"
      @input="closeDialog"
    />
  </div>
</template>

<style scoped lang="scss">
.main-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  --v-layout: initial;
  overflow: hidden;
}
</style>
