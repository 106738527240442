import { default as colors } from '../../tokens/colors.json';

import type { NucleusColorTokenType } from './color-types';
import { flattenAndExcludeValue } from './flattenAndExcludeValue';

/**
 * MUIのテーマカラーに関するトークンを取得する関数
 *
 * @param {object} colors - カラーのトークンが格納されたJSONファイル
 *
 * @returns {Record<NucleusColorTokenType, string>} フラット化されたオブジェクト
 */
const muiThemeColors: Record<NucleusColorTokenType, string> = flattenAndExcludeValue(colors);

export { muiThemeColors };
