<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { MedicalInstitutionCardType } from './types'

import Label from '@/components/Base/Label/index.vue'
import useBirthDate from '@/composables/useBirthDate'
import useDateFormatter from '@/composables/useDateFormatter'

const { t } = useI18n()

const { getFullAge } = useBirthDate()
const { getFormattedDate } = useDateFormatter()

type Props = {
  hospitalizationOrder: MedicalInstitutionCardType
  medicalInstitutionName: string
}
const props = defineProps<Props>()
const hospitalizationOrder = reactive({ ...props.hospitalizationOrder })

const patientName = ref<string>()
const hospitalizationDate = ref<string>()
const dischargeDate = ref<string>()

onMounted(async () => {
  patientName.value = `${hospitalizationOrder.patient.familyName} ${hospitalizationOrder.patient.givenName} ${getFullAge(hospitalizationOrder.patient.birthDate)}`
  if (hospitalizationOrder.hospitalizationDate != undefined) {
    hospitalizationDate.value = getFormattedDate(new Date(hospitalizationOrder.hospitalizationDate))
  } else {
    hospitalizationDate.value = '-'
  }
  if (hospitalizationOrder.dischargeDate != undefined) {
    dischargeDate.value = getFormattedDate(new Date(hospitalizationOrder.dischargeDate))
  } else {
    dischargeDate.value = '-'
  }
})
</script>
<template>
  <div fill-height>
    <div class="medicalInstitution-label-title-layout">
      <Label
        forHtml="content_title"
        :labelText="props.medicalInstitutionName"
        :isRequired="false"
        :token-type="'HEADLINE_S'"
      ></Label>
    </div>
    <div class="medicalInstitution-label-title-layout-padding">
      <Label
        class="hospitalization-name-label-padding"
        forHtml="content_title"
        :labelText="patientName"
        :isRequired="false"
        :token-type="'TITLE_L'"
      ></Label>
    </div>
    <div class="hospitalization-detail-padding">
      <div class="hospitalization-detail-content-layout">
        <v-sheet class="hospitalization-schedule-layout">
          <div class="hospitalization-schedule-item">
            <Label
              forHtml="content_title"
              :labelText="t('features.medicalInstitutionHome.hospitalizationOrder')"
              :isRequired="false"
              :token-type="'LABEL_M'"
            ></Label>
            <label class="hospitalization-schedule-days">{{ hospitalizationDate }}</label>
          </div>
          <div class="hospitalization-schedule-item">
            <Label
              forHtml="content_title"
              :labelText="t('features.medicalInstitutionHome.dischargeDate')"
              :isRequired="false"
              :token-type="'LABEL_M'"
            ></Label>
            <label class="hospitalization-schedule-days">{{ dischargeDate }}</label>
          </div>
        </v-sheet>
      </div>
    </div>
  </div>
</template>

<style>
.medicalInstitution-label-title-layout {
  text-align: center;
  padding: 16px;
  border-radius: 30px 30px 0px 0px;
  background-color: #0017c1;
  color: white;
}

.medicalInstitution-label-title-layout-padding {
  padding: 8px 12px 8px 12px;
}

.hospitalization-detail-padding {
  justify-content: center;
}

.hospitalization-detail-content-layout {
  padding: 0px 12px 8px 12px;
  justify-content: center;
  display: flex;
  flex-flow: column;
}

.hospitalization-schedule-item {
  display: flex;
  flex-direction: column;
}

/* 入院予定日項目のレイアウト */
.hospitalization-schedule-layout {
  padding: 16px;
  border-radius: 8px;
  background-color: #f0f1fb;
  display: flex;
  flex-direction: column;
}

.hospitalization-schedule-days {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}
</style>
