<script setup lang="ts">
import {
  getTypography,
  type NucleusTypographyTokenType,
  type NucleusColorTokenType,
  muiThemeColors
} from '@optim-design-system/src'
import { computed } from 'vue'

type Props = {
  /**
   * ラベル
   */
  label?: string
  /**
   * バッジの文字色
   */
  color?: NucleusColorTokenType
  /**
   * バッジの背景色
   */
  backgroundColor?: NucleusColorTokenType
  /**
   * ラベル書式
   */
  bodyStyle?: NucleusTypographyTokenType
  /**
   * マージンCSS デフォルト '0px 4px'
   */
  margin?: string
}

// デフォルト値
const defaultMargin = '0px 4px'
const props = withDefaults(defineProps<Props>(), {
  label: '',
  color: 'primitive-white-DEFAULT-value',
  backgroundColor: 'key-red-primary-value',
  bodyStyle: 'LABEL_S',
  margin: defaultMargin
})

// 書式
const bodyStyle = computed(() => {
  return getTypography(props.bodyStyle)
})

// バッジの配色
const badgeColorStyle = computed(() => {
  const result: { [key: string]: string } = {}

  if (props.color.trim() !== '') {
    result.color = muiThemeColors[props.color]
  }
  if (props.backgroundColor.trim() !== '') {
    result.backgroundColor = muiThemeColors[props.backgroundColor]
  }
  return result
})

// マージンCSS デフォルト '0px 4px'
const marginStyle = computed(() => {
  if (props.margin.trim() !== defaultMargin) {
    return { margin: props.margin }
  }
  return { margin: defaultMargin }
})
</script>

<template>
  <span :style="[badgeColorStyle, bodyStyle, marginStyle]" class="badge">
    {{ label }}
  </span>
</template>

<style scoped lang="scss">
// バッジ丸型加工
.badge {
  padding: 2px 6px;
  border-radius: 16px;
  width: fit-content;
  word-break: keep-all;
}
</style>
