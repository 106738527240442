/**
 * ユーザーの認証情報をAndroid, iOSアプリに送信する
 * @param userId ユーザーId
 * @param appToken 認証用トークン
 */
export const authenticateUser = (userId: string, appToken: string): void => {
  const userData = {
    userId: userId,
    appToken: appToken
  }

  // Androidへイベントを渡す
  if (window?.android?.authenticateUser) {
    window.android.authenticateUser(JSON.stringify(userData))
  }
  // iOS側へイベントを渡す
  else if (window?.webkit?.messageHandlers?.authenticateUser.postMessage) {
    window.webkit.messageHandlers.authenticateUser.postMessage(JSON.stringify(userData))
  }
}

/**
 * 紐付け用トークンをAndroid, iOSアプリに送信する
 * @param bindingTokenId 紐付け用トークンId
 * @param bindingToken 紐付け用トークン
 */
export const sendBindingToken = (bindingTokenId: string, bindingToken: string): void => {
  const token = {
    bindingTokenId: bindingTokenId,
    bindingToken: bindingToken
  }

  // Androidへイベントを渡す
  if (window?.android?.sendBindingToken) {
    window.android.sendBindingToken(JSON.stringify(token))
  }
  // iOS側へイベントを渡す
  else if (window?.webkit?.messageHandlers?.sendBindingToken.postMessage) {
    window.webkit.messageHandlers.sendBindingToken.postMessage(JSON.stringify(token))
  }
}

export default {
  authenticateUser,
  sendBindingToken
}
