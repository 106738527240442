<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import BaseButton from '@/components/Base/Button/index.vue'
import router from '@/router'

const { t } = useI18n()

type Props = {
  medicalInstitutionId: string
  hospitalizationOrderDocumentSetId: string
  hospitalizationOrderDocumentSetContentId: string
  description: string
  submitted: boolean
}
const {
  medicalInstitutionId,
  hospitalizationOrderDocumentSetId,
  hospitalizationOrderDocumentSetContentId
} = defineProps<Props>()

const isDisabled = ref<boolean>(false)

const startAnswer = () => {
  isDisabled.value = true
  router.push({
    name: 'DGHP-006-01',
    params: {
      medicalInstitutionId,
      hospitalizationOrderDocumentSetId,
      hospitalizationOrderDocumentSetContentId
    }
  })
}
</script>
<template>
  <div class="form-content-detail">
    <div class="description">
      {{ description }}
    </div>
    <div>
      <BaseButton
        v-if="!submitted"
        color="primary"
        block
        large
        :content="t('features.formContentDetail.startAnswer')"
        :loading="isDisabled"
        :disabled="isDisabled"
        @click="startAnswer"
      >
      </BaseButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-content-detail {
  overflow-wrap: break-word;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.description {
  white-space: pre-wrap;
}
</style>
