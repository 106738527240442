<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useTheme } from 'vuetify'

import type { NavMenu } from '@/components/Layout/Navigation/types'

import { AppBar, HomeBar, Navigation } from '@/components/Layout'
import { useDisplayNameStore } from '@/stores/displayName'

const { t } = useI18n()
const route = useRoute()

// カスタムテーマカラーを動的に設定する。なければデフォルトテーマが適用される
const theme = useTheme()
watch(
  () => route.meta.customThemeName,
  (value) => {
    if (typeof value === 'string') {
      theme.global.name.value = value
    } else {
      theme.global.name.value = 'digihosTheme'
    }
  }
)

const navMenus: NavMenu[] = [
  {
    name: t('pages.bottomMenuHome.list'),
    icon: 'home',
    to: '/hospitalization-orders'
  },
  {
    name: t('pages.bottomMenuMiniApp.list'),
    icon: 'apps',
    to: '/patients'
  },
  {
    name: t('pages.bottomMenuCitizenCard.list'),
    icon: 'credit_card',
    to: '/document-sets'
  },
  {
    name: t('pages.bottomMenuHospital.list'),
    icon: 'local_hospital',
    to: '/medical-workers'
  }
]

const drawer = ref<boolean>(false)

const toggleNavigation = () => {
  drawer.value = !drawer.value
}

const displayNameStore = useDisplayNameStore()

// ヘッダー自体が必要か
const nonHeader = computed((): boolean => {
  if (route.meta.nonHeader) return true
  return false
})

// 戻るボタン（ルーターで設定）
const isShowBackIcon = computed((): boolean => {
  // 戻るボタンと競合する場合はカスタムコンポーネントが優先される
  if (typeof route.meta.leftHeaderComponent !== 'undefined') {
    return false
  }
  if (typeof route.meta.showBackIcon === 'boolean') {
    return route.meta.showBackIcon
  }
  return false
})

// 左上ボタン（カスタムコンポーネント）（ルーターで設定）
const leftHeaderComponent = computed(() => {
  // 戻るボタンと競合する場合はカスタムコンポーネントが優先される
  return route.meta.leftHeaderComponent || undefined
})

const useHomeBar = computed(() => {
  if (typeof route.meta.useHomeBar === 'boolean') {
    return route.meta.useHomeBar
  }
  return false
})

// リロードボタン（ルーターで設定）
const isShowReloadPageIcon = computed((): boolean => {
  // リロードボタンと競合する場合はカスタムコンポーネントが優先される
  if (typeof route.meta.rightHeaderComponent !== 'undefined') {
    return false
  }
  if (typeof route.meta.showReloadPageIcon === 'boolean') {
    return route.meta.showReloadPageIcon
  }
  return false
})

// 右上ボタン（カスタムコンポーネント）（ルーターで設定）
const rightHeaderComponent = computed(() => {
  // リロードボタンと競合する場合はカスタムコンポーネントが優先される
  return route.meta.rightHeaderComponent || undefined
})

// ボトムメニュー（ルーターで設定）
const isShowBottomMenu = computed((): boolean => {
  if (typeof route.meta.showBottomMenu === 'boolean') {
    return route.meta.showBottomMenu
  }
  return false
})
</script>

<template>
  <v-app class="base-layout">
    <!-- ヘッダー -->
    <HomeBar
      v-if="useHomeBar"
      :navTitle="
        displayNameStore.displayName != undefined ? t(displayNameStore.displayName) : undefined
      "
      :showBackIcon="isShowBackIcon"
      :showReloadPageIcon="isShowReloadPageIcon"
      :leftHeaderComponent="leftHeaderComponent"
      :rightHeaderComponent="rightHeaderComponent"
    />
    <AppBar
      v-else
      :navTitle="
        displayNameStore.displayName != undefined ? t(displayNameStore.displayName) : undefined
      "
      :showBackIcon="isShowBackIcon"
      :showReloadPageIcon="isShowReloadPageIcon"
      :leftHeaderComponent="leftHeaderComponent"
      :rightHeaderComponent="rightHeaderComponent"
      :nonHeader="nonHeader"
    />
    <v-main>
      <slot />
    </v-main>
    <!-- ボトムメニュー -->
    <div>
      <navigation
        :isShowMenu="isShowBottomMenu"
        :nav-menus="navMenus"
        :drawer="drawer"
        @click="toggleNavigation"
      />
    </div>
  </v-app>
</template>

<style lang="scss" scoped>
.base-layout {
  &__app-bar {
    border-bottom: 1px solid #d8d8d8 !important;
  }
}

// vuetifyの影枠を消す
.no-underline {
  box-shadow: none !important;
}
</style>
