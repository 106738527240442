import { http, HttpResponse } from 'msw'

import { contents, formContentRevisionResponse, submissionFormContentResponse } from './baseData'

const baseUrl = import.meta.env.VITE_API_HOSPITALIZATION_API_BASE_URL
const userV1Url = `${baseUrl}/api/user/v1`

export const hospitalizationApi = [
  // hospitalizationOrderDocumentSetContentsByPar
  http.get(
    `${userV1Url}/medical-institutions/:medicalInstitutionId/hospitalization-orders/:hospitalizationOrderId/document-sets/:hospitalizationOrderDocumentSetId/contents`,
    () => {
      return HttpResponse.json(contents)
    }
  ),
  // hospitalizationOrderDocumentSetFormContentRevisionByPar
  http.get(
    `${userV1Url}/medical-institutions/:medicalInstitutionId/hospitalization-orders/:hospitalizationOrderId/form-content-revisions/:formContentRevisionId`,
    () => {
      return HttpResponse.json(formContentRevisionResponse)
    }
  ),
  // hospitalizationOrderSubmissionFormContentByPar
  http.get(
    `${userV1Url}/medical-institutions/:medicalInstitutionId/hospitalization-orders/:hospitalizationOrderId/submission-form-content/:hospitalizationOrderDocumentSetContentId`,
    ({ params }) => {
      const hospitalizationOrderDocumentSetContentId =
        params['hospitalizationOrderDocumentSetContentId']
      console.error(hospitalizationOrderDocumentSetContentId)
      if (
        hospitalizationOrderDocumentSetContentId ===
        submissionFormContentResponse.hospitalizationOrderDocumentSetContentId
      ) {
        return HttpResponse.json(submissionFormContentResponse)
      } else {
        return new HttpResponse(null, {
          status: 404
        })
      }
    }
  ),
  // updateHospitalizationOrderSubmissionFormContentByPar
  http.put(
    `${userV1Url}/medical-institutions/:medicalInstitutionId/hospitalization-orders/:hospitalizationOrderId/submission-form-content/:hospitalizationOrderDocumentSetContentId`,
    () => {
      return HttpResponse.json(submissionFormContentResponse)
    }
  )
]
