<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import type { DocumentSetContent } from '@/features/DocumentSetContentList/type'

import { hospitalizationOrderApiClient } from '@/api/apiClient'
import DocumentSetContentList from '@/features/DocumentSetContentList/index.vue'
import { useDisplayNameStore } from '@/stores/displayName'
import { useHospitalizationOrderStore } from '@/stores/hospitalizationOrder'

const route = useRoute()
const medicalInstitutionId =
  typeof route.params.medicalInstitutionId == 'string' ? route.params.medicalInstitutionId : ''
const hospitalizationOrderDocumentSetId =
  typeof route.params.hospitalizationOrderDocumentSetId == 'string'
    ? route.params.hospitalizationOrderDocumentSetId
    : ''

const hospitalizationOrderStore = useHospitalizationOrderStore()

const documentSetContentList = ref<DocumentSetContent[]>([])
const documentSetDescription = ref<string | undefined>()
const hospitalizationDate = ref<string | undefined>()
const isLoading = ref<boolean>(true)

const fetchHospitalizationOrderDocument = async (
  hospitalizationOrderId: string,
  representativeId?: string
) => {
  const response = await hospitalizationOrderApiClient().api.hospitalizationOrderDocumentSetsByPar(
    medicalInstitutionId,
    hospitalizationOrderId,
    { userId: '', representativeId }
  )
  response.data.list.forEach((documentSet) => {
    if (documentSet.id == hospitalizationOrderDocumentSetId) {
      useDisplayNameStore().updateDisplayName(documentSet.title)
      documentSetDescription.value = documentSet.description
      return
    }
  })
}

const fetchHospitalizationOrderDocumentSetContents = async (
  hospitalizationOrderId: string,
  representativeId?: string
) => {
  const response =
    await hospitalizationOrderApiClient().api.hospitalizationOrderDocumentSetContentsByPar(
      medicalInstitutionId,
      hospitalizationOrderId,
      hospitalizationOrderDocumentSetId,
      { userId: '', representativeId }
    )

  documentSetContentList.value = response.data.list.map((content) => {
    return {
      id: content.id,
      title: content.title,
      contentStatus: content.contentStatus
    }
  })
}

onMounted(async () => {
  isLoading.value = true
  useDisplayNameStore().updateDisplayName(undefined)
  const hospitalizationOrder =
    await hospitalizationOrderStore.fetchHospitalizationOrderOrThrow(medicalInstitutionId)
  hospitalizationDate.value = hospitalizationOrder.hospitalizationDate

  await Promise.all([
    fetchHospitalizationOrderDocument(
      hospitalizationOrder.id,
      hospitalizationOrderStore.getRepresentativeId()
    ),
    fetchHospitalizationOrderDocumentSetContents(
      hospitalizationOrder.id,
      hospitalizationOrderStore.getRepresentativeId()
    )
  ])

  isLoading.value = false
})
</script>

<template>
  <v-container class="container">
    <DocumentSetContentList
      v-if="!isLoading && documentSetContentList != undefined"
      :medicalInstitutionId="medicalInstitutionId"
      :hospitalizationOrderDocumentSetId="hospitalizationOrderDocumentSetId"
      :hospitalizationDate="hospitalizationDate"
      :documentSetContentList="documentSetContentList"
      :documentSetDescription="documentSetDescription"
    />
  </v-container>
</template>

<style scoped lang="scss">
.container {
  height: 100%;
  padding: 16px;
}
</style>
