<script setup lang="ts">
import { differenceInCalendarDays } from 'date-fns'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import type { DocumentSetItem } from './type'

import { isDocumentSetStatusCode } from '@/api/types'
import { ChipCard } from '@/components/Card'
import useChip from '@/composables/useChip'
import { useConstants } from '@/composables/useConstants'
import router from '@/router'

const { t } = useI18n()
const { getDocumentSetStatus } = useChip()

const props = defineProps<{
  medicalInstitutionId: string
  hospitalizationDate?: string
  documentSetList: DocumentSetItem[]
}>()

const transitionContentsList = (id: string) => {
  router.push(
    `/medical-institutions/${props.medicalInstitutionId}/hospitalization-order-document-sets/${id}/hospitalization-order-document-set-contents`
  )
}

const getChipValue = computed(() => (documentSetStatus: string) => {
  if (isDocumentSetStatusCode(documentSetStatus)) {
    const chipValue = getDocumentSetStatus(documentSetStatus)
    return {
      ...chipValue,
      label: t(chipValue.label)
    }
  } else {
    throw new Error('unexpected documentSetList')
  }
})

const getWarning = computed(
  () => (documentSetStatus: string, hospitalizationDate: string | undefined) => {
    if (typeof hospitalizationDate !== 'string') return
    if (
      documentSetStatus != useConstants().CONSTANTS.DOCUMENT_SET_STATUS.PARTIAL_SUBMITTED &&
      documentSetStatus != useConstants().CONSTANTS.DOCUMENT_SET_STATUS.NOT_SUBMITTED
    ) {
      return
    }

    const result = differenceInCalendarDays(new Date(hospitalizationDate), new Date())
    if (result < 0) {
      return t('features.documentSet.documentSetList.daysOver', { day: Math.abs(result) })
    } else {
      return t('features.documentSet.documentSetList.daysLeft', { day: result })
    }
  }
)
</script>
<template>
  <div class="document-set-list">
    <ChipCard
      v-for="item in props.documentSetList"
      :key="item.id"
      :title="item.title"
      :chipValue="getChipValue(item.documentSetStatus)"
      :warning="getWarning(item.documentSetStatus, hospitalizationDate)"
      @click="transitionContentsList(item.id)"
    />
  </div>
</template>

<style lang="scss" scoped>
.document-set-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: scroll;
}
</style>
