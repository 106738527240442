<script setup lang="ts">
import { computed, reactive } from 'vue'
import { useI18n } from 'vue-i18n'

import { FormOptionType, type FormOption, type FormOptionForInputSet } from '../CommonForm/type'

import BaseBadge from '@/components/Base/Badge/index.vue'
import BaseLabel from '@/components/Base/Label/index.vue'
import Typography from '@/components/Typography/Typography.vue'
import useDateFormatter from '@/composables/useDateFormatter'

const { t } = useI18n()
const { getFormattedDate } = useDateFormatter()

// props初期化
const props = withDefaults(
  defineProps<{
    content: FormOption
  }>(),
  {}
)
const content = reactive<FormOption>({ ...props.content })

const isRequired = computed<boolean>(() => {
  if ('isRequired' in content) {
    return content.isRequired
  }
  return false
})

const reshape = (content: FormOptionForInputSet): FormOption => {
  return { id: '', ...content } as FormOption
}

// 入力セットグループタイトル
const withGroupNumber = (title: string, index: number) => {
  return `${title} ${index + 1}`
}

// [回答なし]
const noAnswerMessage = computed(() => {
  return t('features.FormContents.result.noAnswerMessage')
})

// 複数回答を一行に連結
const concat = (fo: FormOption) => {
  if (FormOptionType.CheckBox === fo.type) {
    if (fo.selected.length <= 0) {
      // ガード
      return noAnswerMessage.value
    }
    const comma = t('attributes.comma')
    let result = ''
    fo.selected.forEach((i: number) => {
      result += comma + fo.selects[i]
    })
    return result.replace(/^、/, '')
  }
  return ''
}

const dateFormat = (value: string) => {
  return getFormattedDate(new Date(value))
}
</script>

<template>
  <div>
    <!-- 項目名 -->
    <!-- 確認画面で入力セットの項目タイトルは表示しない -->
    <div class="content-inline content-title">
      <Typography tag="h4" token-type="TITLE_M">{{ content.name }}</Typography>
    </div>
    <div v-if="content.type !== FormOptionType.InputSet" v-show="isRequired" class="badge-wrapper">
      <BaseBadge
        :label="t('attributes.required')"
        background-color="primitive-red-accent-40-value"
      ></BaseBadge>
    </div>

    <!-- テキスト -->
    <div v-if="content.type === FormOptionType.Text">
      <p class="content-description">{{ content.description }}</p>
      <div class="content-text-value">
        <div v-if="content.value === null || !content.value.length">
          <Typography tag="p" token-type="BODY_M" class="no-answer-message">{{
            noAnswerMessage
          }}</Typography>
        </div>
        <div v-else>
          <Typography tag="p" token-type="BODY_M">{{ content.value }}</Typography>
        </div>
      </div>
    </div>

    <!--選択肢-->
    <div v-if="content.type === FormOptionType.Choice">
      <p class="content-description">{{ content.description }}</p>
      <div class="content-text-value">
        <div v-if="content.selected === null">
          <Typography tag="p" token-type="BODY_M" class="no-answer-message">{{
            noAnswerMessage
          }}</Typography>
        </div>
        <div v-else>
          <Typography tag="p" token-type="BODY_M">{{
            content.choices[content.selected].name
          }}</Typography>
        </div>
      </div>
    </div>

    <!--チェックボックス-->
    <div v-if="content.type === FormOptionType.CheckBox">
      <p class="content-description">{{ content.description }}</p>
      <div class="content-text-value">
        <div v-if="!content.selected.length">
          <Typography tag="p" token-type="BODY_M" class="no-answer-message">{{
            noAnswerMessage
          }}</Typography>
        </div>
        <div v-else>
          <Typography tag="p" token-type="BODY_M">{{ concat(content) }}</Typography>
        </div>
      </div>
    </div>

    <!--数値-->
    <div v-if="content.type === FormOptionType.Quantitative">
      <p class="content-description">{{ content.description }}</p>
      <div class="content-text-value">
        <div v-if="content.value === null">
          <Typography tag="p" token-type="BODY_M" class="no-answer-message">{{
            noAnswerMessage
          }}</Typography>
        </div>
        <div v-else>
          <!-- 値 -->
          <Typography tag="p" token-type="BODY_M"
            >{{ content.value }}
            <!-- 単位 -->
            <Typography tag="span" token-type="BODY_L">{{ content.unit }}</Typography>
          </Typography>
        </div>
      </div>
    </div>

    <!-- 日付-->
    <div v-if="content.type === FormOptionType.Date">
      <p class="content-description">{{ content.description }}</p>
      <div class="content-text-value">
        <div v-if="content.value === null || !content.value.length">
          <Typography tag="p" token-type="BODY_M" class="no-answer-message">{{
            noAnswerMessage
          }}</Typography>
        </div>
        <div v-else>
          <Typography tag="p" token-type="BODY_M">{{ dateFormat(content.value) }}</Typography>
        </div>
      </div>
    </div>

    <!--入力セット-->
    <div v-if="content.type === FormOptionType.InputSet" class="content-input-set-group">
      <!-- 入力セット.valuesが空の場合  -->
      <div v-if="!content.values.length">
        <div class="content-text-value">
          <Typography tag="p" token-type="BODY_M" class="no-answer-message">{{
            noAnswerMessage
          }}</Typography>
        </div>
      </div>
      <!-- 入力セット.valuesがある場合 -->
      <div
        v-else
        v-for="(inputSetValues, inputSetContentIndex) in content.values"
        :key="inputSetContentIndex"
      >
        <BaseLabel
          :label-text="withGroupNumber(content.name, inputSetContentIndex)"
          class="content-inline"
          token-type="LABEL_L"
        ></BaseLabel>
        <div v-for="(inputSetForm, innerIndex) in inputSetValues" :key="innerIndex">
          <div
            v-for="(content, contentIndex) in inputSetForm"
            :key="contentIndex"
            class="input-set-gap"
          >
            <ContentResult :content="reshape(content)"></ContentResult>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content-title {
  margin-bottom: 4px 0;
}
.content-inline {
  display: inline-block;
}
.content-description {
  white-space: pre-wrap;
  margin-bottom: 4px 0;
}
.content-text-value {
  margin: 7px 0 12px 0;
  word-break: break-all;
  white-space: break-spaces;
}
.no-answer-message {
  color: var(--on-surface-primary-disabled, #8b8c8e);
}
// バッジラッパー要素
.badge-wrapper {
  display: inline-block;
  height: 1.2lh;
  vertical-align: middle;
  :deep(.badge) {
    display: inline-block;
    height: 1.2lh;
    vertical-align: middle;
  }
}
.input-set-gap {
  padding: 0 0 12px 0;
  margin: 4px 0;
}
</style>
