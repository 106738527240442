import { FormOptionType, type FormOption } from './type'

export const setInitValue = (fo: FormOption): FormOption => {
  if (fo.type === FormOptionType.Text) {
    if (!fo.value) {
      fo.value = ''
    }
  }
  if (fo.type === FormOptionType.Choice) {
    if (!('selected' in fo) || (fo.selected !== null && typeof fo.selected !== 'number')) {
      fo.selected = null
    }
  }
  if (fo.type === FormOptionType.CheckBox) {
    if (!fo.selected) {
      fo.selected = []
    }
  }
  if (fo.type === FormOptionType.Quantitative) {
    if (!fo.value) {
      fo.value = null
    }
  }
  if (fo.type === FormOptionType.InputSet) {
    if (!fo.values) {
      fo.values = []
    }
  }
  return fo
}

export const setCleanValue = (fo: FormOption): FormOption => {
  if (fo.type === FormOptionType.Text) {
    fo.value = ''
  }
  if (fo.type === FormOptionType.Choice) {
    fo.selected = null
  }
  if (fo.type === FormOptionType.CheckBox) {
    fo.selected = []
  }
  if (fo.type === FormOptionType.Date) {
    fo.value = null
  }
  if (fo.type === FormOptionType.Quantitative) {
    fo.value = null
  }
  if (fo.type === FormOptionType.InputSet) {
    fo.values = []
  }
  return fo
}
