import './assets/main.css'

import * as Sentry from '@sentry/vue'
import { createPinia } from 'pinia'
import { createApp } from 'vue'

import 'vuetify/styles'

import App from './App.vue'
import i18n from './common/i18n'
import vuetify from './common/VuetifyTheme/createVuetify'
import { server } from './mock/browser'
import router from './router'

if (process.env.NODE_ENV === 'development' && import.meta.env.VITE_API_MOCK === 'true') {
  server.start()
}

const app = createApp(App)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  integrations: []
})

app.use(createPinia())
app.use(router)
app.use(vuetify)
app.use(i18n)

app.mount('#app')
