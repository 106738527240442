import { isAxiosError } from 'axios'

import { Api } from '@/api/generate/apiSchema'
import { encodedToken } from '@/api/userDataCookie'
import router from '@/router'

export type ErrorOption = {
  allowNotFound: boolean
}

const baseApiClient = () => {
  const tokens = encodedToken()
  return new Api({
    timeout: import.meta.env.VITE_API_REQUEST_TIMEOUT,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${tokens}`
    },
    withCredentials: true
  })
}

// 医療機関API用クライアント
export const medicalInstitutionsApiClient = (errorOption: ErrorOption | undefined = undefined) => {
  const apiClient = baseApiClient()
  apiClient.instance.defaults.baseURL = import.meta.env.VITE_API_MEDICAL_INSTITUTION_API_BASE_URL
  instanceInterceptors(apiClient, errorOption)
  return apiClient
}

// 入院オーダー用API用クライアント
export const hospitalizationOrderApiClient = (errorOption: ErrorOption | undefined = undefined) => {
  const apiClient = baseApiClient()
  apiClient.instance.defaults.baseURL = import.meta.env.VITE_API_HOSPITALIZATION_API_BASE_URL
  instanceInterceptors(apiClient, errorOption)
  return apiClient
}

// 書類管理API用クライアント
export const documentManagementApiClient = () => {
  const apiClient = baseApiClient()
  apiClient.instance.defaults.baseURL = import.meta.env.VITE_API_DOCUMENT_MANAGEMENT_API_BASE_URL
  instanceInterceptors(apiClient)
  return apiClient
}

// ユーザーAPI用クライアント
export const usersApiClient = () => {
  const apiClient = baseApiClient()
  apiClient.instance.defaults.baseURL = import.meta.env.VITE_API_USER_API_BASE_URL
  instanceInterceptors(apiClient)
  return apiClient
}

const instanceInterceptors = (
  Client: Api<unknown>,
  errorOption: ErrorOption | undefined = undefined
) => {
  Client.instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (isAxiosError(error)) {
        switch (error.response?.status) {
          // 認証の失敗
          case 401:
            // ログイン処理へ遷移
            if (error.response.data.error_code === 'CM0002') {
              if (
                error.config!.baseURL === import.meta.env.VITE_API_MEDICAL_INSTITUTION_API_BASE_URL
              ) {
                // 医療機関APIのログイン状態を元にログイン画面を表示する
                window.location.href = `${error.config!.baseURL}/gateway/login?isAutoLogin=false`
              } else {
                window.location.href = `${error.config!.baseURL}/gateway/login?isAutoLogin=true`
              }
              return
            }

            break
          // 認可の失敗
          case 403:
            router.replace('/forbidden')
            return
          // 存在しない
          case 404:
            if (!errorOption?.allowNotFound) {
              router.replace('/not_found')
            }
            throw error
          case 500:
          case 501:
          case 502:
          case 503:
          case 504:
          case 505:
          case 506:
          case 507:
          case 508:
          case 509:
          case 510:
          case 511:
            router.replace('/server_error')
            return
          default:
        }
      }
      throw error
    }
  )
}
