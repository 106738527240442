<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import SectionResult from '../CommonForm/SectionResult.vue'

import type { SectionContainer as SectionContainerType } from '../CommonForm/type'
import type { ParUpdateHospitalizationOrderSubmissionContentRequest } from '@/api/generate/apiSchema'

import { hospitalizationOrderApiClient } from '@/api/apiClient'
import BaseButton from '@/components/Base/Button/index.vue'
import OnlyTextContentDialog from '@/components/Dialog/OnlyTextContentDialog/index.vue'
import router from '@/router'
import { useHospitalizationOrderStore } from '@/stores/hospitalizationOrder'

const hospitalizationOrderStore = useHospitalizationOrderStore()

// propsを定義
const props = withDefaults(
  defineProps<{
    contentJson: string
    medicalInstitutionId: string
    hospitalizationOrderId: string
    hospitalizationOrderDocumentSetContentId: string
    hospitalizationOrderDocumentSetId: string
  }>(),
  {}
)
const sectionContainers = ref<SectionContainerType[]>(JSON.parse(props.contentJson))
const { t } = useI18n()
const isLoading = ref<boolean>(false)

// フォーム回答完了画面に遷移
const transitionCompletedHospitalizationFormContent = () => {
  router.replace({
    name: 'DGHP-006-03',
    params: {
      medicalInstitutionId: props.medicalInstitutionId,
      hospitalizationOrderDocumentSetId: props.hospitalizationOrderDocumentSetId,
      hospitalizationOrderDocumentSetContentId: props.hospitalizationOrderDocumentSetContentId
    }
  })
}

// フォーム回答画面に遷移
const transitionEditHospitalizationFormContent = () => {
  router.replace({
    name: 'DGHP-006-01',
    params: {
      medicalInstitutionId: props.medicalInstitutionId,
      hospitalizationOrderDocumentSetId: props.hospitalizationOrderDocumentSetId,
      hospitalizationOrderDocumentSetContentId: props.hospitalizationOrderDocumentSetContentId
    }
  })
}

// 回答をAPIに送信
const submit = async () => {
  isLoading.value = true

  if (sectionContainers.value === undefined) {
    isLoading.value = false
    toggleValidationDialog()
    return
  }

  // リクエスト情報を作成
  const data: ParUpdateHospitalizationOrderSubmissionContentRequest = {
    submitted: true,
    contentJson: JSON.stringify(sectionContainers.value)
  }

  try {
    await hospitalizationOrderApiClient().api.updateHospitalizationOrderSubmissionFormContentByPar(
      props.medicalInstitutionId,
      props.hospitalizationOrderId,
      props.hospitalizationOrderDocumentSetContentId,
      { userId: '', representativeId: hospitalizationOrderStore.getRepresentativeId() },
      data
    )
    transitionCompletedHospitalizationFormContent()
  } catch (error) {
    console.error(error)
    toggleValidationDialog()
    isLoading.value = false
    throw error
  }
}

// 前の画面に戻って回答を修正する
const cancelConfirm = () => {
  transitionEditHospitalizationFormContent()
}

// モーダル開閉管理
const isOpenValidationDialog = ref<boolean>(false)

// バリデーションモーダルを開閉
const toggleValidationDialog = () => {
  isOpenValidationDialog.value = !isOpenValidationDialog.value
}
</script>

<template>
  <form>
    <!-- 患者が入力した回答フォーム -->
    <div v-if="sectionContainers !== undefined && sectionContainers.length">
      <div class="form-content-container">
        <div
          class="section-group"
          v-for="(section, sectionIndex) in sectionContainers"
          :key="section.id"
        >
          <div class="section-container">
            <SectionResult :section="section" :section-index="sectionIndex"></SectionResult>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-button-container">
      <BaseButton
        class="action-button"
        color="primary"
        block
        large
        :content="t('features.hospitalizationDocument.FormContentSubmission.completeAnswer')"
        type="button"
        variant="flat"
        @click="submit"
      ></BaseButton>
      <BaseButton
        class="action-button__back-button"
        color="primary"
        block
        large
        :content="t('features.hospitalizationDocument.FormContentSubmission.fixAnswer')"
        type="button"
        variant="flat"
        @click="cancelConfirm"
      ></BaseButton>
    </div>

    <!-- バリデーションエラーダイアログ -->
    <OnlyTextContentDialog
      :value="isOpenValidationDialog"
      :content="t('validations.error')"
      :cancelBtnText="t('attributes.close')"
      :size="'small'"
      :confirmBtnVisible="false"
      @input="toggleValidationDialog()"
    />
  </form>
</template>

<style lang="scss" scoped>
// セクションの集合
.section-group {
  margin: 0 0 16px 0;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #00000033;
  box-shadow: 0px 1px 3px 1px #0000001a;
  background-color: white;
}

// セクションごとの箱
.section-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 16px;
}

// アクションボタンコンテナ
.bottom-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin: 16px 0;
  padding: 0 32px 16px 32px;
}

// 次へボタン
.action-button {
  width: 100%;
  min-width: 92px;
  font-size: 1.125rem;
  height: 52px;
  font-weight: 600;
  border-radius: 24px;
  padding: 14px 87px;
  // 戻るボタン
  &__back-button {
    background-color: transparent !important;
    color: rgba(var(--v-theme-primary)) !important;
  }
}
</style>
