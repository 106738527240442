<script setup lang="ts">
import { computed, reactive, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'

import type { CheckBoxOption } from './type'

import BaseBadge from '@/components/Base/Badge/index.vue'
import BaseLabel from '@/components/Base/Label/index.vue'

const { t } = useI18n()

// props初期化
const props = withDefaults(
  defineProps<{
    sectionIndex: number
    content: CheckBoxOption
    /**
     * コンポーネント識別のため（主に入力セットで利用）
     */
    alternativeId?: string
  }>(),
  {}
)
const content = reactive<CheckBoxOption>(props.content)

// 選択中の状態を画面反映
const isChecked = (itemIndex: number) => {
  return content.selected.includes(itemIndex)
}

// emit定義
const emit = defineEmits<{
  (e: 'updateValue', value: number[]): void
}>()

// アクションを伝達
const onChange = () => {
  emit('updateValue', content.selected)
}

// IDを取得（alternativeIdが優先）
const getId = computed(() => {
  return props.alternativeId ?? content.id
})

// 入力セットでのpropsの変更を検知
watchEffect(() => {
  Object.assign(content, props.content)
})
</script>

<template>
  <div class="checkbox-container">
    <div>
      <!-- 項目名 -->
      <BaseLabel :label-text="content.name" token-type="LABEL_L" class="label-wrapper"></BaseLabel>
      <div v-show="content.isRequired" class="badge-wrapper">
        <BaseBadge
          :label="t('attributes.required')"
          background-color="primitive-red-accent-40-value"
        ></BaseBadge>
      </div>
    </div>

    <div>
      <p class="content-description">{{ content.description }}</p>
      <div v-for="(item, itemIndex) in content.selects" :key="itemIndex.toString()">
        <v-checkbox
          v-model="content.selected"
          :id="getId + itemIndex"
          class="custom-checkbox"
          :class="{ 'custom-checkbox-on': isChecked(itemIndex) }"
          :label="item"
          :value="itemIndex"
          false-icon="panorama_fish_eye"
          true-icon="check_circle"
          hide-details
          @input="onChange"
        ></v-checkbox>
      </div>
      <div class="v-input__details hint-message-wrapper">
        <div class="v-messages v-messages__message">
          {{ t('features.hospitalizationDocument.FormContentSubmission.pleaseSelect') }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
// チェックボックスコンテナ
.checkbox-container {
  padding: 0 0 16px 0;
}
// ラベルラッパー要素
.label-wrapper {
  display: inline-block;
}
// バッジラッパー要素
.badge-wrapper {
  display: inline-block;
  height: 1.2lh;
  vertical-align: middle;
  :deep(.badge) {
    display: inline-block;
    height: 1.2lh;
    vertical-align: middle;
  }
}
// コンテンツの説明文
.content-description {
  display: inline;
}
// カスタムチェックボックスUI
.custom-checkbox {
  margin: 8px 0 0 0;
  // チェックボックスを装飾
  :deep(.v-selection-control) {
    border: 1px solid gray;
    border-radius: 4px;
    display: flex;
    justify-content: start;
    align-items: center;
    // 当たり判定を広げる
    label {
      width: 100%;
    }
  }
  // レイアウト調整のためフィールド下部のヒント領域を非表示
  :deep(.v-input__details) {
    display: none;
  }
}
// ON時のアイコン色
.custom-checkbox-on :deep(.v-selection-control) {
  border-color: #0017c1;
  background-color: #f0f1fb;
  .v-icon {
    color: #0017c1;
  }
}
// ヒントメッセージの余白
.hint-message-wrapper {
  padding: 0 0 0 16px;
}
</style>
