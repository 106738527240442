<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { type Representative, type Patient } from './type'

import { hospitalizationOrderApiClient, medicalInstitutionsApiClient } from '@/api/apiClient'
import BaseButton from '@/components/Base/Button/index.vue'
import Label from '@/components/Base/Label/index.vue'
import ListCard from '@/components/Base/ListCard/index.vue'
import useDateFormatter from '@/composables/useDateFormatter'
import router from '@/router'

const { t } = useI18n()

type Props = {
  medicalInstitutionId: string
  hospitalizationOrderId: string | undefined
  patient: Patient
  representative: Representative | undefined
}

const props = defineProps<Props>()

const isDisabled = ref<boolean>(false)
const { getFormattedDate } = useDateFormatter()

// 利用規約同意ボタンタップ
const confirm = async () => {
  isDisabled.value = true
  try {
    if (props.representative == undefined || props.hospitalizationOrderId == undefined) {
      await medicalInstitutionsApiClient().api.confirmPatientByUser(props.medicalInstitutionId, {
        userId: ''
      })
    } else {
      await hospitalizationOrderApiClient().api.confirmPatientByRepresentative(
        props.medicalInstitutionId,
        props.hospitalizationOrderId,
        { userId: '' }
      )
    }

    router.replace(`/medical-institutions/${props.medicalInstitutionId}`)
  } catch (error) {
    isDisabled.value = false
    throw error
  }
}
</script>
<template>
  <div class="medical-institution-patient-confirmation">
    <div class="content">
      <div class="mx-auto">
        <Label
          :labelText="t('features.medicalInstitutionPatientConfirmation.description')"
          :isRequired="false"
          :token-type="'BODY_L'"
        ></Label>
      </div>
      <div>
        <ListCard
          :title="t('features.medicalInstitutionPatientConfirmation.label.patientName')"
          :content="patient.name"
          :clickable="false"
        />
      </div>
      <div>
        <ListCard
          :title="t('features.medicalInstitutionPatientConfirmation.label.gender')"
          :content="t(`attributes.gender.${patient.gender}`)"
          :clickable="false"
        />
      </div>
      <div>
        <ListCard
          :title="t('features.medicalInstitutionPatientConfirmation.label.birthDate')"
          :content="getFormattedDate(new Date(patient.birthDate))"
          :clickable="false"
        />
      </div>

      <div v-if="representative">
        <ListCard
          :title="t('features.medicalInstitutionPatientConfirmation.label.representativeName')"
          :content="representative.name"
          :clickable="false"
        />
      </div>

      <div v-if="representative">
        <ListCard
          :title="t('features.medicalInstitutionPatientConfirmation.label.relationship')"
          :content="t(`attributes.relationshipType.${representative.relationshipType}`)"
          :clickable="false"
        />
      </div>
    </div>

    <div>
      <BaseButton
        color="primary"
        block
        large
        :content="t('attributes.register')"
        :loading="isDisabled"
        :disabled="isDisabled"
        @click="confirm"
      >
      </BaseButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.medical-institution-patient-confirmation {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
</style>
