<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import Label from '@/components/Base/Label/index.vue'
import router from '@/router'

const { t } = useI18n()

const onClick = () => {
  router.push('/medical-institutions')
}
</script>

<template>
  <div @click="onClick" class="back-button">
    <v-container>
      <v-icon size="24" class="icon">arrow_back</v-icon>
      <Label
        class="label-wrapper"
        forHtml="content_title"
        :labelText="t('pages.bottomMenuHospital.list')"
        :isRequired="false"
        :token-type="'TITLE_M'"
      ></Label>
    </v-container>
  </div>
</template>

<style scoped lang="scss">
.back-button {
  padding: 0px;
}

.v-container {
  display: flex;
  align-items: center;
  padding: 0px;
}

.icon {
  margin-right: 4px;
}

.label-wrapper {
  display: flex;
  align-items: center;
}
</style>
