<script setup lang="ts">
import { toRef } from 'vue'
import { useI18n } from 'vue-i18n'

import { OnlyTextContentDialog } from '@/components/Dialog'
import router from '@/router'
import { useDisplayNameStore } from '@/stores/displayName'

const { t } = useI18n()
const displayNameStore = useDisplayNameStore()
displayNameStore.updateDisplayName(undefined)

// モーダル開閉管理
const isOpenErrorDialog = toRef<boolean>(true)

// 前のページを再読み込み
const reloadPage = () => {
  isOpenErrorDialog.value = false
  router.back()
}
</script>

<template>
  <v-container class="container">
    <OnlyTextContentDialog
      :value="isOpenErrorDialog"
      :title="t('pages.notFound.title')"
      :content="t('pages.notFound.description')"
      cancelBtnDefault="center"
      :cancelBtnText="t('attributes.close')"
      :size="'medium'"
      :confirmBtnVisible="false"
      @input="reloadPage"
    />
  </v-container>
</template>

<style lang="scss" scoped>
.container {
  padding: 16px;
  height: 100%;
}
</style>
