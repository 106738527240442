<script setup lang="ts">
import { computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useTheme } from 'vuetify'

type Props = {
  navTitle?: string
  showBackIcon?: boolean
  showReloadPageIcon?: boolean
  leftHeaderComponent?: Object
  rightHeaderComponent?: Object
  nonHeader?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  navTitle: '',
  showBackIcon: false,
  showReloadPageIcon: false,
  leftHeaderComponent: undefined,
  rightHeaderComponent: undefined,
  nonHeader: false
})

// 動的テーマ設定（AppBar用）
const route = useRoute()
const router = useRouter()
watch(
  () => route.meta.customThemeName,
  (value) => {
    if (typeof value === 'string') {
      theme.global.name.value = value
    }
  }
)
const theme = useTheme()
const backgroundColorStyle = computed(() => {
  const backgroundColor = theme.themes.value[theme.global.name.value].colors?.background || null
  if (backgroundColor) {
    return { background: backgroundColor }
  }
  return {}
})

// 戻るボタンハンドラー
function goBack() {
  router.back()
}

// リロードボタンハンドラー
function reloadPage() {
  window.location.reload()
}

// 左上ボタンの表示判定
const isShowCustomLeftButton = computed((): boolean => {
  return typeof props.leftHeaderComponent !== 'undefined' && props.leftHeaderComponent !== null
})

// 戻るボタンの表示
const isShowLeftDefaultButton = computed((): boolean => {
  if (isShowCustomLeftButton.value) {
    return false
  }
  return props.showBackIcon
})

// 右上ボタンの表示判定
const isShowCustomRightButton = computed((): boolean => {
  return typeof props.rightHeaderComponent !== 'undefined' && props.rightHeaderComponent !== null
})

// リロードボタンの表示
const isShowRightDefaultButton = computed((): boolean => {
  if (isShowCustomRightButton.value) {
    return false
  }
  return props.showReloadPageIcon
})
</script>

<template>
  <v-app-bar v-if="!nonHeader" elevation="0" class="custom-nav-bar" :style="backgroundColorStyle">
    <!-- 左上ボタン（カスタムコンポーネント用） -->
    <component v-if="isShowCustomLeftButton" :is="leftHeaderComponent" class="w-5 inline-block" />
    <!-- 戻るボタン -->
    <v-icon v-else-if="isShowLeftDefaultButton" @click="goBack">arrow_back</v-icon>
    <!-- 空白（左上デフォルト） -->
    <div v-else class="w-5 inline-block place-holder"></div>

    <!-- タイトルの表示、中央配置 -->
    <div class="mx-auto title-bar">{{ navTitle }}</div>
    <!-- 右上ボタン（カスタムコンポーネント用） -->
    <component v-if="isShowCustomRightButton" :is="rightHeaderComponent" class="w-5 inline-block" />
    <!-- リロードボタン -->
    <v-icon v-else-if="isShowRightDefaultButton" @click="reloadPage">rotate_left</v-icon>
    <!-- 空白（右上デフォルト） -->
    <div v-else class="w-5 inline-block place-holder"></div>
  </v-app-bar>
</template>

<style scoped>
.custom-nav-bar {
  justify-content: space-between !important;
  padding: 0px 16px 0px 16px !important;
  height: 64px !important;
}

.title-bar {
  font-size: 18px;
  text-align: center;
}

.opacity-0 {
  opacity: 0;
}

.place-holder {
  width: 24px;
  height: 24px;
}
</style>
