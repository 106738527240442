/**
 * フォームオプションの種別
 */
export enum FormOptionType {
  'Text' = 'text',
  'Choice' = 'choice',
  'CheckBox' = 'check_box',
  'Quantitative' = 'quantitative',
  'Date' = 'date',
  'InputSet' = 'input_set'
}

/**
 * セクション種別
 */
export enum SectionType {
  'Section' = 'section'
}

/**
 * フォームオプション基底情報
 */
type FormOptionBaseType<T extends FormOptionType> = {
  id: string
  type: T
}

/**
 * テキスト
 */
export type TextOption = FormOptionBaseType<FormOptionType.Text> & {
  name: string
  description: string
  isRequired: boolean
  isMultiLine: boolean
  value: string
}

/**
 * 選択肢
 */
export type ChoiceOption = FormOptionBaseType<FormOptionType.Choice> & {
  name: string
  description: string
  isRequired: boolean
  choices: Choice[]
  selected: number | null
}

/**
 * フォームオプションの種別
 */
export enum BranchName {
  'next' = 'next',
  'last' = 'last',
  'option' = 'option'
}

/**
 * ブランチ型の合成型
 */
export type BranchType = BranchNext | BranchLast | BranchNextOption
export type BranchTypeValue = BranchName.next | BranchName.last | BranchName.option

/**
 * 選択肢子要素 - 選択肢の分岐
 */
export type Choice = {
  // 選択肢入力フィールド
  name: string
  // 次へ | 末尾へ | 設問に分岐する場合
  branch: BranchType
}

/**
 * 選択肢子要素 - 次へ
 */
export type BranchNext = {
  type: BranchName.next
}

/**
 * 選択肢子要素 - 末尾へ
 */
export type BranchLast = {
  type: BranchName.last
}

/**
 * 選択肢子要素 - 個別設定
 */
export type BranchNextOption = {
  type: BranchName.option
  // 分岐先設問のid
  id: string
}

/**
 * チェックボックス
 */
export type CheckBoxOption = FormOptionBaseType<FormOptionType.CheckBox> & {
  name: string
  description: string
  isRequired: boolean
  selects: string[]
  selected: number[]
}

/**
 * 数値
 */
export type QuantitativeOption = FormOptionBaseType<FormOptionType.Quantitative> & {
  name: string
  description: string
  isRequired: boolean
  unit: string
  value: number | null
}

/**
 * 日付
 */
export type DateOption = FormOptionBaseType<FormOptionType.Date> & {
  name: string
  description: string
  isRequired: boolean
  value: string | null
}

/**
 * 入力セットvalues用型定義
 */
export type TextOptionForInputSet = Omit<TextOption, 'id'>
export type CheckBoxOptionForInputSet = Omit<CheckBoxOption, 'id'>
export type QuantitativeOptionForInputSet = Omit<QuantitativeOption, 'id'>
export type DateOptionForInputSet = Omit<DateOption, 'id'>
export type FormOptionForInputSet =
  | TextOptionForInputSet
  | CheckBoxOptionForInputSet
  | QuantitativeOptionForInputSet
  | DateOptionForInputSet

/**
 * 入力セット
 */
export type InputSetOption = FormOptionBaseType<FormOptionType.InputSet> & {
  name: string
  optionIds: string[]
  values: Record<string, FormOptionForInputSet>[][]
}

/**
 * フォームオプション
 * < text:テキスト | choice:選択肢 | check_box:チェックボックス | quantitative:数値 | date:日付 | input_set:入力セット >
 */
export type FormOption =
  | TextOption
  | ChoiceOption
  | CheckBoxOption
  | QuantitativeOption
  | DateOption
  | InputSetOption

/**
 * セクションコンテナ
 */
export type SectionContainer = {
  id: string
  name: string
  options: FormOption[]
}

/**
 * セクションコンテナリスト
 */
export type SectionsContainer = {
  [key: number]: SectionContainer[]
}

/**
 * 入力セット許可タイプ
 */
export type InputSetGroupType =
  | FormOptionType.Text
  | FormOptionType.CheckBox
  | FormOptionType.Quantitative
  | FormOptionType.Date
export type InputSetAllowedType<T extends InputSetGroupType> = T extends FormOptionType.Text
  ? TextOption
  : T extends FormOptionType.CheckBox
    ? CheckBoxOption
    : T extends FormOptionType.Quantitative
      ? QuantitativeOption
      : T extends FormOptionType.Date
        ? DateOption
        : never

/**
 * 入力セット用フォームコンテンツのテンプレート
 */
export type InputSetTemplate = { [key: string]: FormOptionForInputSet }

/**
 * 入力セット用フォームコンテンツのテンプレートのリスト
 */
export type TemplateList = {
  [key: string]: { id: string; list: string[]; template: InputSetTemplate[] }
}
