<script setup lang="ts">
import { getTypography } from '@optim-design-system/src'

type Props = {
  /**
   * タイトル
   */
  title: string
  /**
   * 説明
   */
  content?: string
  /**
   * 高さ
   */
  height?: string
  /**
   * 影
   */
  elevation?: string
  /**
   * 要素をクリック出来るように表示するか
   */
  clickable?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  title: 'タイトル',
  height: '',
  elevation: '2',
  clickable: true
})

const titleStyle = getTypography('TITLE_M')
const contentStyle = getTypography('BODY_M')
</script>

<template>
  <v-sheet
    class="list-card"
    :class="{ 'list-card--clickable': clickable }"
    :height="height"
    :elevation="elevation"
  >
    <div class="list-card-title">
      <div :style="titleStyle">{{ props.title }}</div>
    </div>
    <div class="list-card-content" v-if="content != undefined">
      <div :style="contentStyle">{{ props.content }}</div>
    </div>
  </v-sheet>
</template>
<style lang="scss" scoped>
.list-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border-radius: 10px;

  &--clickable {
    &:active {
      background-color: #d1d4d6 !important;
    }
  }
}
</style>
