<script setup lang="ts">
import { getTypography, muiThemeColors } from '@optim-design-system/src'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import Dialog from '@/components/Base/Dialog/index.vue'
import { useFormContentNavigationStore } from '@/stores/formContentNavigation'

const { t } = useI18n()
const formContentNavigationStore = useFormContentNavigationStore()

// フォントカラー
const colorStyle = computed(() => {
  return muiThemeColors['theme-DEFAULT-on-surface-error-value']
})

const interruptEdit = () => {
  formContentNavigationStore.executeInterruptAction()
}

// 書式
const bodyStyle = computed(() => {
  return getTypography('LABEL_M')
})

// ダイアログ開閉状態管理
const isOpenOpenDialog = ref<boolean>(false)
const openDialog = () => {
  isOpenOpenDialog.value = !isOpenOpenDialog.value
}
</script>

<template>
  <div>
    <v-btn :style="[bodyStyle]" :color="colorStyle" @click="openDialog">{{
      t('features.hospitalizationDocument.FormContentSubmission.interruptForm')
    }}</v-btn>

    <Dialog
      :value="isOpenOpenDialog"
      :title="t('features.hospitalizationDocument.FormContentSubmission.interruptFormTitle')"
      :confirmBtnText="
        t('features.hospitalizationDocument.FormContentSubmission.interruptFormOkText')
      "
      confirmBtnVisible
      :cancelBtnText="
        t('features.hospitalizationDocument.FormContentSubmission.interruptFormCancelText')
      "
      size="small"
      @confirm="interruptEdit"
      @input="openDialog"
    >
      <template v-slot:contents>
        <p>
          {{ t('features.hospitalizationDocument.FormContentSubmission.interruptFormDescription') }}
        </p>
      </template>
    </Dialog>
  </div>
</template>

<style scoped lang="scss"></style>
