type NestedObject = {
  [key: string]: NestedObject | string;
};

/**
 * ネストされたオブジェクトをフラット化する関数
 *
 * @param {NestedObject} obj - フラット化するオブジェクト
 *
 * @param {string} parentKey - 親キー
 *
 * @param {Record<string, string>} result - 結果オブジェクト
 *
 * @returns {Record<string, string>} フラット化されたオブジェクト
 */
export const flattenAndExcludeValue = (
  obj: NestedObject,
  parentKey = '',
  result: Record<string, string> = {},
): Record<string, string> => {
  for (const key in obj) {
    // Construct the new key
    const newKey = parentKey ? `${parentKey}-${key}` : key;

    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      // If the key is 'value', skip it
      if (key === 'value') continue;

      // Recurse into the nested object
      flattenAndExcludeValue(obj[key] as NestedObject, newKey, result);
    } else {
      // Add the key-value pair to the result object
      result[newKey] = obj[key] as string;
    }
  }
  return result;
};
