<script setup lang="ts">
import { getTypography } from '@optim-design-system/src'
import { toRefs } from 'vue'

import type { TypographyTokenType } from './types'

type Props = {
  /**
   * HTMLタグ
   */
  tag?: string | object
  /**
   * テキストの種類
   */
  tokenType?: TypographyTokenType
}

const props = withDefaults(defineProps<Props>(), {
  tag: 'span',
  tokenType: 'BODY_M'
})

const { tag, tokenType } = toRefs(props)

// フォントのデザイントークンを取得
const tokenStyle = getTypography(tokenType.value)
</script>

<template>
  <component :is="tag" v-bind="$attrs" :style="tokenStyle">
    <slot />
  </component>
</template>
