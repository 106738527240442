<script setup lang="ts">
import { getTypography } from '@optim-design-system/src'

import type { CancelBtnType, SizeType } from '@/components/Base/Dialog/types'
import type { TypographyTokenType } from '@/components/Typography/types'
import type { VuetifyColorTokenType } from '@common/VuetifyTheme/types'

import BaseButton from '@/components/Base/Button/index.vue'
import BaseDialog from '@/components/Base/Dialog/index.vue'
import useDialog from '@/composables/useDialog'

type Props = {
  /**
   * ダイアログの開閉
   */
  value: boolean
  /**
   * ダイアログのタイトル
   */
  title?: string
  /**
   * 完了ボタン色
   */
  confirmBtnColor?: VuetifyColorTokenType
  /**
   * 完了ボタンのテキスト
   */
  confirmBtnText?: string
  /**
   * 完了ボタン表示
   */
  confirmBtnVisible?: boolean
  /**
   * ダイアログの内容
   */
  content: string

  /**
   * キャンセルボタン色
   */
  cancelBtnColor?: string

  /**
   * キャンセルボタンのテキスト
   */
  cancelBtnText: string

  cancelBtnDefault?: CancelBtnType

  /**
   * サイズ
   */
  size: SizeType

  /**
   * トークンタイプ
   */
  tokenType?: TypographyTokenType
}

const props = withDefaults(defineProps<Props>(), {
  value: false,
  title: '',
  cancelBtnDefault: 'right',
  confirmBtnText: '',
  confirmBtnColor: 'theme-DEFAULT-surface-brand-primary-value',
  confirmBtnVisible: true,
  content: '',
  size: 'medium',
  tokenType: 'BODY_L'
})

type Emits = {
  (e: 'confirm', done: () => void, failure: () => void): void
  (e: 'input', value: boolean): void
}

const emit = defineEmits<Emits>()

// ローディング管理用
const { isLoadingState, startLoading, stopLoading } = useDialog()

// submit処理
const onConfirm = () => {
  startLoading()
  const done = () => {
    stopLoading()
    onClose(false)
  }
  const failure = () => {
    stopLoading()
  }
  emit('confirm', done, failure)
}

const onClose = (value: boolean) => {
  emit('input', value)
}

const tokenStyle = getTypography(props.tokenType)
</script>

<template>
  <base-dialog
    :value="value"
    :title="title"
    :confirm-btn-text="confirmBtnText"
    :confirmBtnVisible="confirmBtnVisible"
    :cancelBtnText="cancelBtnText"
    :cancelBtnColor="cancelBtnColor"
    :size="size"
    :cancelBtnDefault="cancelBtnDefault"
    persistent
    @input="onClose"
  >
    <template #activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }" />
    </template>
    <template #contents>
      <div :style="tokenStyle" class="v-dialog-text" v-text="content" />
    </template>
    <template #actions-right>
      <base-button
        :color="confirmBtnColor"
        :loading="isLoadingState.value"
        :content="confirmBtnText"
        @click="onConfirm"
      />
    </template>
  </base-dialog>
</template>

<style lang="scss" scoped>
.v-dialog-text {
  white-space: pre-wrap;
}
</style>
