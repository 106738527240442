<script setup lang="ts">
import { computed } from 'vue'

type CircularSizeType = 'small' | 'medium' | 'large'

const CircularSize = {
  small: 18,
  medium: 70,
  large: 140
} as const

const CircularWidthSize = {
  small: 2,
  medium: 7,
  large: 14
} as const

type Props = {
  /**
   * サーキュラーサイズ
   */
  size?: CircularSizeType
  /**
   * サーキュラーカラー
   */
  color?: string
}

const props = withDefaults(defineProps<Props>(), {
  size: 'medium',
  color: 'secondary'
})

// サーキュラーのサイズ
const baseCircularClass = computed<number>(() => {
  switch (props.size) {
    case 'small':
      return CircularSize.small
    case 'medium':
      return CircularSize.medium
    case 'large':
      return CircularSize.large
    default:
      return CircularSize.medium
  }
})

// サーキュラーのサイズ
const baseCircularWidthSize = computed<number>(() => {
  switch (props.size) {
    case 'small':
      return CircularWidthSize.small
    case 'medium':
      return CircularWidthSize.medium
    case 'large':
      return CircularWidthSize.large
    default:
      return CircularWidthSize.medium
  }
})
</script>

<template>
  <v-progress-circular
    indeterminate
    :size="baseCircularClass"
    :width="baseCircularWidthSize"
    :color="color"
    class="mb-0"
  />
</template>
