<script setup lang="ts">
import { HttpStatusCode } from 'axios'
import { onMounted, ref } from 'vue'

import { usersApiClient } from '@/api/apiGatewayClient'

const versions = ref<object>()
const patientFrontend = ref('')

onMounted(async () => {
  const response = await usersApiClient().gateway.getVersions()
  if (response.status === HttpStatusCode.Ok) {
    versions.value = response.data.versions
  }
  patientFrontend.value = import.meta.env.VITE_APP_VERSION
})
</script>
<template>
  <div class="container">
    <h1>versions</h1>
    <div v-for="(value, key) in versions" :key="key">{{ key }}: {{ value }}</div>
    <div>patient-frontend: {{ patientFrontend }}</div>
  </div>
</template>
<style lang="css" scoped>
.container {
  padding: 16px;
  text-align: center;
}
</style>
