import Cookie from 'js-cookie'

// ユーザーIDをCookieにセットする
export const setUserId = (userId: string) => {
  Cookie.set('userId', userId)
}

// 紐付け用トークンIDをCookieにセットする
export const setBindingTokenId = (bindingTokenId: string) => {
  Cookie.set('bindingTokenId', bindingTokenId)
}

// 紐付け用トークンをCookieにセットする
export const setBindingToken = (bindingToken: string) => {
  Cookie.set('bindingToken', bindingToken)
}

// ユーザーIDと認証用トークンをCookieにセットする
export const setEncodedToken = (userId: string, appToken: string) => {
  Cookie.set('userId', userId)
  Cookie.set('appToken', appToken)
}

// Cookieからユーザーidを取得する
export const getUserId = () => {
  const userId = Cookie.get('userId')
  return userId
}

// Cookieから紐付け用トークンidを取得する
export const getBindingTokenId = () => {
  const userId = Cookie.get('bindingTokenId')
  return userId
}

// Cookieから紐付け用トークンを取得する
export const getBindingToken = () => {
  const userId = Cookie.get('bindingToken')
  return userId
}

// Cookieからユーザーidと認証用トークンを取得する
export const encodedToken = () => {
  const userId = Cookie.get('userId') || ''
  const appToken = Cookie.get('appToken') || ''
  return encodeToBase64(userId, appToken)
}

// base64形式に変換する
const encodeToBase64 = (userId: string, appToken: string) => {
  const credentials = `${userId}:${appToken}`
  return btoa(credentials)
}
