<script setup lang="ts">
import { computed, reactive, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'

import type { DateOption } from './type'

import BaseBadge from '@/components/Base/Badge/index.vue'
import BaseLabel from '@/components/Base/Label/index.vue'

const { t } = useI18n()

// props初期化
const props = withDefaults(
  defineProps<{
    sectionIndex: number
    content: DateOption
    /**
     * コンポーネント識別のため（主に入力セットで利用）
     */
    alternativeId?: string
  }>(),
  {}
)
const content = reactive<DateOption>(props.content)

// IDを取得（alternativeIdが優先）
const getId = computed(() => {
  return props.alternativeId ?? content.id
})

// ラベルID発行
const labelId = computed(() => {
  return `${props.sectionIndex}_${getId.value}`
})

// emitを定義
const emit = defineEmits<{
  (e: 'onChange', value: string | null): void
}>()

const onChange = () => {
  emit('onChange', content.value)
}
// 日付入力リセット
const onClear = () => {
  content.value = null
}

// 入力セットでのpropsの変更を検知
watchEffect(() => {
  Object.assign(content, props.content)
})
</script>

<template>
  <div>
    <div>
      <!-- 項目名 -->
      <BaseLabel :label-text="content.name" token-type="LABEL_L" class="label-wrapper"></BaseLabel>
      <div v-show="content.isRequired" class="badge-wrapper">
        <BaseBadge
          :label="t('attributes.required')"
          background-color="primitive-red-accent-40-value"
        ></BaseBadge>
      </div>
    </div>

    <div>
      <label :for="labelId" class="content-description">{{ content.description }}</label>
      <v-text-field
        :id="labelId"
        class="input-field"
        variant="outlined"
        type="date"
        v-model="content.value"
        :hint="t('features.hospitalizationDocument.FormContentSubmission.pleaseEnterDate')"
        persistent-hint
        clearable
        @update:modelValue="onChange"
        @click:clear="onClear"
        :rules="[]"
      ></v-text-field>
    </div>
  </div>
</template>

<style scoped lang="scss">
// ラベルラッパー要素
.label-wrapper {
  display: inline-block;
}
// バッジラッパー要素
.badge-wrapper {
  display: inline-block;
  height: 1.2lh;
  vertical-align: middle;
  :deep(.badge) {
    display: inline-block;
    height: 1.2lh;
    vertical-align: middle;
  }
}
// コンテンツ説明文
.content-description {
  display: inline;
}
.input-field {
  padding: 4px 0;
}
</style>
