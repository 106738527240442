/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ErrorDetail {
  code: 'DOMAIN_EXCEPTION' | 'DEPENDENT_FROM_OUTSIDE' | 'VALIDATION' | 'CONFLICT'
  field: string
  message?: string
}

export interface UnexpectedException {
  message: string
  errors: ErrorDetail[]
}

export interface UsUserRequest {
  /** @format uuid */
  termsId: string
}

export interface ParUpdateHospitalizationOrderSubmissionContentRequest {
  submitted: boolean
  contentJson: string
}

export interface ParHospitalizationOrderSubmissionFormContentResponse {
  /** @format uuid */
  hospitalizationOrderDocumentSetContentId: string
  submitted: boolean
  contentJson: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface MiBindPatientRequest {
  userMedicalInstitutionBindingToken: string
}

export interface MiRepresentativeRequest {
  /** @format uuid */
  representativeId?: string
  userMedicalInstitutionBindingToken: string
  familyName: string
  givenName: string
  relationshipType: string
}

export interface MiRepresentativeResponse {
  /** @format uuid */
  representativeId: string
  /** @format uuid */
  userId: string
  /** @format uuid */
  userMedicalInstitutionBindingTokenId: string
  familyName: string
  givenName: string
  relationshipType: string
}

export interface MiUpdateHospitalizationOrderConfirmationFormContentRequest {
  contentJson: string
}

export interface MiHospitalizationOrderConfirmationFormContentResponse {
  /** @format uuid */
  hospitalizationOrderDocumentSetContentId: string
  confirmed: boolean
  contentJson: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface UsCreatedUserResponse {
  /** @format uuid */
  id: string
  appToken: string
  /** @format date-time */
  agreedTermsAt: string
  termsAgreed: boolean
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface UsUserMedicalInstitutionBindingTokenResponse {
  /** @format uuid */
  id: string
  bindingToken: string
  /** @format date-time */
  createdAt: string
}

export interface SpCreateMedicalInstitutionRequest {
  /** @format uuid */
  medicalInstitutionId: string
  name: string
}

export interface SpMedicalInstitutionResponse {
  /** @format uuid */
  id: string
  name: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface MwProfileResponse {
  /** @format uuid */
  id: string
  familyName: string
  givenName: string
  phoneticFamilyName: string
  phoneticGivenName: string
  email: string
  roleUrn: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface MiCreatePatientRequest {
  patientNo: string
  familyName: string
  givenName: string
  phoneticFamilyName: string
  phoneticGivenName: string
  /** @format int32 */
  gender: number
  /** @format date */
  birthDate: string
}

export interface MiPatientResponse {
  /** @format uuid */
  id: string
  patientNo: string
  familyName: string
  givenName: string
  phoneticFamilyName: string
  phoneticGivenName: string
  /** @format int32 */
  gender: number
  /** @format date */
  birthDate: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface MiUpdatePatientRequest {
  patientNo: string
  familyName: string
  givenName: string
  phoneticFamilyName: string
  phoneticGivenName: string
  /** @format int32 */
  gender: number
  /** @format date */
  birthDate: string
}

export interface MiCreateMedicalWorkerRequest {
  familyName: string
  givenName: string
  phoneticFamilyName: string
  phoneticGivenName: string
  /** @pattern ^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9]+([-]*[a-zA-Z0-9]+)*([.][a-zA-Z0-9]+([-]*[a-zA-Z0-9]+)*)*$ */
  email: string
  roleUrn: string
}

export interface MiMedicalWorkerResponse {
  /** @format uuid */
  id: string
  familyName: string
  givenName: string
  phoneticFamilyName: string
  phoneticGivenName: string
  email: string
  roleUrn: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface MiUpdateMedicalWorkerRequest {
  familyName: string
  givenName: string
  phoneticFamilyName: string
  phoneticGivenName: string
  /** @pattern ^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9]+([-]*[a-zA-Z0-9]+)*([.][a-zA-Z0-9]+([-]*[a-zA-Z0-9]+)*)*$ */
  email: string
  roleUrn: string
}

export interface MiFormHospitalizationOrderRequest {
  /** @format uuid */
  patientId: string
  hospitalDepartment: string
  ward: string
  hospitalRoom: string
  /** @format date */
  hospitalizationDate?: string
  /** @format date */
  dischargeDate?: string
}

export interface MiHospitalizationOrderRepresentativeItem {
  /** @format uuid */
  id: string
  familyName: string
  givenName: string
  relationshipCode: string
}

export interface MiHospitalizationOrderResponse {
  /** @format uuid */
  id: string
  /** @format uuid */
  patientId: string
  representatives: MiHospitalizationOrderRepresentativeItem[]
  hospitalizationOrderStatus: string
  hospitalDepartment: string
  ward: string
  hospitalRoom: string
  /** @format date */
  hospitalizationDate?: string
  /** @format date */
  dischargeDate?: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface MiAddHospitalizationOrderDocumentSetsRequest {
  /**
   * @maxItems 10
   * @minItems 1
   */
  documentSetIds: string[]
}

export interface MiFormDocumentSetRequest {
  title: string
  description: string
  /**
   * @maxItems 20
   * @minItems 1
   */
  contentIds: string[]
}

export interface MiDocumentSetSlimResponse {
  /** @format uuid */
  id: string
  title: string
  description: string
  /** @format date-time */
  createAt: string
  /** @format date-time */
  updatedAt: string
  contentIds: string[]
}

export interface MiContentRequest {
  title: string
  description: string
  urlContentRevision?: MiUrlContentRevisionItem
  formContentRevision?: MiFormContentRevisionItem
  tag?: string
}

export interface MiFormContentRevisionItem {
  contentJson: string
}

export interface MiUrlContentRevisionItem {
  url: string
}

export interface MiContentCreateResponse {
  /** @format uuid */
  id: string
  title: string
  description: string
  urlContentRevision?: MiUrlContentRevisionItem
  formContentRevision?: MiFormContentRevisionItem
  tag?: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface SpUpdateMedicalInstitutionRequest {
  name: string
  updateTerms: boolean
}

export interface MiUpdateHospitalizationOrderRequest {
  /** @format uuid */
  patientId: string
  hospitalizationOrderStatus: string
  hospitalDepartment: string
  ward: string
  hospitalRoom: string
  /** @format date */
  hospitalizationDate?: string
  /** @format date */
  dischargeDate?: string
}

export interface MiContentUpdateResponse {
  /** @format uuid */
  id: string
  title: string
  description: string
  urlContentRevision?: MiUrlContentRevisionItem
  formContentRevision?: MiFormContentRevisionItem
  tag?: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface CoreBackendAppVersionResponse {
  version: string
}

export interface UsUserResponse {
  /** @format uuid */
  id: string
  /** @format date-time */
  agreedTermsAt: string
  termsAgreed: boolean
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface DigihosTermsResponse {
  baseUrl: string
  termsFileName: string
  privacyPolicyFileName: string
  /** @format uuid */
  termsId: string
}

export interface UsMedicalInstitutionItem {
  /** @format uuid */
  id: string
  name: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface UsMedicalInstitutionListResponse {
  list: UsMedicalInstitutionItem[]
}

export interface UsMedicalInstitutionResponse {
  /** @format uuid */
  id: string
  name: string
  baseUrl: string
  termsFilePath: string
  privacyPolicyFilePath: string
  /** @format uuid */
  medicalInstitutionTermsId: string
  /** @format date-time */
  termsUpdatedAt: string
  termsAgreed: boolean
}

export interface UsPatientResponse {
  /** @format uuid */
  id: string
  patientNo: string
  familyName: string
  givenName: string
  phoneticFamilyName: string
  phoneticGivenName: string
  /** @format int32 */
  gender: number
  patientConfirmed: boolean
  /** @format date */
  birthDate: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface ParUrlContentRevisionResponse {
  /** @format uuid */
  id: string
  url: string
  /** @format date-time */
  createdAt: string
}

export interface ParFormContentRevisionResponse {
  /** @format uuid */
  formContentRevisionId: string
  contentJson: string
  /** @format date-time */
  createdAt: string
}

export interface ParHospitalizationOrderDocumentSetItem {
  /** @format uuid */
  id: string
  /** @format uuid */
  documentSetId: string
  title: string
  description: string
  documentSetStatus: string
}

export interface ParHospitalizationOrderDocumentSetListResponse {
  list: ParHospitalizationOrderDocumentSetItem[]
}

export interface ParHospitalizationOrderContentItem {
  /** @format uuid */
  id: string
  /** @format uuid */
  contentId: string
  title: string
  description: string
  contentType: string
  /** @format uuid */
  contentRevisionId: string
  contentStatus?: string
}

export interface ParHospitalizationOrderContentListResponse {
  list: ParHospitalizationOrderContentItem[]
}

export interface ParHospitalizationOrderContentResponse {
  /** @format uuid */
  id: string
  /** @format uuid */
  contentId: string
  contentType: string
  title: string
  description: string
  /** @format uuid */
  contentRevisionId: string
}

export interface ParHospitalizationOrderPatientItem {
  /** @format uuid */
  id: string
  patientNo: string
  familyName: string
  givenName: string
  phoneticFamilyName: string
  phoneticGivenName: string
  /** @format int32 */
  gender: number
  /** @format date */
  birthDate: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface ParHospitalizationOrderRepresentativeItem {
  /** @format uuid */
  id: string
  /** @format uuid */
  userId: string
  familyName: string
  givenName: string
  relationshipType: string
  patientConfirmed: boolean
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface ParHospitalizationOrderResponse {
  /** @format uuid */
  id: string
  hospitalizationOrderStatus: string
  hospitalDepartment: string
  ward: string
  hospitalRoom: string
  representatives: ParHospitalizationOrderRepresentativeItem[]
  /** @format date */
  hospitalizationDate?: string
  patient: ParHospitalizationOrderPatientItem
  /** @format date */
  dischargeDate?: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface SpMedicalInstitutionItem {
  /** @format uuid */
  id: string
  name: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface SpMedicalInstitutionListResponse {
  list: SpMedicalInstitutionItem[]
}

export interface RtMedicalInstitutionItem {
  /** @format uuid */
  id: string
  name: string
  representativeIds: string[]
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface RtMedicalInstitutionListResponse {
  list: RtMedicalInstitutionItem[]
}

export interface RtMedicalInstitutionResponse {
  /** @format uuid */
  id: string
  name: string
  representativeIds: string[]
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface MwMedicalInstitutionItem {
  /** @format uuid */
  id: string
  name: string
  /** @format uuid */
  medicalWorkerId: string
  roleUrn: string
  familyName: string
  givenName: string
  email: string
}

export interface MwMedicalInstitutionListResponse {
  list: MwMedicalInstitutionItem[]
}

export interface MiMedicalInstitutionResponse {
  /** @format uuid */
  id: string
  name: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface MiPatientItem {
  /** @format uuid */
  id: string
  patientNo: string
  familyName: string
  givenName: string
  /** @format int32 */
  gender: number
  /** @format date */
  birthDate: string
  hospitalizationOrderStatus?: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface MiPatientListResponse {
  list: MiPatientItem[]
}

export interface MiUserPatientResponse {
  /** @format uuid */
  userId?: string
}

export interface MiMedicalWorkerItem {
  /** @format uuid */
  id: string
  familyName: string
  givenName: string
  phoneticFamilyName: string
  phoneticGivenName: string
  email: string
  roleUrn: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface MiMedicalWorkerListResponse {
  list: MiMedicalWorkerItem[]
}

export interface MiHospitalizationOrderItem {
  /** @format uuid */
  id: string
  patientNo: string
  familyName: string
  givenName: string
  /** @format int32 */
  gender: number
  /** @format date */
  hospitalizationDate?: string
  hospitalizationOrderStatus: string
  useAppStatus: string
  documentSetStatus: string
}

export interface MiHospitalizationOrderListResponse {
  list: MiHospitalizationOrderItem[]
}

export interface MiHospitalizationOrderSubmissionFormContentResponse {
  /** @format uuid */
  hospitalizationOrderDocumentSetContentId: string
  submitted: boolean
  contentJson: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface MiHospitalizationOrderDocumentSetItem {
  /** @format uuid */
  hospitalizationOrderDocumentSetId: string
  /** @format uuid */
  hospitalizationOrderDocumentSetContentId: string
  /** @format uuid */
  documentSetId: string
  documentSetTitle: string
  /** @format uuid */
  contentId: string
  /** @format uuid */
  contentRevisionId: string
  contentTitle: string
  contentType: string
  contentStatusCode: string
  /** @format date-time */
  updatedAt: string
}

export interface MiHospitalizationOrderDocumentSetListResponse {
  list: MiHospitalizationOrderDocumentSetItem[]
}

export interface MiHospitalizationOrderDocumentSetContentResponse {
  /** @format uuid */
  id: string
  /** @format uuid */
  contentId: string
  /** @format uuid */
  contentRevisionId: string
  title: string
  description: string
  contentType: string
}

export interface MiDocumentSetItem {
  /** @format uuid */
  id: string
  title: string
  /** @format int32 */
  contentSize: number
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface MiDocumentSetListResponse {
  list: MiDocumentSetItem[]
}

export interface MiDocumentSetContentItem {
  /** @format uuid */
  id: string
  title: string
  contentType: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface MiDocumentSetResponse {
  /** @format uuid */
  id: string
  title: string
  description: string
  /** @format date-time */
  createAt: string
  /** @format date-time */
  updatedAt: string
  contents: MiDocumentSetContentItem[]
}

export interface MiContentItem {
  /** @format uuid */
  id: string
  title: string
  contentType: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface MiContentListResponse {
  list: MiContentItem[]
}

export interface MiContentResponse {
  /** @format uuid */
  id: string
  title: string
  description: string
  tag?: string
  contentType: string
  /** @format date-time */
  createdAt: string
  /** @format date-time */
  updatedAt: string
}

export interface MiUrlContentRevisionResponse {
  /** @format uuid */
  id: string
  url: string
  /** @format date-time */
  createdAt: string
}

export interface MiFormContentRevisionResponse {
  /** @format uuid */
  id: string
  contentJson: string
  /** @format date-time */
  createdAt: string
}

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType
} from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'http://localhost:8091'
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path
    })
  }
}

/**
 * @title デジホス Core Backend API
 * @version 1.0.0
 * @baseUrl http://localhost:8091
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * @description 利用規約に同意します。
     *
     * @tags usersByUser
     * @name AgreeTermsByUser
     * @summary 患者利用規約同意
     * @request PUT:/api/user/v1/users/agree
     */
    agreeTermsByUser: (
      query: {
        /** @format uuid */
        userId: string
      },
      data: UsUserRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, object | UnexpectedException>({
        path: `/api/user/v1/users/agree`,
        method: 'PUT',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description 患者情報を確認します。
     *
     * @tags patientsByUser
     * @name ConfirmPatientByUser
     * @summary 患者情報の確認
     * @request PUT:/api/user/v1/medical-institutions/{medicalInstitutionId}/patient/confirm
     */
    confirmPatientByUser: (
      medicalInstitutionId: string,
      query: {
        /** @format uuid */
        userId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<void, object | UnexpectedException>({
        path: `/api/user/v1/medical-institutions/${medicalInstitutionId}/patient/confirm`,
        method: 'PUT',
        query: query,
        ...params
      }),

    /**
     * @description 入院オーダー書類セットのフォームコンテンツ取得します。
     *
     * @tags hospitalizationOrderSubmissionFormContentsByPar
     * @name HospitalizationOrderSubmissionFormContentByPar
     * @summary 入院オーダー書類セットの患者提出フォームコンテンツ取得
     * @request GET:/api/user/v1/medical-institutions/{medicalInstitutionId}/hospitalization-orders/{hospitalizationOrderId}/submission-form-content/{hospitalizationOrderDocumentSetContentId}
     */
    hospitalizationOrderSubmissionFormContentByPar: (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      hospitalizationOrderDocumentSetContentId: string,
      query: {
        /** @format uuid */
        userId: string
        /** @format uuid */
        representativeId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        ParHospitalizationOrderSubmissionFormContentResponse,
        object | UnexpectedException
      >({
        path: `/api/user/v1/medical-institutions/${medicalInstitutionId}/hospitalization-orders/${hospitalizationOrderId}/submission-form-content/${hospitalizationOrderDocumentSetContentId}`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 入院オーダー書類セットの患者提出フォームコンテンツ更新します。
     *
     * @tags hospitalizationOrderSubmissionFormContentsByPar
     * @name UpdateHospitalizationOrderSubmissionFormContentByPar
     * @summary 入院オーダー書類セット患者提出フォームコンテンツ更新
     * @request PUT:/api/user/v1/medical-institutions/{medicalInstitutionId}/hospitalization-orders/{hospitalizationOrderId}/submission-form-content/{hospitalizationOrderDocumentSetContentId}
     */
    updateHospitalizationOrderSubmissionFormContentByPar: (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      hospitalizationOrderDocumentSetContentId: string,
      query: {
        /** @format uuid */
        userId: string
        /** @format uuid */
        representativeId?: string
      },
      data: ParUpdateHospitalizationOrderSubmissionContentRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        ParHospitalizationOrderSubmissionFormContentResponse,
        object | UnexpectedException
      >({
        path: `/api/user/v1/medical-institutions/${medicalInstitutionId}/hospitalization-orders/${hospitalizationOrderId}/submission-form-content/${hospitalizationOrderDocumentSetContentId}`,
        method: 'PUT',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description 入院オーダーに紐づく患者情報を確認する
     *
     * @tags medicalInstitutionsByRepresentative
     * @name ConfirmPatientByRepresentative
     * @summary 患者情報確認
     * @request PUT:/api/representative/v1/medical-institutions/{medicalInstitutionId}/hospitalization-orders/{hospitalizationOrderId}/confirm
     */
    confirmPatientByRepresentative: (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      query: {
        /** @format uuid */
        userId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<void, object | UnexpectedException>({
        path: `/api/representative/v1/medical-institutions/${medicalInstitutionId}/hospitalization-orders/${hospitalizationOrderId}/confirm`,
        method: 'PUT',
        query: query,
        ...params
      }),

    /**
     * @description 医療機関の患者にユーザーを紐付けします。
     *
     * @tags userPatientsByMedicalInstitution
     * @name BindPatientByMedicalInstitution
     * @summary 医療機関の患者へユーザー紐付け
     * @request PUT:/api/medical-institutions/{medicalInstitutionId}/v1/patients/{patientId}/bind
     */
    bindPatientByMedicalInstitution: (
      medicalInstitutionId: string,
      patientId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      data: MiBindPatientRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/patients/${patientId}/bind`,
        method: 'PUT',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description 患者の代理人を入院オーダーに紐づけます。
     *
     * @tags representativesByMedicalInstitution
     * @name BindRepresentativeByMedicalInstitution
     * @summary 代理人の紐付け
     * @request PUT:/api/medical-institutions/{medicalInstitutionId}/v1/hospitalization-orders/{hospitalizationOrderId}/representatives/bind
     */
    bindRepresentativeByMedicalInstitution: (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      data: MiRepresentativeRequest,
      params: RequestParams = {}
    ) =>
      this.request<MiRepresentativeResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/hospitalization-orders/${hospitalizationOrderId}/representatives/bind`,
        method: 'PUT',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description 入院オーダー書類セットのフォームコンテンツ取得します。
     *
     * @tags hospitalizationOrderConfirmationFormContentsByMedicalInstitution
     * @name HospitalizationOrderConfirmationFormContentByMedicalInstitution
     * @summary 入院オーダー書類セットの修正・確定用フォームコンテンツ取得
     * @request GET:/api/medical-institutions/{medicalInstitutionId}/v1/hospitalization-orders/{hospitalizationOrderId}/confirmation-form-contents/{hospitalizationOrderDocumentSetContentId}
     */
    hospitalizationOrderConfirmationFormContentByMedicalInstitution: (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      hospitalizationOrderDocumentSetContentId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        MiHospitalizationOrderConfirmationFormContentResponse,
        object | UnexpectedException
      >({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/hospitalization-orders/${hospitalizationOrderId}/confirmation-form-contents/${hospitalizationOrderDocumentSetContentId}`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 入院オーダー書類セットのフォームコンテンツを更新します。
     *
     * @tags hospitalizationOrderConfirmationFormContentsByMedicalInstitution
     * @name UpdateHospitalizationOrderConfirmationFormContentByMedicalInstitution
     * @summary 入院オーダー書類セットの修正・確定用フォームコンテンツを修正する
     * @request PUT:/api/medical-institutions/{medicalInstitutionId}/v1/hospitalization-orders/{hospitalizationOrderId}/confirmation-form-contents/{hospitalizationOrderDocumentSetContentId}
     */
    updateHospitalizationOrderConfirmationFormContentByMedicalInstitution: (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      hospitalizationOrderDocumentSetContentId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      data: MiUpdateHospitalizationOrderConfirmationFormContentRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        MiHospitalizationOrderConfirmationFormContentResponse,
        object | UnexpectedException
      >({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/hospitalization-orders/${hospitalizationOrderId}/confirmation-form-contents/${hospitalizationOrderDocumentSetContentId}`,
        method: 'PUT',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description ユーザー情報を取得します。
     *
     * @tags usersByUser
     * @name UserByUser
     * @summary ユーザー情報取得
     * @request GET:/api/user/v1/users
     */
    userByUser: (
      query: {
        /** @format uuid */
        userId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<UsUserResponse, object | UnexpectedException>({
        path: `/api/user/v1/users`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description ユーザーを作成します。
     *
     * @tags usersByUser
     * @name CreateUserByUser
     * @summary ユーザー作成
     * @request POST:/api/user/v1/users
     */
    createUserByUser: (data: UsUserRequest, params: RequestParams = {}) =>
      this.request<UsCreatedUserResponse, object | UnexpectedException>({
        path: `/api/user/v1/users`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description ユーザーを作成します。
     *
     * @tags medicalInstitutionBindingTokensByUser
     * @name CreateMedicalInstitutionBindingTokenByUser
     * @summary 医療機関紐付け用トークン作成
     * @request POST:/api/user/v1/medical-institution-binding-tokens
     */
    createMedicalInstitutionBindingTokenByUser: (
      query: {
        /** @format uuid */
        userId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<UsUserMedicalInstitutionBindingTokenResponse, object | UnexpectedException>({
        path: `/api/user/v1/medical-institution-binding-tokens`,
        method: 'POST',
        query: query,
        ...params
      }),

    /**
     * @description 医療機関を全て取得します。
     *
     * @tags medicalInstitutionsByServiceProvider
     * @name MedicalInstitutionByServiceProvider
     * @summary 医療機関一覧取得
     * @request GET:/api/service-providers/{serviceProviderId}/v1/medical-institutions
     */
    medicalInstitutionByServiceProvider: (
      serviceProviderId: string,
      query: {
        spToken: string
      },
      params: RequestParams = {}
    ) =>
      this.request<SpMedicalInstitutionListResponse, object | UnexpectedException>({
        path: `/api/service-providers/${serviceProviderId}/v1/medical-institutions`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 医療機関を作成します。
     *
     * @tags medicalInstitutionsByServiceProvider
     * @name CreateMedicalInstitutionByServiceProvider
     * @summary 医療機関作成
     * @request POST:/api/service-providers/{serviceProviderId}/v1/medical-institutions
     */
    createMedicalInstitutionByServiceProvider: (
      serviceProviderId: string,
      data: SpCreateMedicalInstitutionRequest,
      params: RequestParams = {}
    ) =>
      this.request<SpMedicalInstitutionResponse, object | UnexpectedException>({
        path: `/api/service-providers/${serviceProviderId}/v1/medical-institutions`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description ログイン中の医療機関ユーザーを所属する企業に対して作成します。
     *
     * @tags profileByMedicalWorker
     * @name CreateMedicalWorkerByMedicalWorker
     * @summary ログイン中の医療機関ユーザー作成
     * @request POST:/api/medical-worker/v1/profile
     */
    createMedicalWorkerByMedicalWorker: (
      query: {
        optimIdToken: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MwProfileResponse, object | UnexpectedException>({
        path: `/api/medical-worker/v1/profile`,
        method: 'POST',
        query: query,
        ...params
      }),

    /**
     * @description 患者一覧を取得します。
     *
     * @tags patientsByMedicalInstitution
     * @name PatientsByMedicalInstitution
     * @summary 患者一覧
     * @request GET:/api/medical-institutions/{medicalInstitutionId}/v1/patients
     */
    patientsByMedicalInstitution: (
      medicalInstitutionId: string,
      query: {
        /** @format uuid */
        optimId: string
        patientNo?: string
        name?: string
        hospitalizationOrderStatus?: string
        /** @format uuid */
        lastPatientId?: string
        /** @default "id" */
        orderBy?: string
        /** @default "DESC" */
        order?: string
        /**
         * @format int32
         * @default 50
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MiPatientListResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/patients`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 医療機関の患者を作成します。
     *
     * @tags patientsByMedicalInstitution
     * @name CreatePatientByMedicalInstitution
     * @summary 医療機関の患者作成
     * @request POST:/api/medical-institutions/{medicalInstitutionId}/v1/patients
     */
    createPatientByMedicalInstitution: (
      medicalInstitutionId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      data: MiCreatePatientRequest,
      params: RequestParams = {}
    ) =>
      this.request<MiPatientResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/patients`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description 患者詳細を取得します。
     *
     * @tags patientsByMedicalInstitution
     * @name PatientByMedicalInstitution
     * @summary 患者詳細
     * @request GET:/api/medical-institutions/{medicalInstitutionId}/v1/patients/{patientId}
     */
    patientByMedicalInstitution: (
      medicalInstitutionId: string,
      patientId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MiPatientResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/patients/${patientId}`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 医療機関の患者を更新します。
     *
     * @tags patientsByMedicalInstitution
     * @name UpdatePatientByMedicalInstitution
     * @summary 医療機関の患者更新
     * @request POST:/api/medical-institutions/{medicalInstitutionId}/v1/patients/{patientId}
     */
    updatePatientByMedicalInstitution: (
      medicalInstitutionId: string,
      patientId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      data: MiUpdatePatientRequest,
      params: RequestParams = {}
    ) =>
      this.request<MiPatientResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/patients/${patientId}`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description 医療機関の患者を削除します。
     *
     * @tags patientsByMedicalInstitution
     * @name DestroyPatientByMedicalInstitution
     * @summary 医療機関の患者削除
     * @request DELETE:/api/medical-institutions/{medicalInstitutionId}/v1/patients/{patientId}
     */
    destroyPatientByMedicalInstitution: (
      medicalInstitutionId: string,
      patientId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<void, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/patients/${patientId}`,
        method: 'DELETE',
        query: query,
        ...params
      }),

    /**
     * @description 医療機関ユーザー一覧を取得します。
     *
     * @tags medicalWorkersByMedicalInstitution
     * @name MedicalWorkersByMedicalInstitution
     * @summary 医療機関ユーザー一覧
     * @request GET:/api/medical-institutions/{medicalInstitutionId}/v1/medical-workers
     */
    medicalWorkersByMedicalInstitution: (
      medicalInstitutionId: string,
      query: {
        /** @format uuid */
        optimId: string
        name?: string
        /** @format uuid */
        lastMedicalWorkerId?: string
        /** @default "id" */
        orderBy?: string
        /** @default "DESC" */
        order?: string
        /**
         * @format int32
         * @default 50
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MiMedicalWorkerListResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/medical-workers`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 医療機関ユーザーを作成します。
     *
     * @tags medicalWorkersByMedicalInstitution
     * @name CreateMedicalWorkerByMedicalInstitution
     * @summary 医療機関ユーザー作成
     * @request POST:/api/medical-institutions/{medicalInstitutionId}/v1/medical-workers
     */
    createMedicalWorkerByMedicalInstitution: (
      medicalInstitutionId: string,
      query: {
        /** @format uuid */
        optimId: string
        optimIdToken: string
      },
      data: MiCreateMedicalWorkerRequest,
      params: RequestParams = {}
    ) =>
      this.request<MiMedicalWorkerResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/medical-workers`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description 医療機関ユーザーを取得します。
     *
     * @tags medicalWorkersByMedicalInstitution
     * @name MedicalWorkerByMedicalInstitution
     * @summary 医療機関ユーザー詳細
     * @request GET:/api/medical-institutions/{medicalInstitutionId}/v1/medical-workers/{medicalWorkerId}
     */
    medicalWorkerByMedicalInstitution: (
      medicalInstitutionId: string,
      medicalWorkerId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MiMedicalWorkerResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/medical-workers/${medicalWorkerId}`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 医療機関ユーザーを更新します。
     *
     * @tags medicalWorkersByMedicalInstitution
     * @name UpdateMedicalWorkerByMedicalInstitution
     * @summary 医療機関ユーザー更新
     * @request POST:/api/medical-institutions/{medicalInstitutionId}/v1/medical-workers/{medicalWorkerId}
     */
    updateMedicalWorkerByMedicalInstitution: (
      medicalInstitutionId: string,
      medicalWorkerId: string,
      query: {
        /** @format uuid */
        optimId: string
        optimIdToken: string
      },
      data: MiUpdateMedicalWorkerRequest,
      params: RequestParams = {}
    ) =>
      this.request<MiMedicalWorkerResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/medical-workers/${medicalWorkerId}`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description 医療機関ユーザーを削除します。
     *
     * @tags medicalWorkersByMedicalInstitution
     * @name DestroyMedicalWorkerByMedicalInstitution
     * @summary 医療機関ユーザー削除
     * @request DELETE:/api/medical-institutions/{medicalInstitutionId}/v1/medical-workers/{medicalWorkerId}
     */
    destroyMedicalWorkerByMedicalInstitution: (
      medicalInstitutionId: string,
      medicalWorkerId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<void, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/medical-workers/${medicalWorkerId}`,
        method: 'DELETE',
        query: query,
        ...params
      }),

    /**
     * @description 入院オーダー一覧を取得します。
     *
     * @tags hospitalizationOrdersByMedicalInstitution
     * @name HospitalizationOrdersByMedicalInstitution
     * @summary 入院オーダー一覧
     * @request GET:/api/medical-institutions/{medicalInstitutionId}/v1/hospitalization-orders
     */
    hospitalizationOrdersByMedicalInstitution: (
      medicalInstitutionId: string,
      query: {
        /** @format uuid */
        optimId: string
        patientNo?: string
        name?: string
        useAppStatus?: string
        hospitalizationOrderStatus?: string
        documentSetStatus?: string
        /** @format uuid */
        lastHospitalizationOrderId?: string
        /** @default "id" */
        orderBy?: string
        /** @default "DESC" */
        order?: string
        /**
         * @format int32
         * @default 50
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MiHospitalizationOrderListResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/hospitalization-orders`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 入院オーダーを作成します。
     *
     * @tags hospitalizationOrdersByMedicalInstitution
     * @name CreateHospitalizationOrdersByMedicalInstitution
     * @summary 入院オーダー作成
     * @request POST:/api/medical-institutions/{medicalInstitutionId}/v1/hospitalization-orders
     */
    createHospitalizationOrdersByMedicalInstitution: (
      medicalInstitutionId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      data: MiFormHospitalizationOrderRequest,
      params: RequestParams = {}
    ) =>
      this.request<MiHospitalizationOrderResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/hospitalization-orders`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description 入院オーダー書類セット一覧を取得します。
     *
     * @tags hospitalizationOrderDocumentSetsByMedicalInstitution
     * @name HospitalizationOrderDocumentSetsByMedicalInstitution
     * @summary 入院オーダー書類セット一覧
     * @request GET:/api/medical-institutions/{medicalInstitutionId}/v1/hospitalization-orders/{hospitalizationOrderId}/document-sets
     */
    hospitalizationOrderDocumentSetsByMedicalInstitution: (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      query: {
        /** @format uuid */
        optimId: string
        contentTypes?: string[]
      },
      params: RequestParams = {}
    ) =>
      this.request<MiHospitalizationOrderDocumentSetListResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/hospitalization-orders/${hospitalizationOrderId}/document-sets`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 入院オーダーに書類を紐付け患者に送付します。
     *
     * @tags hospitalizationOrderDocumentSetsByMedicalInstitution
     * @name AddHospitalizationOrderDocumentSetsByMedicalInstitution
     * @summary 入院オーダー書類セット追加
     * @request POST:/api/medical-institutions/{medicalInstitutionId}/v1/hospitalization-orders/{hospitalizationOrderId}/document-sets
     */
    addHospitalizationOrderDocumentSetsByMedicalInstitution: (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      data: MiAddHospitalizationOrderDocumentSetsRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/hospitalization-orders/${hospitalizationOrderId}/document-sets`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description 入院オーダー書類セットのフォームコンテンツを確定します。
     *
     * @tags hospitalizationOrderConfirmationFormContentsByMedicalInstitution
     * @name ConfirmHospitalizationOrderConfirmationFormContentByMedicalInstitution
     * @summary 入院オーダー書類セットの修正・確定用フォームコンテンツを確定する
     * @request POST:/api/medical-institutions/{medicalInstitutionId}/v1/hospitalization-orders/{hospitalizationOrderId}/confirmation-form-contents/{hospitalizationOrderDocumentSetContentId}/confirm
     */
    confirmHospitalizationOrderConfirmationFormContentByMedicalInstitution: (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      hospitalizationOrderDocumentSetContentId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<void, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/hospitalization-orders/${hospitalizationOrderId}/confirmation-form-contents/${hospitalizationOrderDocumentSetContentId}/confirm`,
        method: 'POST',
        query: query,
        ...params
      }),

    /**
     * @description 特定医療機関の書類セット一覧を取得します。
     *
     * @tags documentSetsByMedicalInstitution
     * @name DocumentSetsByMedicalInstitution
     * @summary 書類セット一覧
     * @request GET:/api/medical-institutions/{medicalInstitutionId}/v1/document-sets
     */
    documentSetsByMedicalInstitution: (
      medicalInstitutionId: string,
      query: {
        /** @format uuid */
        optimId: string
        title?: string
        /** @format uuid */
        lastDocumentSetId?: string
        /** @default "updatedAt" */
        orderBy?: string
        /** @default "DESC" */
        order?: string
        /**
         * @format int32
         * @default 50
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MiDocumentSetListResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/document-sets`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 書類セットを作成します。
     *
     * @tags documentSetsByMedicalInstitution
     * @name CreateDocumentSetByMedicalInstitution
     * @summary 書類セット作成
     * @request POST:/api/medical-institutions/{medicalInstitutionId}/v1/document-sets
     */
    createDocumentSetByMedicalInstitution: (
      medicalInstitutionId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      data: MiFormDocumentSetRequest,
      params: RequestParams = {}
    ) =>
      this.request<MiDocumentSetSlimResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/document-sets`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description 特定医療機関のコンテンツを全て取得します。
     *
     * @tags contentsByMedicalInstitution
     * @name ContentsByMedicalInstitution
     * @summary コンテンツ一覧取得
     * @request GET:/api/medical-institutions/{medicalInstitutionId}/v1/contents
     */
    contentsByMedicalInstitution: (
      medicalInstitutionId: string,
      query: {
        /** @format uuid */
        optimId: string
        title?: string
        tag?: string
        /** @format uuid */
        lastContentId?: string
        /** @default "updatedAt" */
        orderBy?: string
        /** @default "DESC" */
        order?: string
        /**
         * @format int32
         * @default 50
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MiContentListResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/contents`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description コンテンツを作成します。
     *
     * @tags contentsByMedicalInstitution
     * @name CreateContentByMedicalInstitution
     * @summary コンテンツ作成
     * @request POST:/api/medical-institutions/{medicalInstitutionId}/v1/contents
     */
    createContentByMedicalInstitution: (
      medicalInstitutionId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      data: MiContentRequest,
      params: RequestParams = {}
    ) =>
      this.request<MiContentCreateResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/contents`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description 医療機関の利用規約に同意します。
     *
     * @tags medicalInstitutionAgreedTermsByUser
     * @name MedicalInstitutionAgreeTermsByUser
     * @summary 医療機関の利用規約同意
     * @request PATCH:/api/user/v1/medical-institutions/{medicalInstitutionId}/terms/{medicalInstitutionTermsId}
     */
    medicalInstitutionAgreeTermsByUser: (
      medicalInstitutionId: string,
      medicalInstitutionTermsId: string,
      query: {
        /** @format uuid */
        userId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<void, object | UnexpectedException>({
        path: `/api/user/v1/medical-institutions/${medicalInstitutionId}/terms/${medicalInstitutionTermsId}`,
        method: 'PATCH',
        query: query,
        ...params
      }),

    /**
     * @description 医療機関を取得します。
     *
     * @tags medicalInstitutionsByServiceProvider
     * @name MedicalInstitutionByServiceProvider1
     * @summary 医療機関詳細
     * @request GET:/api/service-providers/{serviceProviderId}/v1/medical-institutions/{medicalInstitutionId}
     */
    medicalInstitutionByServiceProvider1: (
      serviceProviderId: string,
      medicalInstitutionId: string,
      params: RequestParams = {}
    ) =>
      this.request<SpMedicalInstitutionResponse, object | UnexpectedException>({
        path: `/api/service-providers/${serviceProviderId}/v1/medical-institutions/${medicalInstitutionId}`,
        method: 'GET',
        ...params
      }),

    /**
     * @description 医療機関を更新します。
     *
     * @tags medicalInstitutionsByServiceProvider
     * @name UpdateMedicalInstitutionByServiceProvider
     * @summary 医療機関更新
     * @request PATCH:/api/service-providers/{serviceProviderId}/v1/medical-institutions/{medicalInstitutionId}
     */
    updateMedicalInstitutionByServiceProvider: (
      medicalInstitutionId: string,
      serviceProviderId: string,
      data: SpUpdateMedicalInstitutionRequest,
      params: RequestParams = {}
    ) =>
      this.request<SpMedicalInstitutionResponse, object | UnexpectedException>({
        path: `/api/service-providers/${serviceProviderId}/v1/medical-institutions/${medicalInstitutionId}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description 入院オーダー詳細を取得します。
     *
     * @tags hospitalizationOrdersByMedicalInstitution
     * @name HospitalizationOrderByMedicalInstitution
     * @summary 入院オーダー詳細
     * @request GET:/api/medical-institutions/{medicalInstitutionId}/v1/hospitalization-orders/{hospitalizationOrderId}
     */
    hospitalizationOrderByMedicalInstitution: (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MiHospitalizationOrderResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/hospitalization-orders/${hospitalizationOrderId}`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 入院オーダーを削除します。
     *
     * @tags hospitalizationOrdersByMedicalInstitution
     * @name DestroyHospitalizationOrderByMedicalInstitution
     * @summary 入院オーダー削除
     * @request DELETE:/api/medical-institutions/{medicalInstitutionId}/v1/hospitalization-orders/{hospitalizationOrderId}
     */
    destroyHospitalizationOrderByMedicalInstitution: (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<void, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/hospitalization-orders/${hospitalizationOrderId}`,
        method: 'DELETE',
        query: query,
        ...params
      }),

    /**
     * @description 入院オーダーを更新します。
     *
     * @tags hospitalizationOrdersByMedicalInstitution
     * @name UpdateHospitalizationOrdersByMedicalInstitution
     * @summary 入院オーダー更新
     * @request PATCH:/api/medical-institutions/{medicalInstitutionId}/v1/hospitalization-orders/{hospitalizationOrderId}
     */
    updateHospitalizationOrdersByMedicalInstitution: (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      data: MiUpdateHospitalizationOrderRequest,
      params: RequestParams = {}
    ) =>
      this.request<MiHospitalizationOrderResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/hospitalization-orders/${hospitalizationOrderId}`,
        method: 'PATCH',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description 特定医療機関の書類セットを取得します。
     *
     * @tags documentSetsByMedicalInstitution
     * @name DocumentSetByMedicalInstitution
     * @summary 書類セット詳細
     * @request GET:/api/medical-institutions/{medicalInstitutionId}/v1/document-sets/{documentSetId}
     */
    documentSetByMedicalInstitution: (
      medicalInstitutionId: string,
      documentSetId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MiDocumentSetResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/document-sets/${documentSetId}`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 書類セットを削除します。
     *
     * @tags documentSetsByMedicalInstitution
     * @name DestroyDocumentSetByMedicalInstitution
     * @summary 書類セット削除
     * @request DELETE:/api/medical-institutions/{medicalInstitutionId}/v1/document-sets/{documentSetId}
     */
    destroyDocumentSetByMedicalInstitution: (
      medicalInstitutionId: string,
      documentSetId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<void, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/document-sets/${documentSetId}`,
        method: 'DELETE',
        query: query,
        ...params
      }),

    /**
     * @description 書類セットを更新します。
     *
     * @tags documentSetsByMedicalInstitution
     * @name UpdateDocumentSetByMedicalInstitution
     * @summary 書類セット更新
     * @request PATCH:/api/medical-institutions/{medicalInstitutionId}/v1/document-sets/{documentSetId}
     */
    updateDocumentSetByMedicalInstitution: (
      medicalInstitutionId: string,
      documentSetId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      data: MiFormDocumentSetRequest,
      params: RequestParams = {}
    ) =>
      this.request<MiDocumentSetSlimResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/document-sets/${documentSetId}`,
        method: 'PATCH',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description コンテンツを取得します。Revisionは含まれないため別途取得が必要です。
     *
     * @tags contentsByMedicalInstitution
     * @name ContentByMedicalInstitution
     * @summary コンテンツ取得
     * @request GET:/api/medical-institutions/{medicalInstitutionId}/v1/contents/{contentId}
     */
    contentByMedicalInstitution: (
      medicalInstitutionId: string,
      contentId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MiContentResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/contents/${contentId}`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description コンテンツを削除します。
     *
     * @tags contentsByMedicalInstitution
     * @name DestroyContentByMedicalInstitution
     * @summary コンテンツ削除
     * @request DELETE:/api/medical-institutions/{medicalInstitutionId}/v1/contents/{contentId}
     */
    destroyContentByMedicalInstitution: (
      medicalInstitutionId: string,
      contentId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<void, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/contents/${contentId}`,
        method: 'DELETE',
        query: query,
        ...params
      }),

    /**
     * @description コンテンツを更新します。
     *
     * @tags contentsByMedicalInstitution
     * @name UpdateContentByMedicalInstitution
     * @summary コンテンツ更新
     * @request PATCH:/api/medical-institutions/{medicalInstitutionId}/v1/contents/{contentId}
     */
    updateContentByMedicalInstitution: (
      medicalInstitutionId: string,
      contentId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      data: MiContentRequest,
      params: RequestParams = {}
    ) =>
      this.request<MiContentUpdateResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/contents/${contentId}`,
        method: 'PATCH',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description core-backendのバージョン情報を返します
     *
     * @tags appVersion
     * @name GetCoreBackendAppVersion
     * @summary core-backendのバージョン情報取得
     * @request GET:/api/version
     */
    getCoreBackendAppVersion: (params: RequestParams = {}) =>
      this.request<CoreBackendAppVersionResponse, object | UnexpectedException>({
        path: `/api/version`,
        method: 'GET',
        ...params
      }),

    /**
     * @description 利用規約URLを取得します。
     *
     * @tags digihos-terms-controller
     * @name GetTermsUrl
     * @summary 患者アプリ利用規約URL取得
     * @request GET:/api/user/v1/terms
     */
    getTermsUrl: (params: RequestParams = {}) =>
      this.request<DigihosTermsResponse, object | UnexpectedException>({
        path: `/api/user/v1/terms`,
        method: 'GET',
        ...params
      }),

    /**
     * @description 医療機関を全て取得します。
     *
     * @tags medicalInstitutionsByUser
     * @name MedicalInstitutionsByUser
     * @summary 医療機関一覧取得
     * @request GET:/api/user/v1/medical-institutions
     */
    medicalInstitutionsByUser: (
      query: {
        /** @format uuid */
        userId: string
        /** @format uuid */
        bindingTokenId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<UsMedicalInstitutionListResponse, object | UnexpectedException>({
        path: `/api/user/v1/medical-institutions`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 医療機関を取得します。
     *
     * @tags medicalInstitutionsByUser
     * @name MedicalInstitutionByUser
     * @summary 医療機関取得
     * @request GET:/api/user/v1/medical-institutions/{medicalInstitutionId}
     */
    medicalInstitutionByUser: (
      medicalInstitutionId: string,
      query: {
        /** @format uuid */
        userId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<UsMedicalInstitutionResponse, object | UnexpectedException>({
        path: `/api/user/v1/medical-institutions/${medicalInstitutionId}`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 患者情報を取得します。
     *
     * @tags patientsByUser
     * @name PatientByUser
     * @summary 患者取得
     * @request GET:/api/user/v1/medical-institutions/{medicalInstitutionId}/patient
     */
    patientByUser: (
      medicalInstitutionId: string,
      query: {
        /** @format uuid */
        userId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<UsPatientResponse, object | UnexpectedException>({
        path: `/api/user/v1/medical-institutions/${medicalInstitutionId}/patient`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 入院オーダー書類セットのURLコンテンツリビジョン取得します。
     *
     * @tags urlContentRevisionsByPar
     * @name HospitalizationOrderDocumentSetUrlContentRevisionByPar
     * @summary 入院オーダー書類セットのURLコンテンツリビジョンを取得
     * @request GET:/api/user/v1/medical-institutions/{medicalInstitutionId}/hospitalization-orders/{hospitalizationOrderId}/url-content-revisions/{urlContentRevisionId}
     */
    hospitalizationOrderDocumentSetUrlContentRevisionByPar: (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      urlContentRevisionId: string,
      query: {
        /** @format uuid */
        userId: string
        /** @format uuid */
        representativeId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ParUrlContentRevisionResponse, object | UnexpectedException>({
        path: `/api/user/v1/medical-institutions/${medicalInstitutionId}/hospitalization-orders/${hospitalizationOrderId}/url-content-revisions/${urlContentRevisionId}`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 入院オーダー書類セットのフォームコンテンツリビジョン取得します。
     *
     * @tags formContentRevisionsByPar
     * @name HospitalizationOrderDocumentSetFormContentRevisionByPar
     * @summary 入院オーダー書類セットのフォームコンテンツリビジョンを取得
     * @request GET:/api/user/v1/medical-institutions/{medicalInstitutionId}/hospitalization-orders/{hospitalizationOrderId}/form-content-revisions/{formContentRevisionId}
     */
    hospitalizationOrderDocumentSetFormContentRevisionByPar: (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      formContentRevisionId: string,
      query: {
        /** @format uuid */
        userId: string
        /** @format uuid */
        representativeId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ParFormContentRevisionResponse, object | UnexpectedException>({
        path: `/api/user/v1/medical-institutions/${medicalInstitutionId}/hospitalization-orders/${hospitalizationOrderId}/form-content-revisions/${formContentRevisionId}`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 入院オーダー書類セットコンテンツの一覧取得します。
     *
     * @tags hospitalizationOrderDocumentSetsByPar
     * @name HospitalizationOrderDocumentSetsByPar
     * @summary 入院オーダー書類セット一覧取得
     * @request GET:/api/user/v1/medical-institutions/{medicalInstitutionId}/hospitalization-orders/{hospitalizationOrderId}/document-sets
     */
    hospitalizationOrderDocumentSetsByPar: (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      query: {
        /** @format uuid */
        userId: string
        /** @format uuid */
        representativeId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ParHospitalizationOrderDocumentSetListResponse, object | UnexpectedException>({
        path: `/api/user/v1/medical-institutions/${medicalInstitutionId}/hospitalization-orders/${hospitalizationOrderId}/document-sets`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 入院オーダー書類セットコンテンツ取得します。
     *
     * @tags hospitalizationOrderDocumentSetContentsByPar
     * @name HospitalizationOrderDocumentSetContentsByPar
     * @summary 入院オーダー書類セットコンテンツ一覧取得
     * @request GET:/api/user/v1/medical-institutions/{medicalInstitutionId}/hospitalization-orders/{hospitalizationOrderId}/document-sets/{hospitalizationOrderDocumentSetId}/contents
     */
    hospitalizationOrderDocumentSetContentsByPar: (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      hospitalizationOrderDocumentSetId: string,
      query: {
        /** @format uuid */
        userId: string
        /** @format uuid */
        representativeId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ParHospitalizationOrderContentListResponse, object | UnexpectedException>({
        path: `/api/user/v1/medical-institutions/${medicalInstitutionId}/hospitalization-orders/${hospitalizationOrderId}/document-sets/${hospitalizationOrderDocumentSetId}/contents`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 入院オーダー書類セットコンテンツ取得します。
     *
     * @tags hospitalizationOrderDocumentSetContentsByPar
     * @name HospitalizationOrderDocumentSetContentByPar
     * @summary 入院オーダー書類セットコンテンツ取得
     * @request GET:/api/user/v1/medical-institutions/{medicalInstitutionId}/hospitalization-orders/{hospitalizationOrderId}/document-sets/{hospitalizationOrderDocumentSetId}/contents/{hospitalizationOrderDocumentSetContentId}
     */
    hospitalizationOrderDocumentSetContentByPar: (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      hospitalizationOrderDocumentSetId: string,
      hospitalizationOrderDocumentSetContentId: string,
      query: {
        /** @format uuid */
        userId: string
        /** @format uuid */
        representativeId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ParHospitalizationOrderContentResponse, object | UnexpectedException>({
        path: `/api/user/v1/medical-institutions/${medicalInstitutionId}/hospitalization-orders/${hospitalizationOrderId}/document-sets/${hospitalizationOrderDocumentSetId}/contents/${hospitalizationOrderDocumentSetContentId}`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 入院オーダー最新の詳細を取得します。
     *
     * @tags hospitalizationOrdersByPar
     * @name LatestHospitalizationOrderByPar
     * @summary 入院オーダー最新詳細取得
     * @request GET:/api/user/v1/medical-institutions/{medicalInstitutionId}/hospitalization-orders/latest
     */
    latestHospitalizationOrderByPar: (
      medicalInstitutionId: string,
      query: {
        /** @format uuid */
        userId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ParHospitalizationOrderResponse, object | UnexpectedException>({
        path: `/api/user/v1/medical-institutions/${medicalInstitutionId}/hospitalization-orders/latest`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description ユーザーを認証します。
     *
     * @tags usersByUser
     * @name AuthByUser
     * @summary ユーザー認証
     * @request GET:/api/user/v1/auth
     */
    authByUser: (params: RequestParams = {}) =>
      this.request<void, object | UnexpectedException>({
        path: `/api/user/v1/auth`,
        method: 'GET',
        ...params
      }),

    /**
     * @description 医療機関を全て取得します。
     *
     * @tags medicalInstitutionsByRepresentative
     * @name MedicalInstitutionsByRepresentative
     * @summary 医療機関一覧取得
     * @request GET:/api/representative/v1/medical-institutions
     */
    medicalInstitutionsByRepresentative: (
      query: {
        /** @format uuid */
        userId: string
        /** @format uuid */
        bindingTokenId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<RtMedicalInstitutionListResponse, object | UnexpectedException>({
        path: `/api/representative/v1/medical-institutions`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 医療機関を取得します。
     *
     * @tags medicalInstitutionsByRepresentative
     * @name MedicalInstitutionByRepresentative
     * @summary 医療機関詳細取得
     * @request GET:/api/representative/v1/medical-institutions/{medicalInstitutionId}
     */
    medicalInstitutionByRepresentative: (
      medicalInstitutionId: string,
      query: {
        /** @format uuid */
        userId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<RtMedicalInstitutionResponse, object | UnexpectedException>({
        path: `/api/representative/v1/medical-institutions/${medicalInstitutionId}`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description ログイン中の医療機関ユーザーが所属する企業の一覧を取得します。
     *
     * @tags medicalInstitutionsByMedicalWorker
     * @name MedicalInstitutionsByMedicalWorker
     * @summary ログイン中の医療機関ユーザーの医療機関一覧取得
     * @request GET:/api/medical-worker/v1/medical-institutions
     */
    medicalInstitutionsByMedicalWorker: (
      query: {
        /** @format uuid */
        optimId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MwMedicalInstitutionListResponse, object | UnexpectedException>({
        path: `/api/medical-worker/v1/medical-institutions`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 医療機関を取得します。
     *
     * @tags medicalInstitutionsByMedicalInstitution
     * @name MedicalInstitutionByMedicalInstitution
     * @summary 医療機関詳細
     * @request GET:/api/medical-institutions/{medicalInstitutionId}/v1/profile
     */
    medicalInstitutionByMedicalInstitution: (
      medicalInstitutionId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MiMedicalInstitutionResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/profile`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 医療機関の患者に紐づいているユーザーを取得します
     *
     * @tags userPatientsByMedicalInstitution
     * @name UserPatientByMedicalInstitution
     * @summary 医療機関の患者へユーザー紐付け
     * @request GET:/api/medical-institutions/{medicalInstitutionId}/v1/patients/{patientId}/user
     */
    userPatientByMedicalInstitution: (
      medicalInstitutionId: string,
      patientId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MiUserPatientResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/patients/${patientId}/user`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 入院オーダー書類セットのフォームコンテンツ取得します。
     *
     * @tags hospitalizationOrderSubmissionFormContentsByMedicalInstitution
     * @name HospitalizationOrderSubmissionFormContentByMedicalInstitution
     * @summary 入院オーダー書類セットの患者提出フォームコンテンツ取得
     * @request GET:/api/medical-institutions/{medicalInstitutionId}/v1/hospitalization-orders/{hospitalizationOrderId}/submission-form-contents/{hospitalizationOrderDocumentSetContentId}
     */
    hospitalizationOrderSubmissionFormContentByMedicalInstitution: (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      hospitalizationOrderDocumentSetContentId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        MiHospitalizationOrderSubmissionFormContentResponse,
        object | UnexpectedException
      >({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/hospitalization-orders/${hospitalizationOrderId}/submission-form-contents/${hospitalizationOrderDocumentSetContentId}`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description 入院オーダー書類セットコンテンツを取得します。
     *
     * @tags hospitalizationOrderDocumentSetContentsByMedicalInstitution
     * @name HospitalizationOrderDocumentSetContentByMedicalInstitution
     * @summary 入院オーダー書類セットコンテンツ詳細
     * @request GET:/api/medical-institutions/{medicalInstitutionId}/v1/hospitalization-orders/{hospitalizationOrderId}/document-sets/{hospitalizationOrderDocumentSetId}/contents/{hospitalizationOrderDocumentSetContentId}
     */
    hospitalizationOrderDocumentSetContentByMedicalInstitution: (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      hospitalizationOrderDocumentSetId: string,
      hospitalizationOrderDocumentSetContentId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MiHospitalizationOrderDocumentSetContentResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/hospitalization-orders/${hospitalizationOrderId}/document-sets/${hospitalizationOrderDocumentSetId}/contents/${hospitalizationOrderDocumentSetContentId}`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description URLコンテンツリビジョンを取得します。
     *
     * @tags urlContentRevisionsByMedicalInstitution
     * @name UrlContentRevisionByMedicalInstitution
     * @summary URLコンテンツリビジョン取得
     * @request GET:/api/medical-institutions/{medicalInstitutionId}/v1/contents/{contentId}/url-content-revisions/{urlContentRevisionId}
     */
    urlContentRevisionByMedicalInstitution: (
      medicalInstitutionId: string,
      contentId: string,
      urlContentRevisionId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MiUrlContentRevisionResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/contents/${contentId}/url-content-revisions/${urlContentRevisionId}`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description URLコンテンツリビジョンを取得します。最新のリビジョンを返します。
     *
     * @tags urlContentRevisionsByMedicalInstitution
     * @name LatestUrlContentRevisionByMedicalInstitution
     * @summary URLコンテンツリビジョン取得
     * @request GET:/api/medical-institutions/{medicalInstitutionId}/v1/contents/{contentId}/url-content-revisions/latest
     */
    latestUrlContentRevisionByMedicalInstitution: (
      medicalInstitutionId: string,
      contentId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MiUrlContentRevisionResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/contents/${contentId}/url-content-revisions/latest`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description フォームコンテンツリビジョンを取得します。
     *
     * @tags formContentRevisionsByMedicalInstitution
     * @name FormContentRevisionByMedicalInstitution
     * @summary フォームコンテンツリビジョン取得
     * @request GET:/api/medical-institutions/{medicalInstitutionId}/v1/contents/{contentId}/form-content-revisions/{formContentRevisionId}
     */
    formContentRevisionByMedicalInstitution: (
      medicalInstitutionId: string,
      contentId: string,
      formContentRevisionId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MiFormContentRevisionResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/contents/${contentId}/form-content-revisions/${formContentRevisionId}`,
        method: 'GET',
        query: query,
        ...params
      }),

    /**
     * @description フォームコンテンツリビジョンを取得します。最新のリビジョンを返します。
     *
     * @tags formContentRevisionsByMedicalInstitution
     * @name LatestFormContentRevisionByMedicalInstitution
     * @summary フォームコンテンツリビジョン取得
     * @request GET:/api/medical-institutions/{medicalInstitutionId}/v1/contents/{contentId}/form-content-revisions/latest
     */
    latestFormContentRevisionByMedicalInstitution: (
      medicalInstitutionId: string,
      contentId: string,
      query: {
        /** @format uuid */
        optimId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MiFormContentRevisionResponse, object | UnexpectedException>({
        path: `/api/medical-institutions/${medicalInstitutionId}/v1/contents/${contentId}/form-content-revisions/latest`,
        method: 'GET',
        query: query,
        ...params
      })
  }
}
