<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import type { UsMedicalInstitutionResponse } from '@/api/generate/apiSchema'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import { getUserId } from '@/api/userDataCookie'
import { OnlyTextContentDialog } from '@/components/Dialog'
import MedicalInstitutionAgreement from '@/features/MedicalInstitutionAgreement/index.vue'
import router from '@/router'
import { useDisplayNameStore } from '@/stores/displayName'
import { useMedicalInstitutionTermsCheckStore } from '@/stores/medicalInstitutionTermsCheck'

const { t } = useI18n()
const route = useRoute()
const medicalInstitutionId = route.params.medicalInstitutionId as string

// 同意書類
const terms = ref<UsMedicalInstitutionResponse>()
const termsCheckStore = useMedicalInstitutionTermsCheckStore()

// 同意書を取得しユーザーの同意状態を確認する
const requiredAgreementTerms = async () => {
  const userId = getUserId()
  if (userId) {
    try {
      const response = await medicalInstitutionsApiClient().api.medicalInstitutionByUser(
        medicalInstitutionId,
        { userId: '' }
      )
      terms.value = response.data
    } catch (error) {
      router.replace('/not_found')
    }
  }
}

// モーダル開閉管理
const isOpenErrorDialog = ref<boolean>(false)

// サーバーエラーダイアログを開く
const openDialog = () => {
  isOpenErrorDialog.value = true
}

const closeDialog = async () => {
  // 利用規約IDが異なる場合にエラーとなるため、更新をかける
  await requiredAgreementTerms()
  termsCheckStore.reset()
  isOpenErrorDialog.value = false
  isAlreadySubmit.value = false
}

// 二重送信フラグ
const isAlreadySubmit = ref<boolean>(false)

// 同意送信
const onSubmit = async (): Promise<void> => {
  if (isAlreadySubmit.value) {
    return
  }

  const medicalInstitutionTermsId = terms.value?.medicalInstitutionTermsId
  if (medicalInstitutionTermsId) {
    try {
      await medicalInstitutionsApiClient().api.medicalInstitutionAgreeTermsByUser(
        medicalInstitutionId,
        medicalInstitutionTermsId,
        { userId: `${getUserId()}` }
      )

      // 成功した場合入院手続きホーム画面へ
      termsCheckStore.reset()
      isAlreadySubmit.value = false
      router.replace(`/medical-institutions/${medicalInstitutionId}`)
    } catch (error) {
      openDialog()
      throw error
    }
  }
}

onMounted(async () => {
  const displayNameStore = useDisplayNameStore()
  displayNameStore.updateDisplayName(undefined)

  termsCheckStore.initSetups(medicalInstitutionId)

  // 利用規約等の取得
  await requiredAgreementTerms()
})
</script>

<template>
  <div class="main-container">
    <div v-if="terms !== undefined && (terms === undefined || terms.termsAgreed === false)">
      <MedicalInstitutionAgreement :terms="terms" @submit="onSubmit"> </MedicalInstitutionAgreement>
    </div>

    <OnlyTextContentDialog
      :value="isOpenErrorDialog"
      :title="t('pages.serverError.title')"
      :content="
        t('errors.FailedRegistration', { resource: t('features.terms.agreementToTermsOfUse') })
      "
      cancelBtnDefault="center"
      :cancelBtnText="t('attributes.close')"
      :size="'medium'"
      :confirmBtnVisible="false"
      @input="closeDialog"
    />
  </div>
</template>

<style scoped lang="scss">
.main-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  --v-layout: initial;
  overflow: hidden;
}
</style>
