<script setup lang="ts">
import Typography from '@/components/Typography/Typography.vue'

// props初期化
const props = withDefaults(
  defineProps<{
    id: string
    sectionIndex: number
    title?: string
  }>(),
  {
    title: ''
  }
)
</script>

<template>
  <div class="section-container">
    <div class="section-inner-container">
      <div class="section-title-wrapper">
        <Typography tag="span" token-type="BODY_M">{{ props.title }}</Typography>
      </div>

      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
// セクションコンテナ
.section-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
// セクションインナーコンテナ
.section-inner-container {
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: rgb(var(--v-theme-primitive-white-DEFAULT-value));
}
// セクションタイトルラップ
.section-title-wrapper {
  margin: 0 0 8px 0;
}
</style>
