import { type RouteLocationNormalizedLoadedGeneric } from 'vue-router'

export const usePathParameters = (route: RouteLocationNormalizedLoadedGeneric) => {
  const medicalInstitutionId =
    typeof route.params.medicalInstitutionId == 'string' ? route.params.medicalInstitutionId : ''

  const hospitalizationOrderDocumentSetId =
    typeof route.params.hospitalizationOrderDocumentSetId == 'string'
      ? route.params.hospitalizationOrderDocumentSetId
      : ''

  const hospitalizationOrderDocumentSetContentId =
    typeof route.params.hospitalizationOrderDocumentSetContentId == 'string'
      ? route.params.hospitalizationOrderDocumentSetContentId
      : ''

  return {
    medicalInstitutionId,
    hospitalizationOrderDocumentSetId,
    hospitalizationOrderDocumentSetContentId
  }
}
