import type { ChipItem } from '@/components/Card/ChipCard/types'

import { ContentStatusCode, DocumentSetStatusCode } from '@/api/types'

export default function () {
  const getDocumentSetStatus = (documentSetStatus: DocumentSetStatusCode): ChipItem => {
    switch (documentSetStatus) {
      case 'not_submitted':
        return {
          label: 'attributes.documentSetStatus.notSubmitted',
          color: 'error'
        }
      case 'partial_submitted':
        return {
          label: 'attributes.documentSetStatus.partialSubmitted',
          color: 'warning-primary'
        }
      case 'submitted':
        return {
          label: 'attributes.documentSetStatus.submitted',
          color: 'success-primary'
        }
      case 'confirmed':
        return {
          label: 'attributes.documentSetStatus.confirmed',
          color: 'grey'
        }
    }
  }

  const getDocumentSetContentStatus = (
    contentStatusCode: ContentStatusCode | string
  ): ChipItem | undefined => {
    switch (contentStatusCode) {
      case 'not_submitted':
        return {
          label: 'attributes.documentSetContentStatus.notSubmitted',
          color: 'error'
        }
      case 'submitted':
        return {
          label: 'attributes.documentSetContentStatus.submitted',
          color: 'success-primary'
        }
      case 'confirmed':
        return {
          label: 'attributes.documentSetContentStatus.confirmed',
          color: 'grey'
        }
    }
  }

  return { getDocumentSetStatus, getDocumentSetContentStatus }
}
