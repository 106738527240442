<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import type { DocumentSetItem } from '@/features/DocumentSetList/type'

import { hospitalizationOrderApiClient } from '@/api/apiClient'
import DocumentSetList from '@/features/DocumentSetList/index.vue'
import { useDisplayNameStore } from '@/stores/displayName'
import { useHospitalizationOrderStore } from '@/stores/hospitalizationOrder'

const route = useRoute()
const hospitalizationOrderStore = useHospitalizationOrderStore()
const medicalInstitutionId = route.params.medicalInstitutionId as string

const hospitalizationDate = ref<string | undefined>()
const documentSetList = ref<DocumentSetItem[]>([])

onMounted(async () => {
  const displayNameStore = useDisplayNameStore()
  displayNameStore.updateDisplayName('pages.navigationTitles.hospitalizationDocumentsList')

  const hospitalizationOrder =
    await hospitalizationOrderStore.fetchHospitalizationOrderOrThrow(medicalInstitutionId)
  hospitalizationDate.value = hospitalizationOrder.hospitalizationDate
  await fetchDocumentSet(hospitalizationOrder.id, hospitalizationOrderStore.getRepresentativeId())
})

const fetchDocumentSet = async (hospitalizationOrderId: string, representativeId?: string) => {
  const response = await hospitalizationOrderApiClient().api.hospitalizationOrderDocumentSetsByPar(
    medicalInstitutionId,
    hospitalizationOrderId,
    { userId: '', representativeId }
  )
  documentSetList.value = response.data.list.map((item) => {
    return {
      id: item.id,
      documentSetId: item.documentSetId,
      title: item.title,
      description: item.description,
      documentSetStatus: item.documentSetStatus
    }
  })
}
</script>

<template>
  <v-container class="container">
    <DocumentSetList
      v-if="documentSetList"
      :medicalInstitutionId="medicalInstitutionId"
      :hospitalizationDate="hospitalizationDate"
      :documentSetList="documentSetList"
    />
  </v-container>
</template>

<style scoped lang="scss">
.container {
  height: 100%;
  padding: 16px;
}
</style>
