<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import { usePathParameters } from '@/composables/usePathParameters'
import EditFormContentSubmission from '@/features/FormContentSubmission/edit/index.vue'
import router from '@/router'
import { useDisplayNameStore } from '@/stores/displayName'
import { useHospitalizationOrderStore } from '@/stores/hospitalizationOrder'
import { useHospitalizationOrderFormContentStore } from '@/stores/hospitalizationOrderFormContent'

const pathParameters = usePathParameters(useRoute())
const hospitalizationOrderFormContentStore = useHospitalizationOrderFormContentStore()
const hospitalizationOrderStore = useHospitalizationOrderStore()

const medicalInstitutionId = pathParameters.medicalInstitutionId
const hospitalizationOrderId = hospitalizationOrderStore.getHospitalizationOrder()?.id ?? ''
const hospitalizationOrderDocumentSetId = pathParameters.hospitalizationOrderDocumentSetId
const hospitalizationOrderDocumentSetContentId =
  pathParameters.hospitalizationOrderDocumentSetContentId

const contentJson = ref<string>()
const isLoading = ref<boolean>(true)

onMounted(async () => {
  const displayNameStore = useDisplayNameStore()
  displayNameStore.updateDisplayName(undefined)

  hospitalizationOrderFormContentStore.clearHospitalizationOrderContent()

  const response = await hospitalizationOrderFormContentStore.fetchContentJson(
    medicalInstitutionId,
    hospitalizationOrderId,
    hospitalizationOrderDocumentSetId,
    hospitalizationOrderDocumentSetContentId,
    hospitalizationOrderStore.getRepresentativeId()
  )
  if (response.success) {
    contentJson.value = response.content
  } else {
    router.push('/not_found')
  }

  if (hospitalizationOrderFormContentStore.isSubmitted()) {
    // 既に回答済みの場合、コンテンツ詳細画面へ戻す
    router.replace({
      name: 'DGHP-006-06',
      params: {
        medicalInstitutionId,
        hospitalizationOrderDocumentSetId,
        hospitalizationOrderDocumentSetContentId
      }
    })
    return
  }

  isLoading.value = false
})
</script>

<template>
  <v-container>
    <div v-if="!isLoading && contentJson != undefined && hospitalizationOrderId != undefined">
      <EditFormContentSubmission
        :content-json="contentJson"
        :medical-institution-id="medicalInstitutionId"
        :hospitalization-order-id="hospitalizationOrderId"
        :hospitalization-order-document-set-id="hospitalizationOrderDocumentSetId"
        :hospitalization-order-document-set-content-id="hospitalizationOrderDocumentSetContentId"
      >
      </EditFormContentSubmission>
    </div>
  </v-container>
</template>

<style scoped lang="scss"></style>
