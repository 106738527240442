<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import QrCodeCard from './QrCodeCard/index.vue'

import type { MedicalInstitution } from './type'

import Label from '@/components/Base/Label/index.vue'
import ListCard from '@/components/Base/ListCard/index.vue'
import router from '@/router'

const { t } = useI18n()

type MedicalInstitutionList = MedicalInstitution[]

type Props = {
  medicalInstitutionList: MedicalInstitutionList
  bindingToken: string
}

const props = defineProps<Props>()

// 医療機関が選択された時
const transitionMedicalInstitutionDetail = (medicalInstitutionId: string) => {
  // 入院手続き画面へ遷移
  router.push({ path: `/medical-institutions/${medicalInstitutionId}` })
}
</script>
<template>
  <div>
    <div class="qr-code-card">
      <QrCodeCard :bindingToken="props.bindingToken" />
    </div>
    <div class="label-padding">
      <Label
        forHtml="content_title"
        :labelText="t('features.medicalInstitutionList.select')"
        :isRequired="false"
      ></Label>
    </div>
    <div class="medical-institution-list-content">
      <ListCard
        v-for="institution in props.medicalInstitutionList"
        :key="institution.id"
        :title="institution.name"
        @click="transitionMedicalInstitutionDetail(institution.id)"
      />
    </div>
  </div>
</template>

<style>
.qr-code-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.label-padding {
  padding-top: 16px;
  padding-bottom: 16px;
}
.card-container {
  padding-bottom: 16px;
}
.medical-institution-list-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
