// UUIDなどを統一して返すため、サンプル用のデータを管理するファイル
export const medicalInstitutions = [
  {
    id: '00000000-ffff-0000-ffff-000000000000',
    name: 'オプティム医療機関',
    createdAt: '2024-07-10T05:39:30Z',
    updatedAt: '2024-07-10T05:39:30Z'
  }
]

export const contents = {
  list: [
    {
      id: '0191b7ed-d37c-715f-a1f4-aa0aeadf0a8f',
      contentId: '0191b275-59d1-7026-aa1f-099777e98563',
      title: 'URLコンテンツサンプル',
      description: '説明説明説明説明説明説明説明説明説明説明',
      contentType: 2,
      contentRevisionId: '0191b275-59d1-7c83-bbe6-8e843f264e90',
      contentStatus: null
    },
    {
      id: '0191b7ed-d37e-7d31-96f9-4634505b7064',
      contentId: '0191b7dd-ae23-7482-9afa-29e2337ea2db',
      title: '未入力フォームコンテンツサンプル',
      description: 'テストテストテストテストテストテストテストテスト',
      contentType: 1,
      contentRevisionId: '0191b7dd-ae23-71be-96be-7e9388792753',
      contentStatus: 'not_submitted'
    },
    {
      id: '0191b7ed-d37e-7d31-96f9-4634505b7065',
      contentId: '0191b7dd-ae23-7482-9afa-29e2337ea2db',
      title: '途中保存フォームコンテンツサンプル',
      description: 'テストテストテストテストテストテスト',
      contentType: 1,
      contentRevisionId: '0191b7dd-ae23-71be-96be-7e9388792754',
      contentStatus: 'not_submitted'
    },
    {
      id: '0191b7ed-d37e-7d31-96f9-4634505b7066',
      contentId: '0191b7dd-ae23-7482-9afa-29e2337ea2db',
      title: '提出済フォームコンテンツサンプル',
      description: 'テストテストテストテストテストテストテストテスト',
      contentType: 1,
      contentRevisionId: '0191b7dd-ae23-71be-96be-7e9388792755',
      contentStatus: 'submitted'
    },
    {
      id: '0191b7ed-d37e-7d31-96f9-4634505b7067',
      contentId: '0191b7dd-ae23-7482-9afa-29e2337ea2db',
      title: '確定済フォームコンテンツサンプル',
      description: 'テストテストテストテストテストテストテストテストテストテスト',
      contentType: 1,
      contentRevisionId: '0191b7dd-ae23-71be-96be-7e9388792756',
      contentStatus: 'confirmed'
    }
  ]
}

const cleanContent = [
  {
    id: '0b0c613f-9965-49b1-bd66-e4246316e927',
    name: 'セクション1',
    options: [
      {
        id: '3dfeef7c-4d47-4cb0-b80d-6e954e505976',
        name: '単一行テキスト',
        type: 'text',
        isRequired: true,
        description: '説明説明説明説明説明説明説明説明',
        isMultiLine: false
      },
      {
        id: 'ede7f816-2894-4be0-a007-0d14c35e27c0',
        name: '複数行テキスト',
        type: 'text',
        isRequired: false,
        description: '説明説明説明説明説明説明説明説明',
        isMultiLine: true
      },
      {
        id: '54cb9cda-9a7e-4d79-992e-161f4888b258',
        name: 'チェックボックス',
        type: 'check_box',
        selects: ['1', '2', '3'],
        isRequired: true,
        description: '説明説明説明説明説明説明説明説明'
      },
      {
        id: 'ce8f84a0-509f-4ba4-a554-8904eb8f1ffe',
        name: '数値',
        type: 'quantitative',
        unit: 'cm',
        isRequired: false,
        description: '説明説明説明説明説明説明説明説明'
      },
      {
        id: '69e79981-bdf2-4515-bc89-41dc07da218e',
        name: '日付',
        type: 'date',
        isRequired: false,
        description: '説明'
      },
      {
        id: '958d5222-aaa8-47cc-b8fe-fad21c623b1c',
        name: '選択肢',
        type: 'choice',
        choices: [
          { name: '1', branch: { type: 'next' } },
          { name: '2', branch: { type: 'next' } },
          { name: '3', branch: { type: 'next' } }
        ],
        isRequired: false,
        description: '説明説明説明説明説明説明'
      }
    ]
  },
  {
    id: '0aa16211-ec56-44c6-af43-a2b816f0c50b',
    name: 'セクション2',
    options: [
      {
        id: 'c9fbc5f4-a8b2-4991-a9ed-e19140919b4e',
        name: '名前',
        type: 'text',
        isRequired: false,
        description: '名前を入力して',
        isMultiLine: false
      },
      {
        id: '72e3355f-3ef6-4e79-b2ad-ae25a09e24ea',
        name: '年齢',
        type: 'quantitative',
        unit: '歳',
        isRequired: false,
        description: '年齢を入力して'
      },
      {
        id: 'ba55891d-bda8-4439-aee8-1458f30da0b8',
        name: '家族情報',
        type: 'input_set',
        optionIds: ['c9fbc5f4-a8b2-4991-a9ed-e19140919b4e', '72e3355f-3ef6-4e79-b2ad-ae25a09e24ea']
      }
    ]
  }
]

const dirtyContent = [
  {
    id: '0b0c613f-9965-49b1-bd66-e4246316e927',
    name: 'セクション1',
    options: [
      {
        id: '3dfeef7c-4d47-4cb0-b80d-6e954e505976',
        name: '単一行テキスト',
        type: 'text',
        isRequired: true,
        description: '説明説明説明説明説明説明説明説明',
        isMultiLine: false,
        value: 'aaaaaaaaaa'
      },
      {
        id: 'ede7f816-2894-4be0-a007-0d14c35e27c0',
        name: '複数行テキスト',
        type: 'text',
        isRequired: false,
        description: '説明説明説明説明説明説明説明説明',
        isMultiLine: true,
        value: 'aaaaaaaaaa\naaaaaaaaaa'
      },
      {
        id: '54cb9cda-9a7e-4d79-992e-161f4888b258',
        name: 'チェックボックス',
        type: 'check_box',
        selects: ['1', '2', '3'],
        isRequired: true,
        description: '説明説明説明説明説明説明説明説明',
        selected: [0, 1]
      },
      {
        id: 'ce8f84a0-509f-4ba4-a554-8904eb8f1ffe',
        name: '数値',
        type: 'quantitative',
        unit: 'cm',
        isRequired: false,
        description: '説明説明説明説明説明説明説明説明',
        value: '1'
      },
      {
        id: '69e79981-bdf2-4515-bc89-41dc07da218e',
        name: '日付',
        type: 'date',
        isRequired: false,
        description: '説明',
        value: '2024-09-11'
      },
      {
        id: '958d5222-aaa8-47cc-b8fe-fad21c623b1c',
        name: '選択肢',
        type: 'choice',
        choices: [
          { name: '1', branch: { type: 'next' } },
          { name: '2', branch: { type: 'next' } },
          { name: '3', branch: { type: 'next' } }
        ],
        isRequired: false,
        description: '説明説明説明説明説明説明',
        selected: 1
      }
    ]
  },
  {
    id: '0aa16211-ec56-44c6-af43-a2b816f0c50b',
    name: 'セクション2',
    options: [
      {
        id: 'c9fbc5f4-a8b2-4991-a9ed-e19140919b4e',
        name: '名前',
        type: 'text',
        isRequired: false,
        description: '名前を入力して',
        isMultiLine: false
      },
      {
        id: '72e3355f-3ef6-4e79-b2ad-ae25a09e24ea',
        name: '年齢',
        type: 'quantitative',
        unit: '歳',
        isRequired: false,
        description: '年齢を入力して'
      },
      {
        id: 'ba55891d-bda8-4439-aee8-1458f30da0b8',
        name: '家族情報',
        type: 'input_set',
        optionIds: ['c9fbc5f4-a8b2-4991-a9ed-e19140919b4e', '72e3355f-3ef6-4e79-b2ad-ae25a09e24ea']
      }
    ]
  }
]

export const formContentRevisionResponse = {
  id: '0191b7dd-ae23-71be-96be-7e9388792753',
  contentJson: JSON.stringify(cleanContent),
  createdAt: '2024-07-16T05:39:30Z'
}

export const submissionFormContentResponse = {
  hospitalizationOrderDocumentSetContentId: '0191b7ed-d37e-7d31-96f9-4634505b7065',
  submitted: false,
  contentJson: JSON.stringify(dirtyContent),
  createdAt: '2024-07-16T05:39:30Z',
  updatedAt: '2024-07-16T05:39:30Z'
}

export const hospitalizationOrderDocumentSets = {
  [medicalInstitutions[0].id]: [
    {
      id: '00000000-ffff-0000-ffff-000000000000',
      documentSetId: '00000000-ffff-0000-ffff-000000000000',
      title: '書類セット001 10/20',
      description: '',
      statusCode: 'not_submitted'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000001',
      documentSetId: '00000000-ffff-0000-ffff-000000000001',
      title: '書類セット002 9/10',
      description: '',
      statusCode: 'partial_submitted'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000002',
      documentSetId: '00000000-ffff-0000-ffff-000000000002',
      title: '書類セット003 4/23',
      description: '',
      statusCode: 'submitted'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000003',
      documentSetId: '00000000-ffff-0000-ffff-000000000003',
      title: '書類セット004 4/20',
      description: '',
      statusCode: 'confirmed'
    }
  ]
}
