<script setup lang="ts">
import { getTypography } from '@optim-design-system/src'
import { computed, toRefs } from 'vue'
import { useTheme } from 'vuetify'

import BaseButton from '../Button/index.vue'
import BaseCircular from '../Circular/index.vue'

import type { CancelBtnType, SizeType } from './types'
import type { VuetifyColorTokenType } from '@/common/VuetifyTheme/types'
import type { NucleusColorTokenType } from '@optim-design-system/src'

const DialogSize = {
  small: '320px',
  medium: '560px',
  large: '840px',
  xLarge: 'calc(100vw - 48px)'
} as const

type Props = {
  /**
   * ダイアログの開閉
   */
  value?: boolean
  /**
   * ダイアログのタイトル
   */
  title?: string
  /**
   * タイトルテキストカラー
   */
  titleColor?: NucleusColorTokenType | string
  /**
   * 完了ボタン色
   */
  color?: VuetifyColorTokenType
  /**
   * 完了時のローディング表示
   */
  loading?: boolean
  /**
   * APIのレスポンス待ちなど表示待ちの全体ローディング表示
   */
  dialogLoading?: boolean
  /**
   * 完了ボタンの文字
   */
  confirmBtnText?: string
  /**
   * 完了ボタン表示
   */
  confirmBtnVisible?: boolean
  /**
   * キャンセルボタンの文字
   */
  cancelBtnText: string
  /**
   * 完了ボタンの非活性化
   */
  disabled?: boolean
  /**
   * ダイアログサイズ
   */
  size?: SizeType
  /**
   * キャンセルボタン位置
   */
  cancelBtnDefault?: CancelBtnType
  /**
   * 永続表示
   */
  persistent?: boolean
  /**
   * テレポート無効化(テスト用)
   */
  attach?: string | boolean
  /**
   * アクションボタン配置センター寄せ
   */
  actionButtonsCenter?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  value: false,
  title: 'title',
  color: 'theme-DEFAULT-surface-brand-primary-value',
  titleColor: 'inherit',
  loading: false,
  dialogLoading: false,
  confirmBtnText: '',
  cancelBtnText: '',
  disabled: false,
  size: 'medium',
  cancelBtnDefault: 'left',
  persistent: false,
  fixedActions: true,
  attach: false,
  actionButtonsCenter: false
})

const { value } = toRefs(props)

// ダイアログのサイズ
const baseCardClass = computed(() => {
  switch (props.size) {
    case 'small':
      return DialogSize.small
    case 'medium':
      return DialogSize.medium
    case 'large':
      return DialogSize.large
    case 'x-large':
      return DialogSize.xLarge
    default:
      return DialogSize.medium
  }
})

type Emits = {
  (e: 'confirm'): void
  (e: 'input', value: boolean): void
}

const emit = defineEmits<Emits>()

const onConfirm = () => {
  emit('confirm')
}

const onCloseDialog = (value: boolean) => {
  emit('input', value)
}

const cancelBtnText = props.cancelBtnText

const titleTokenStyle = getTypography('HEADLINE_S')

const buttonSize = computed(() => {
  if (typeof props.size !== 'undefined' && props.size === 'small') {
    return 'small'
  }
  return 'medium'
})

const titleColorStyle = computed(() => {
  if (props.titleColor) {
    const thmeColors = useTheme().current.value.colors
    return { color: thmeColors[props.titleColor] }
  }
  return null
})
</script>

<template>
  <v-dialog
    v-model="value"
    :max-width="baseCardClass"
    :persistent="persistent"
    :attach="attach"
    @update:model-value="onCloseDialog"
  >
    <template #activator="{ props: namedSlotProps }">
      <slot name="activator" v-bind="namedSlotProps" />
    </template>
    <v-card v-if="!dialogLoading" class="app-base-dialog-card">
      <v-card-title class="app-base-dialog-card--title">
        <div class="d-flex">
          <span :style="[titleTokenStyle, titleColorStyle]" style="pointer-events: auto">{{
            title
          }}</span>
          <div class="ml-auto"></div>
        </div>
      </v-card-title>
      <!-- コンテンツ -->
      <v-card-text class="app-base-dialog-card--text" tag="article" aria-labelledby="contents">
        <slot name="contents" />
      </v-card-text>

      <v-card-actions v-if="cancelBtnDefault === 'center'" class="app-base-dialog-card--actions">
        <slot name="cancel-button-center">
          <BaseButton
            variant="elevated"
            :color="color"
            @click="onCloseDialog(false)"
            :content="cancelBtnText"
            :loading="loading"
            :disabled="disabled"
            block
            large
          />
        </slot>
      </v-card-actions>

      <v-card-actions v-else class="app-base-dialog-card--actions">
        <!-- キャンセルボタン左配置 -->
        <slot v-if="cancelBtnDefault === 'left'" name="cancel-button-left">
          <BaseButton
            variant="outlined"
            @click="onCloseDialog(false)"
            :loading="loading"
            :disabled="disabled"
            :content="cancelBtnText"
            :outlined="true"
            :size="buttonSize"
          />
        </slot>
        <slot name="actions-left" />
        <v-spacer />
        <!-- キャンセルボタン右配置 -->
        <slot v-if="cancelBtnDefault === 'right'" name="cancel-button-right">
          <BaseButton
            variant="outlined"
            @click="onCloseDialog(false)"
            :content="cancelBtnText"
            :loading="loading"
            :disabled="disabled"
            :outlined="true"
            :size="buttonSize"
          />
        </slot>
        <slot name="actions-stepper-back" />
        <slot v-if="confirmBtnVisible" name="actions-right">
          <BaseButton
            variant="elevated"
            :color="color"
            :loading="loading"
            :content="confirmBtnText"
            :disabled="disabled"
            :size="buttonSize"
            @click="onConfirm"
          />
        </slot>
      </v-card-actions>
    </v-card>
    <!-- Dialog Loading -->
    <v-card v-else class="app-base-dialog-card-loading">
      <v-card-title class="app-base-dialog-card--title">
        <div class="d-flex">
          <span :style="titleTokenStyle" style="pointer-events: auto">{{ title }}</span>
          <div class="ml-auto"></div>
        </div>
      </v-card-title>
      <div class="loading">
        <base-circular size="medium" />
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.app-base-dialog-card {
  display: flex;
  flex-direction: column;
  min-width: 280px;
  height: 100%;

  &__close-icon {
    width: 40px;
    height: 40px;
  }

  &--title {
    padding: 24px 24px 8px 24px !important;
  }

  &--text {
    padding: 16px 24px !important;
  }

  &--actions {
    padding: 0px 24px 24px 24px;
  }
}

.app-base-dialog-card-loading {
  display: flex;
  flex-direction: column;
  min-width: 280px;
  height: 100%;
  min-height: 280px;

  &__close-icon {
    width: 40px;
    height: 40px;
  }
}

.loading {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.x-mark-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.x-mark-button-type {
  min-width: 36px;
  width: 36px;
}
</style>
