<script setup lang="ts">
import { getTypography, type NucleusTypographyTokenType } from '@optim-design-system/src'
import { computed, defineProps } from 'vue'

type Props = {
  /**
   * フォームラベル
   */
  forHtml?: string
  /**
   * ラベル
   */
  labelText?: string
  /**
   * 必須かどうか
   */
  isRequired?: boolean
  /**
   * ステータスの表示位置
   */
  direction?: 'left' | 'right'
  /**
   * ラベル書式
   */
  tokenType?: NucleusTypographyTokenType
}

// props定義
const props = withDefaults(defineProps<Props>(), {
  formLabel: 'label',
  labelText: 'text',
  isRequired: false,
  direction: 'left',
  tokenType: 'BODY_M'
})

//  書式
const bodyStyle = computed(() => {
  return getTypography(props.tokenType)
})
</script>

<template>
  <div class="wrapper">
    <span v-if="isRequired && direction === 'left'" class="required-mark">*</span>
    <label :for="forHtml" :style="bodyStyle">{{ labelText }}</label>
    <span v-if="isRequired && direction === 'right'" class="required-mark">*</span>
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  display: inline;
}
// 必須マーク
.required-mark {
  color: red;
  margin-left: 4px;
}
</style>
