/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface UserResponse {
  id: string
  agreedTermsAt: string
  termsAgreed: boolean
}

export interface CreateUserRequest {
  termsId: string
}

export interface CreateUserResponse {
  id: string
  appToken: string
  agreedTermsAt: string
  termsAgreed: boolean
}

export interface TermsResponse {
  baseUrl: string
  termsFileName: string
  privacyPolicyFileName: string
  termsId: string
}

export interface AppVersionResponse {
  versions: object
}

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType
} from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path
    })
  }
}

/**
 * @title Digihos Application Gateway API
 * @version 1.0
 * @contact
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  gateway = {
    /**
     * No description
     *
     * @name UserByUser
     * @summary ユーザー情報取得
     * @request GET:/gateway/v1/users
     */
    userByUser: (
      query: {
        userId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<UserResponse, any>({
        path: `/gateway/v1/users`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @name CreateUser
     * @summary ユーザーの作成を行う
     * @request POST:/gateway/v1/users
     */
    createUser: (data: CreateUserRequest, params: RequestParams = {}) =>
      this.request<CreateUserResponse, any>({
        path: `/gateway/v1/users`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @name GetTerms
     * @summary 利用規約の取得
     * @request GET:/gateway/v1/terms
     */
    getTerms: (params: RequestParams = {}) =>
      this.request<TermsResponse, any>({
        path: `/gateway/v1/terms`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * @description core-backendとapp-gatewayのバージョン情報を返します
     *
     * @name GetVersions
     * @summary app-gatewayとcore-backendのバージョン情報取得
     * @request GET:/gateway/v1/version
     */
    getVersions: (params: RequestParams = {}) =>
      this.request<AppVersionResponse, any>({
        path: `/gateway/v1/version`,
        method: 'GET',
        format: 'json',
        ...params
      })
  }
  api = {
    /**
     * No description
     *
     * @name ProxyControllerAllGet
     * @request GET:/api/*
     */
    proxyControllerAllGet: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/*`,
        method: 'GET',
        ...params
      }),

    /**
     * No description
     *
     * @name ProxyControllerAllPost
     * @request POST:/api/*
     */
    proxyControllerAllPost: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/*`,
        method: 'POST',
        ...params
      }),

    /**
     * No description
     *
     * @name ProxyControllerAllPut
     * @request PUT:/api/*
     */
    proxyControllerAllPut: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/*`,
        method: 'PUT',
        ...params
      }),

    /**
     * No description
     *
     * @name ProxyControllerAllDelete
     * @request DELETE:/api/*
     */
    proxyControllerAllDelete: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/*`,
        method: 'DELETE',
        ...params
      }),

    /**
     * No description
     *
     * @name ProxyControllerAllPatch
     * @request PATCH:/api/*
     */
    proxyControllerAllPatch: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/*`,
        method: 'PATCH',
        ...params
      }),

    /**
     * No description
     *
     * @name ProxyControllerAllOptions
     * @request OPTIONS:/api/*
     */
    proxyControllerAllOptions: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/*`,
        method: 'OPTIONS',
        ...params
      }),

    /**
     * No description
     *
     * @name ProxyControllerAllHead
     * @request HEAD:/api/*
     */
    proxyControllerAllHead: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/*`,
        method: 'HEAD',
        ...params
      }),

    /**
     * No description
     *
     * @name ProxyControllerAllSearch
     * @request SEARCH:/api/*
     */
    proxyControllerAllSearch: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/*`,
        method: 'SEARCH',
        ...params
      })
  }
  health = {
    /**
     * No description
     *
     * @name HealthControllerCheck
     * @request GET:/health
     */
    healthControllerCheck: (params: RequestParams = {}) =>
      this.request<
        {
          /** @example "ok" */
          status?: string
          /** @example {"database":{"status":"up"}} */
          info?: Record<
            string,
            {
              status: string
              [key: string]: any
            }
          >
          /** @example {} */
          error?: Record<
            string,
            {
              status: string
              [key: string]: any
            }
          >
          /** @example {"database":{"status":"up"}} */
          details?: Record<
            string,
            {
              status: string
              [key: string]: any
            }
          >
        },
        {
          /** @example "error" */
          status?: string
          /** @example {"database":{"status":"up"}} */
          info?: Record<
            string,
            {
              status: string
              [key: string]: any
            }
          >
          /** @example {"redis":{"status":"down","message":"Could not connect"}} */
          error?: Record<
            string,
            {
              status: string
              [key: string]: any
            }
          >
          /** @example {"database":{"status":"up"},"redis":{"status":"down","message":"Could not connect"}} */
          details?: Record<
            string,
            {
              status: string
              [key: string]: any
            }
          >
        }
      >({
        path: `/health`,
        method: 'GET',
        format: 'json',
        ...params
      })
  }
}
