<script setup lang="ts">
import FormContentDetail from '@features/FormContentDetail/index.vue'
import UrlContentDetail from '@features/UrlContentDetail/index.vue'
import { isAxiosError } from 'axios'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import { hospitalizationOrderApiClient } from '@/api/apiClient'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'
import { useConstants } from '@/composables/useConstants'
import { useDisplayNameStore } from '@/stores/displayName'
import { useHospitalizationOrderStore } from '@/stores/hospitalizationOrder'

const route = useRoute()
const medicalInstitutionId =
  typeof route.params.medicalInstitutionId == 'string' ? route.params.medicalInstitutionId : ''
const hospitalizationOrderDocumentSetContentId =
  typeof route.params.hospitalizationOrderDocumentSetContentId == 'string'
    ? route.params.hospitalizationOrderDocumentSetContentId
    : ''
const hospitalizationOrderDocumentSetId =
  typeof route.params.hospitalizationOrderDocumentSetId == 'string'
    ? route.params.hospitalizationOrderDocumentSetId
    : ''

const documentSetContent = ref<DocumentSetContent>()
const urlContentUrl = ref<string>()
const formContentSubmitted = ref<boolean>()

const hospitalizationOrderStore = useHospitalizationOrderStore()
const CONTENT_TYPE = useConstants().CONSTANTS.CONTENT_TYPE

type DocumentSetContent = {
  id: string
  contentType: string
  description: string
}
const axiosErrorGuard = useAxiosErrorGuard()

onMounted(async () => {
  useDisplayNameStore().updateDisplayName(undefined)
  const hospitalizationOrder =
    await hospitalizationOrderStore.fetchHospitalizationOrderOrThrow(medicalInstitutionId)

  const response =
    await hospitalizationOrderApiClient().api.hospitalizationOrderDocumentSetContentByPar(
      medicalInstitutionId,
      hospitalizationOrder.id,
      hospitalizationOrderDocumentSetId,
      hospitalizationOrderDocumentSetContentId,
      {
        userId: '',
        representativeId: hospitalizationOrderStore.getRepresentativeId()
      }
    )
  useDisplayNameStore().updateDisplayName(response.data.title)

  documentSetContent.value = {
    id: response.data.id,
    contentType: response.data.contentType,
    description: response.data.description
  }

  switch (response.data.contentType) {
    case CONTENT_TYPE.FORM:
      // フォームコンテンツの提出状況を取得
      formContentSubmitted.value = await hospitalizationOrderApiClient({ allowNotFound: true })
        .api.hospitalizationOrderSubmissionFormContentByPar(
          medicalInstitutionId,
          hospitalizationOrder.id,
          hospitalizationOrderDocumentSetContentId,
          { userId: '', representativeId: hospitalizationOrderStore.getRepresentativeId() }
        )
        .then((response) => response.data.submitted)
        .catch((error) => {
          if (isAxiosError(error) && axiosErrorGuard.isNotFound(error)) {
            return false
          } else {
            throw error
          }
        })
      break
    case CONTENT_TYPE.URL:
      // URL取得
      urlContentUrl.value = (
        await hospitalizationOrderApiClient().api.hospitalizationOrderDocumentSetUrlContentRevisionByPar(
          medicalInstitutionId,
          hospitalizationOrder.id,
          response.data.contentRevisionId,
          { userId: '', representativeId: hospitalizationOrderStore.getRepresentativeId() }
        )
      ).data.url
      break
  }
})
</script>
<template>
  <v-container class="container">
    <div v-if="documentSetContent" class="detail-document-set-content">
      <FormContentDetail
        v-if="
          documentSetContent.contentType == CONTENT_TYPE.FORM && formContentSubmitted != undefined
        "
        :medical-institution-id="medicalInstitutionId"
        :hospitalization-order-document-set-id="hospitalizationOrderDocumentSetId"
        :hospitalization-order-document-set-content-id="hospitalizationOrderDocumentSetContentId"
        :description="documentSetContent.description"
        :submitted="formContentSubmitted"
      />
      <UrlContentDetail
        v-if="documentSetContent.contentType == CONTENT_TYPE.URL && urlContentUrl"
        :description="documentSetContent.description"
        :url="urlContentUrl"
      />
    </div>
  </v-container>
</template>

<style lang="scss" scoped>
.container {
  padding: 16px;
  height: 100%;
}

.detail-document-set-content {
  height: 100%;
}
</style>
