<script setup lang="ts">
import axios from 'axios'
import sanitize from 'sanitize-html'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { VuetifyColorTokenType } from '@/common/VuetifyTheme/types'

import { usersApiClient } from '@/api/apiGatewayClient'
import BaseButton from '@/components/Base/Button/index.vue'
import ScrollField from '@/components/Base/ScrollField/index.vue'
import LogoArea from '@/features/AppTools/LogoArea/index.vue'
import router from '@/router'

// i18n
const { t } = useI18n()

// emit定義
const emit = defineEmits<{
  (e: 'submit'): void
}>()

const primary: VuetifyColorTokenType = 'primary'

// URLからhtmlコンテンツに変換
const termContent = ref<string>('')
const privacyPolicyContent = ref<string>('')

const fetchTermContent = async () => {
  try {
    const response = await usersApiClient().gateway.getTerms()
    const termResponse = await axios.get(`${response.data.baseUrl}${response.data.termsFileName}`)
    termContent.value = sanitize(termResponse.data)

    const privacyPolicyResponse = await axios.get(
      `${response.data.baseUrl}${response.data.privacyPolicyFileName}`
    )
    privacyPolicyContent.value = sanitize(privacyPolicyResponse.data)
  } catch (error) {
    router.replace('/not_found')
  }
}

// 初期化
onMounted(async () => {
  await fetchTermContent()
})

const isChecked = ref(false)

// 同意ボタン送信
const submit = async () => {
  if (isChecked.value) {
    emit('submit')
  }
}
</script>

<template>
  <div class="inner-container">
    <div class="content">
      <div class="header">
        <LogoArea />
      </div>
      <div class="page-list">
        <ScrollField :title="t('features.terms.termsOfUse')" :content="termContent"></ScrollField>
        <ScrollField
          :title="t('features.terms.privacyPolicy')"
          :content="privacyPolicyContent"
        ></ScrollField>
      </div>
      <div class="button-container">
        <div class="check-box-layout">
          <v-checkbox v-model="isChecked" :color="primary"></v-checkbox>
          <span class="checkbox-text">{{ t('features.terms.makeUser') }}</span>
        </div>

        <div class="footer">
          <BaseButton
            :class="isChecked ? 'action-button' : 'non-action-button'"
            class="button-size"
            :color="isChecked ? 'primary' : 'surface-disable'"
            :content="t('features.terms.toNext')"
            type="button"
            variant="flat"
            :disabled="!isChecked"
            size="large"
            @click="submit"
          ></BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.inner-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content {
  flex: 10;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  height: 64px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.page-list {
  padding-bottom: 16px;
}

.check-box-layout {
  height: 72px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.footer {
  width: 100%;
  padding: 16px 32px 24px 32px;
}

.button-size {
  width: 100%;
}

.checkbox-text {
  height: 48px;
  text-align: left;
  line-height: 24px;
}

.non-action-button {
  background-color: #e8e9eb !important;
  opacity: 0.2;
}

.v-container {
  display: flex;
  align-items: center;
  padding: 16px;
}
</style>
