import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useFormContentNavigationStore = defineStore('formContentNavigation', () => {
  const _value = ref<number>(0)

  let backButtonAction: (() => Promise<void>) | null = null
  let interruptButtonAction: (() => Promise<void>) | null = null

  const section = computed(() => _value.value)

  const nextSection = () => {
    _value.value++
  }

  const previousSection = () => {
    _value.value--
  }

  const setBackButtonAction = (action: () => Promise<void>) => {
    backButtonAction = action
  }

  const executeBackAction = async () => {
    if (backButtonAction) {
      await backButtonAction()
    }
  }

  const setInterruptButtonAction = (action: () => Promise<void>) => {
    interruptButtonAction = action
  }

  const executeInterruptAction = async () => {
    if (interruptButtonAction) {
      await interruptButtonAction()
    }
  }

  const clearFormContentNavigation = () => {
    _value.value = 0
    backButtonAction = null
    interruptButtonAction = null
  }

  return {
    section,
    setBackButtonAction,
    setInterruptButtonAction,
    nextSection,
    previousSection,
    executeBackAction,
    executeInterruptAction,
    clearFormContentNavigation
  }
})
