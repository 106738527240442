import { defineStore } from 'pinia'
import { ref } from 'vue'

// 同意書類の閲覧状態を管理する
export const useMedicalInstitutionTermsCheckStore = defineStore(
  'medicalInstitutionTermsCheck',
  () => {
    // 同意書類群
    const medicalInstitutionId = ref<string>()
    const termsCheck = ref<boolean>(false)
    const privacyPolicyCheck = ref<boolean>(false)

    const isAllChecked = (): boolean => {
      return termsCheck.value && privacyPolicyCheck.value
    }

    // 一つの医療機関の同意状態のみ保持し、別の医療機関での使用を検知したら初期化する
    const initSetups = (id: string) => {
      // 別の医療機関の
      if (medicalInstitutionId.value !== id) {
        reset()
        medicalInstitutionId.value = id
      }
    }

    const reset = (): void => {
      medicalInstitutionId.value = undefined
      termsCheck.value = false
      privacyPolicyCheck.value = false
    }

    return {
      medicalInstitutionId,
      termsCheck,
      privacyPolicyCheck,
      initSetups,
      isAllChecked,
      reset
    }
  }
)
