<script setup lang="ts">
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import checkCricleLogo from '@/assets/icons/check_circle.svg'
import BaseButton from '@/components/Base/Button/index.vue'
import router from '@/router'
import { useDisplayNameStore } from '@/stores/displayName'

const { t } = useI18n()

// コンテンツ一覧画面へ遷移
const transitionContents = () => {
  router.go(-2)
}

onMounted(async () => {
  const displayNameStore = useDisplayNameStore()
  displayNameStore.updateDisplayName('pages.navigationTitles.responsesCompleted')
})
</script>

<template>
  <v-container class="complete-container">
    <div class="container">
      <div class="inner-container">
        <label>{{ t('features.hospitalizationDocument.FormContentComplete.completed') }}</label>
        <v-img class="imgicon" :src="checkCricleLogo" height="100px" />
      </div>
    </div>
    <div class="bottom-container">
      <div>
        <BaseButton
          color="primary"
          block
          :content="t('features.hospitalizationDocument.FormContentComplete.backToDocumentSets')"
          @click="transitionContents"
        >
        </BaseButton>
      </div>
    </div>
  </v-container>
</template>

<style lang="scss" scoped>
// 画面全体のコンテナ
.complete-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

// 中央コンテナ
.container {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 8px;
  padding: 32px;
}

// 中央インナーコンテナ
.inner-container {
  padding: 32px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  background-color: rgb(var(--v-theme-primitive-white-DEFAULT-value));
}

// ボトムコンテナ
.bottom-container {
  margin-bottom: 32px;
  margin: 32px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
}
</style>
