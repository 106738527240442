<script setup lang="ts">
import { differenceInCalendarDays } from 'date-fns'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { type ChipItem } from '../../components/Card/ChipCard/types'

import type { DocumentSetContent } from './type'

import { ChipCard } from '@/components/Card'
import useChip from '@/composables/useChip'
import { useConstants } from '@/composables/useConstants'
import router from '@/router'

const { t } = useI18n()
const { getDocumentSetContentStatus } = useChip()

const props = defineProps<{
  medicalInstitutionId: string
  hospitalizationOrderDocumentSetId: string
  hospitalizationDate?: string
  documentSetContentList: DocumentSetContent[]
  documentSetDescription?: string
}>()

const transitionDetailContent = (hospitalizationOrderDocumentSetContentId: string) => {
  router.push(
    `/medical-institutions/${props.medicalInstitutionId}/hospitalization-order-document-sets/${props.hospitalizationOrderDocumentSetId}/hospitalization-order-document-set-contents/${hospitalizationOrderDocumentSetContentId}`
  )
}

const getChipValue = (contentStatus: string | undefined): ChipItem | undefined => {
  if (contentStatus == undefined) return
  const chipValue = getDocumentSetContentStatus(contentStatus)
  if (chipValue) {
    return {
      ...chipValue,
      label: t(chipValue.label)
    }
  } else {
    return
  }
}

const getWarning = computed(
  () => (contentStatus: string | undefined, hospitalizationDate: string | undefined) => {
    if (typeof hospitalizationDate !== 'string') return
    if (contentStatus != useConstants().CONSTANTS.CONTENT_STATUS.NOT_SUBMITTED) {
      return
    }
    const result = differenceInCalendarDays(new Date(hospitalizationDate), new Date())
    if (result < 0) {
      return t('features.documentSet.documentSetList.daysOver', { day: Math.abs(result) })
    } else {
      return t('features.documentSet.documentSetList.daysLeft', { day: result })
    }
  }
)
</script>
<template>
  <div class="document-set-description">{{ documentSetDescription }}</div>
  <div class="document-set-content-list">
    <ChipCard
      v-for="item in documentSetContentList"
      :key="item.id"
      :title="item.title"
      :chipValue="getChipValue(item.contentStatus)"
      :warning="getWarning(item.contentStatus, hospitalizationDate)"
      @click="transitionDetailContent(item.id)"
    />
  </div>
</template>

<style lang="scss" scoped>
.document-set-description {
  white-space: pre-wrap;
  margin-bottom: 16px;
}

.document-set-content-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: scroll;
}
</style>
