import { defineStore } from 'pinia'
import { ref } from 'vue'

import { hospitalizationOrderApiClient, type ErrorOption } from '@/api/apiClient'
import { type ParHospitalizationOrderResponse } from '@/api/generate/apiSchema'
import { getUserId } from '@/api/userDataCookie'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'

const axiosErrorGuard = useAxiosErrorGuard()

export interface Representative {
  id: string
  userId: string
  familyName: string
  givenName: string
  relationshipType: string
  patientConfirmed: boolean
  createdAt: string
  updatedAt: string
}

export interface Patient {
  id: string
  patientNo: string
  familyName: string
  givenName: string
  phoneticFamilyName: string
  phoneticGivenName: string
  gender: number
  birthDate: string
  createdAt: string
  updatedAt: string
}

export interface HospitalizationOrder {
  id: string
  hospitalizationOrderStatus: string
  hospitalDepartment: string
  ward: string
  hospitalRoom: string
  representatives: Representative[]
  hospitalizationDate?: string
  dischargeDate?: string
  patient: Patient
  createdAt: string
  updatedAt: string
}

export const useHospitalizationOrderStore = defineStore('hospitalizationOrder', () => {
  const representative = ref<Representative>()
  const hospitalizationOrder = ref<ParHospitalizationOrderResponse>()

  /**
   * 入院オーダーの取得
   * @param medicalInstitutionId
   * @param errorOption
   * @returns エラーオプションを渡している場合、undefinedを返す可能性がある。そうでない場合、undefinedとなることはない
   */
  const fetchHospitalizationOrder = async (
    medicalInstitutionId: string,
    errorOption: ErrorOption | undefined = undefined
  ): Promise<ParHospitalizationOrderResponse | undefined> => {
    const response = await hospitalizationOrderApiClient(errorOption)
      .api.latestHospitalizationOrderByPar(medicalInstitutionId, { userId: '' })
      .catch((error) => {
        if (axiosErrorGuard.isNotFound(error)) {
          return undefined
        }

        throw error
      })

    if (response != undefined) {
      hospitalizationOrder.value = response.data
      hospitalizationOrder.value.representatives.forEach((_representative) => {
        if (getUserId() && _representative.userId == getUserId()) {
          representative.value = _representative
        }
      })
    }

    return hospitalizationOrder.value
  }

  /**
   * 入院オーダーの取得
   * @param medicalInstitutionId
   * @returns
   */
  const fetchHospitalizationOrderOrThrow = async (
    medicalInstitutionId: string
  ): Promise<ParHospitalizationOrderResponse> => {
    const response = await hospitalizationOrderApiClient().api.latestHospitalizationOrderByPar(
      medicalInstitutionId,
      { userId: '' }
    )

    hospitalizationOrder.value = response.data
    hospitalizationOrder.value.representatives.forEach((_representative) => {
      if (getUserId() && _representative.userId == getUserId()) {
        representative.value = _representative
      }
    })

    return hospitalizationOrder.value
  }

  const getRepresentativeId = (): string | undefined => {
    return representative.value?.id
  }

  const getRepresentative = (): Representative | undefined => {
    return representative.value
  }
  const getHospitalizationOrder = (): ParHospitalizationOrderResponse | undefined => {
    return hospitalizationOrder.value
  }

  return {
    fetchHospitalizationOrder,
    fetchHospitalizationOrderOrThrow,
    getRepresentativeId,
    getRepresentative,
    getHospitalizationOrder
  }
})
