<script setup lang="ts">
import type { MedicalInstitutionCardType } from './MedicalInstitutionCard/types'

import HospitalizationDocumentCard from '@/features/MedicalInstitutionDetail/HospitalizationDocumentCard/index.vue'
import MedicalInstitutionCard from '@/features/MedicalInstitutionDetail/MedicalInstitutionCard/index.vue'

type Props = {
  hospitalizationOrder: MedicalInstitutionCardType
  medicalInstitutionId: string
  medicalInstitutionName: string
}
const props = defineProps<Props>()
</script>
<template>
  <div class="hospitalization-card-container">
    <v-sheet class="hospitalization-card-border" elevation="2">
      <MedicalInstitutionCard
        :hospitalizationOrder="hospitalizationOrder"
        :medicalInstitutionName="$props.medicalInstitutionName"
      ></MedicalInstitutionCard>
    </v-sheet>
  </div>
  <HospitalizationDocumentCard :medical-institution-id="props.medicalInstitutionId" />
</template>

<style lang="scss" scoped>
.hospitalization-card-container {
  padding-bottom: 20px;
}

.hospitalization-card-border {
  border-radius: 24px;
}
</style>
