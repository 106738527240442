<script setup lang="ts">
import { toRefs } from 'vue'
import { useRoute } from 'vue-router'

import type { NavMenu } from './types'

type Props = {
  /**
   * ナビゲーションメニュー
   */
  navMenus: NavMenu[]
  /**
   * ナビゲーションの表示
   */
  isShowMenu: boolean

  /**
   * ナビゲーションの開閉
   */
  drawer: boolean
}
const props = withDefaults(defineProps<Props>(), {
  isShowMenu: false,
  rail: false
})

const { isShowMenu } = toRefs(props)
const route = useRoute()

const isActive = (path: string): boolean => {
  return route.path.startsWith(path)
}
</script>

<template>
  <v-bottom-navigation
    v-if="isShowMenu"
    class="app-bottom-navigation"
    hide-on-scroll
    :elevation="24"
    :grow="true"
  >
    <v-btn
      v-for="(item, index) in navMenus"
      :key="`${item.name}-${index}`"
      :to="item.to"
      :value="index"
      text
    >
      <v-icon
        :class="{ white: item.to && isActive(item.to), grey: !item.to || !isActive(item.to) }"
        class="nav-icon"
        >{{ item.icon }}</v-icon
      >
      <span
        :class="{ white: item.to && isActive(item.to), grey: !item.to || !isActive(item.to) }"
        >{{ item.name }}</span
      >
    </v-btn>
  </v-bottom-navigation>
</template>

<style scoped>
.app-bottom-navigation {
  background: #0017c1;
  /* 上部左右の角を丸くする */
  border-radius: 16px 16px 0px 0px;
  height: 92px !important;
  gap: 0px;
  opacity: 0px;
}

.white {
  color: white;
}

.grey {
  color: #cccccc;
}

.nav-icon {
  margin-bottom: 4px;
}
</style>
