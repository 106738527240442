import { computed, readonly, ref } from 'vue'

/**
 * ダイアログの状態管理するComposables
 */
export default function useDialog() {
  const open = ref(false)
  const loading = ref(false)

  /**
   * ダイアログの状態をReadonlyで返却する算出プロパティ
   */
  const isDialogState = computed(() => {
    return readonly(open)
  })

  /**
   * ダイアログの状態をReadonlyで返却する算出プロパティ
   */
  const isLoadingState = computed(() => {
    return readonly(loading)
  })

  /**
   * ダイアログを開く関数
   */
  const openDialog = (): void => {
    open.value = true
  }

  /**
   * ダイアログを閉じる関数
   */
  const closeDialog = (): void => {
    open.value = false
  }

  /**
   * ダイアログのローディングを開く関数
   */
  const startLoading = (): void => {
    loading.value = true
  }

  /**
   * ダイアログのローディングを閉じる関数
   */
  const stopLoading = (): void => {
    loading.value = false
  }

  return {
    openDialog,
    closeDialog,
    startLoading,
    stopLoading,
    isDialogState,
    isLoadingState
  }
}
