<script setup lang="ts">
import { computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useTheme } from 'vuetify'

import Label from '@/components/Base/Label/index.vue'

type Props = {
  navTitle?: string
  showBackIcon?: boolean
  showReloadPageIcon?: boolean
  leftHeaderComponent?: Object
  rightHeaderComponent?: Object
}

const props = withDefaults(defineProps<Props>(), {
  navTitle: '',
  showBackIcon: false,
  showReloadPageIcon: false,
  leftHeaderComponent: undefined,
  rightHeaderComponent: undefined
})

// 動的テーマ設定（HomeBar用）
const route = useRoute()
const router = useRouter()
watch(
  () => route.meta.customThemeName,
  (value) => {
    if (typeof value === 'string') {
      theme.global.name.value = value
    }
  }
)
const theme = useTheme()
const backgroundColorStyle = computed(() => {
  const backgroundColor = theme.themes.value[theme.global.name.value].colors?.background || null
  if (backgroundColor) {
    return { background: backgroundColor }
  }
  return {}
})

// 戻るボタンハンドラー
function goBack() {
  router.back()
}

// リロードボタンハンドラー
function reloadPage() {
  window.location.reload()
}

// 左上ボタンの表示判定
const isShowCustomLeftButton = computed((): boolean => {
  return typeof props.leftHeaderComponent !== 'undefined' && props.leftHeaderComponent !== null
})

// 戻るボタンの表示
const isShowLeftDefaultButton = computed((): boolean => {
  if (isShowCustomLeftButton.value) {
    return false
  }
  return props.showBackIcon
})

// 右上ボタンの表示判定
const isShowCustomRightButton = computed((): boolean => {
  return typeof props.rightHeaderComponent !== 'undefined' && props.rightHeaderComponent !== null
})

// リロードボタンの表示
const isShowRightDefaultButton = computed((): boolean => {
  if (isShowCustomRightButton.value) {
    return false
  }
  return props.showReloadPageIcon
})
</script>

<template>
  <v-app-bar elevation="0" class="custom-nav-bar" :style="backgroundColorStyle">
    <!-- 左上ボタン（カスタムコンポーネント用） -->
    <component v-if="isShowCustomLeftButton" :is="leftHeaderComponent" class="left-button" />
    <!-- 戻るボタン -->
    <v-icon v-else-if="isShowLeftDefaultButton" @click="goBack" class="left-button"
      >arrow_back</v-icon
    >
    <!-- 空白（左上デフォルト） -->
    <div v-else class="left-button place-holder"></div>

    <!-- タイトルの表示、中央配置 -->
    <div class="title-container">
      <Label
        class="title-bar"
        :labelText="navTitle"
        :isRequired="false"
        :token-type="'TITLE_L'"
      ></Label>
    </div>

    <!-- 右上ボタン（カスタムコンポーネント用） -->
    <component v-if="isShowCustomRightButton" :is="rightHeaderComponent" class="right-button" />
    <!-- リロードボタン -->
    <v-icon v-else-if="isShowRightDefaultButton" @click="reloadPage" class="right-button"
      >rotate_left</v-icon
    >
    <!-- 空白（右上デフォルト） -->
    <div v-else class="right-button place-holder"></div>
  </v-app-bar>
</template>
<style scoped>
.custom-nav-bar {
  position: relative;
  padding: 0px 16px 0px 16px !important;
  height: 64px !important;
}

.title-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.title-bar {
  text-align: center;
  pointer-events: auto;
}

.left-button,
.right-button {
  position: relative;
  z-index: 1;
}

.place-holder {
  width: 24px;
  height: 24px;
}
</style>
