// ルーティング毎のページ名などを記載する

export const pages = {
  appTitle: 'デジホス',
  bottomMenuHome: {
    list: 'ホーム'
  },
  bottomMenuMiniApp: {
    list: 'ミニアプリ'
  },
  bottomMenuCitizenCard: {
    list: '市民証'
  },
  bottomMenuHospital: {
    list: '病院選択'
  },
  navigationTitles: {
    home: 'ホーム',
    terms: '利用規約',
    privacyPolicy: 'プライバシーポリシー',
    termsAndPrivacyPolicy: '利用規約・プライバシーポリシー',
    hospitalizationDocumentsList: '入院提出書類一覧',
    answersList: '回答一覧',
    responsesCompleted: '回答完了',
    patientConfirmation: '受診者情報確認'
  },
  medicalWorkers: {
    list: '従業員',
    new: '新規登録'
  },
  medicalInstitution: {
    title: '病院一覧'
  },
  hospitalizationHome: {
    title: '入院手続き'
  },
  serverError: {
    title: 'サーバーエラーが発生しました。',
    description:
      'サーバーエラーが発生したためサービスをご利用いただけません。お手数をおかけしますが、しばらくしてから再度アクセスしてください。'
  },
  forbidden: {
    title: '権限がありません。',
    description: 'アクセス権限がないため機能をご利用いただけません。'
  },
  notFound: {
    title: 'ページが存在しません。',
    description: 'お手数をおかけしますが、しばらくしてから再度アクセスしてください。'
  }
}
