// Features配下をディレクトリ毎に区切って指定する
export const features = {
  terms: {
    terms: '同意',
    termsOfUse: '利用規約',
    privacyPolicy: 'プライバシーポリシー',
    agreementToTermsOfUse: '利用規約の同意',
    makeUser: '利用規約・プライバシーポリシーに同意しデジホスIDを作成します。',
    agreementToTermsOfUseAndPrivacyAndPolicy: '利用規約・プライバシーポリシーに同意します',
    toNext: '次へ'
  },
  medicalInstitutionPatientConfirmation: {
    description: '受診者情報が正しいことをご確認ください',
    label: {
      patientName: '氏名',
      gender: '性別',
      birthDate: '生年月日',
      representativeName: '代理人氏名',
      relationship: '続柄'
    }
  },
  medicalWorker: {
    MedicalWorkerRegistrationForm: {
      input: {
        name: '名前',
        phoneticName: '名前（カタカナ）',
        email: 'メールアドレス',
        role: '権限'
      },
      placeholder: {
        familyName: '姓',
        givenName: '名',
        phoneticFamilyName: 'セイ',
        phoneticGivenName: 'メイ',
        email: 'メールアドレス',
        role: '権限'
      }
    }
  },
  medicalInstitutionList: {
    qrCodeCard: {
      bindingToken: '認証ID:',
      description: 'スタッフにこちらのQRコードをご提示ください。'
    },
    select: 'ご利用になる病院を選択してください'
  },
  hospitalizationDocument: {
    FormContentSubmission: {
      start: '回答をはじめる',
      goToNext: '次の質問へ',
      goToConfirm: '確認画面へ',
      stepBack: '前の質問へ',
      interruptForm: '回答を中断',
      interruptFormTitle: '回答を中断しますか？',
      interruptFormDescription:
        '入力情報は保存されます。次回続きから回答を再開することができます。',
      interruptFormCancelText: '入力を続ける',
      interruptFormOkText: '中断する',
      pleaseEnterNumber: '数字を入力してください',
      pleaseEnterText: 'テキストを入力してください',
      pleaseEnterDate: '日付を選んでください',
      pleaseSelect: '当てはまるものを全て選択してください',
      pleaseChoseOne: '選択肢から一つ選んでください',
      addInputSetButtonCaption: 'を追加',
      nonSelectAnswer: '選択なし',
      completeAnswer: '回答完了',
      fixAnswer: '回答修正'
    },
    FormContentComplete: {
      completed: 'ありがとうございました！',
      backToDocumentSets: '書類一覧に戻る'
    }
  },
  documentSet: {
    documentSetList: {
      daysLeft: 'あと{day}日',
      daysOver: '{day}日遅れ'
    }
  },
  FormContents: {
    typeName: {
      section: 'セクション'
    },
    result: {
      noAnswerMessage: '[回答なし]'
    }
  },
  medicalInstitutionHome: {
    hospitalizationOrder: '入院予定日',
    dischargeDate: '退院予定日',
    hospital: '病院選択',
    miniApp: {
      hospitalizationDocument: '入院書類'
    }
  },
  formContentDetail: {
    startAnswer: '回答をはじめる'
  }
}
