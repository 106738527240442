export const DocumentSetStatusCode = [
  'not_submitted',
  'partial_submitted',
  'submitted',
  'confirmed'
] as const
export type DocumentSetStatusCode = (typeof DocumentSetStatusCode)[number]
export const isDocumentSetStatusCode = (
  statusCode: string
): statusCode is DocumentSetStatusCode => {
  return DocumentSetStatusCode.some((value) => value === statusCode)
}

export const ContentStatusCode = ['not_submitted', 'submitted', 'confirmed'] as const
export type ContentStatusCode = (typeof ContentStatusCode)[number]
