<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import Label from '@/components/Base/Label/index.vue'
import router from '@/router'

const { t } = useI18n()

type Props = {
  medicalInstitutionId: string
}

const props = defineProps<Props>()
const transition = () => {
  router.push(
    `/medical-institutions/${props.medicalInstitutionId}/hospitalization-order-document-sets`
  )
}
</script>
<template>
  <div class="mini-app-icon-layout" @click="transition">
    <span class="material-symbols-outlined icon--btn-primary">description</span>
    <Label
      forHtml="content_title"
      class="content-title"
      :labelText="t('features.medicalInstitutionHome.miniApp.hospitalizationDocument')"
      :isRequired="false"
      :token-type="'LABEL_S'"
    ></Label>
  </div>
</template>

<style lang="scss" scoped>
.mini-app-icon-layout {
  background-color: #0017c1;
  border-radius: 16px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  align-self: center;

  &--btn-primary {
    color: #ffffff;
    font-size: 30px;
    font-variation-settings: 'FILL' 1;
  }
}

.content-title {
  color: #ffffff;
}
</style>
