<script setup lang="ts">
import { computed, reactive } from 'vue'
import { useI18n } from 'vue-i18n'

import ContentResult from './ContentResult.vue'
import { BranchName, FormOptionType, type FormOption, type SectionContainer } from './type'

import Typography from '@/components/Typography/Typography.vue'

const { t } = useI18n()

// props初期化
const props = withDefaults(
  defineProps<{
    section: SectionContainer
    sectionIndex: number
  }>(),
  {}
)
const section = reactive<SectionContainer>({ ...props.section })

const sectionTitle = computed(() => {
  return `${t('features.FormContents.typeName.section')}${props.sectionIndex + 1}:${section.name}`
})

/**
 * 選択肢によってセクション内部の要素がスキップされているかどうかを確認する
 * @param index オプション内部の表示順
 * @param options セクション内部のオプション一覧
 */
const isOptionSkip = (index: number, options: FormOption[]): boolean => {
  // indexより前の選択肢要素を全て取得する
  var isSkip = false
  var nextId = undefined
  for (let i = 0; i < index; i++) {
    const option = options[i]
    if (nextId == option.id) {
      // 現在の要素が選択肢によって飛ばされた最後の要素の場合はIDをリセットする
      nextId = undefined
      isSkip = false
    } else if (nextId != undefined) {
      // nextIdが含まれていて、自身ではない場合、飛ばされているので次に進む
      continue
    }

    if (option.type !== FormOptionType.Choice) {
      continue
    }

    if (option.selected == undefined) {
      isSkip = true
      nextId = undefined
      continue
    }

    const branch = option.choices[option.selected].branch
    switch (branch.type) {
      case BranchName.next:
        // 次への場合は表示するため次ループへ
        continue
      case BranchName.last:
        // 最後への場合はこの後の処理見る必要ないためreturn
        return true
      case BranchName.option:
        // optionの場合、idを入力して次回へ引き渡す
        nextId = branch.id
        isSkip = true
        continue
    }
  }

  // 常に自身より前の要素を参照するため、自分自身が飛ばされた先の場合にスキップしないようにする
  if (nextId == options[index].id) {
    return false
  }
  // 一番手前から後方に確認
  // 選択なしの場合、全てスキップ扱い
  // ある項目まで飛ばす場合、飛ばしてから選択肢項目を見つける
  return isSkip
}
</script>

<template>
  <div>
    <!-- セクションカード -->
    <div class="section-card-header">
      <Typography tag="h3" token-type="TITLE_M">{{ sectionTitle }}</Typography>
      <slot name="section-button"></slot>
    </div>
    <div v-for="(content, index) in section.options" :key="content.id">
      <div class="content-result-indent">
        <ContentResult
          class="result-container"
          v-if="!isOptionSkip(index, section.options)"
          :content="content"
        ></ContentResult>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
// セクションカードヘッダー
.section-card-header {
  display: flex;
  justify-content: space-between;
}
.content-result-indent {
  margin-left: 16px;
}
.result-container {
  margin: 4px 0;
  padding: 0 0 12px 0;
}
.result-container:not(:last-child) {
  margin-bottom: 0;
  padding-bottom: 0;
}
</style>
