import { isAxiosError, type AxiosError } from 'axios'

export default function () {
  const isConflict = (error: unknown): error is AxiosError => {
    return isAxiosError(error) && error.response?.status === 409
  }

  const isBadRequest = (error: unknown): error is AxiosError => {
    return isAxiosError(error) && error.response?.status === 400
  }

  const isNotFound = (error: unknown): error is AxiosError => {
    return isAxiosError(error) && error.response?.status === 404
  }

  return {
    isConflict,
    isBadRequest,
    isNotFound
  }
}
